import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'zipCode' })
export class ZipCodePipe implements PipeTransform {
  transform(value: string | undefined) {
    if (!value) {
      return '';
    }

    let formattedValue = value.replace(/\D/g, '');
    if (!formattedValue || formattedValue?.length < 2) {
      return formattedValue;
    }

    formattedValue = formattedValue.trim();
    const zipWithoutDash = formattedValue.replace(/-/g, '');

    if (!zipWithoutDash || zipWithoutDash.length < 2) {
      return formattedValue;
    }

    const firstFiveDigits = zipWithoutDash.slice(0, 2);
    const lastFourDigits =
      zipWithoutDash.length > 2 ? zipWithoutDash.slice(2) : '';
    const lastFourWithDash = lastFourDigits ? '-' + lastFourDigits : '';

    return `${firstFiveDigits}${lastFourWithDash}`;
  }
}
