<section>
  @if (textLabels().mainLabel) {
    <mat-label class="main-label">{{ textLabels().mainLabel }}</mat-label>
  }
  <div
    class="tel-form"
    [formGroup]="telForm"
    [ngClass]="{ 'is-focused': isFocused() }"
  >
    <mat-form-field appearance="fill" class="prefix-form-field">
      <mat-select
        formControlName="prefixCtrl"
        [placeholder]="(!isLoading() && textLabels().codePlaceholder) || ''"
        #singleSelect
        panelClass="tel-mat-select-panel"
        [required]="required()"
      >
        <mat-select-trigger>
          <div class="country-option">
            <div class="country-option-flag-container">
              @if (emojiFlags()) {
                <div>
                  <span class="flag-emoji">{{
                    prefixCtrl.value?.emojiFlag
                  }}</span>
                </div>
              } @else {
                <div
                  class="country-option-flag"
                  [ngClass]="prefixCtrl.value?.flagClass"
                ></div>
              }
            </div>
            @if (includeDialCode() || prefixCtrl.value?.iso2 === 'mp') {
              <div>{{ prefixCtrl.value?.iso2?.toUpperCase() || '' }}</div>
            } @else {
              <div>+{{ prefixCtrl.value?.dialCode || '' }}</div>
            }
          </div>
        </mat-select-trigger>

        @if (enableSearch()) {
          <mat-option>
            <ngx-mat-select-search
              [formControl]="prefixFilterCtrl"
              [placeholderLabel]="textLabels().searchPlaceholderLabel || ''"
              [noEntriesFoundLabel]="textLabels().noEntriesFoundLabel || ''"
            >
              <svg
                ngxMatSelectSearchClear
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-x"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M18 6l-12 12" />
                <path d="M6 6l12 12" />
              </svg>
            </ngx-mat-select-search>
          </mat-option>
        }

        @for (country of filteredCountries | async; track $index) {
          <mat-option [value]="country">
            <div
              class="country-option"
              style="display: flex; align-items: center; font-size: 14px"
            >
              <div class="country-option-flag-container">
                @if (emojiFlags()) {
                  <div>
                    <span class="flag-emoji">{{ country.emojiFlag }}</span>
                  </div>
                } @else {
                  <div
                    class="country-option-flag"
                    [ngClass]="country.flagClass"
                  ></div>
                }
              </div>
              <div>{{ country.name }}</div>
            </div>
          </mat-option>
        }
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" class="number-form-field">
      @if (textLabels().nationalNumberLabel) {
        <mat-label>{{ textLabels().nationalNumberLabel }}</mat-label>
      }
      <input
        matInput
        type="tel"
        formControlName="numberControl"
        [placeholder]="prefixCtrl.value?.placeHolder || ''"
        autocomplete="off"
        (focus)="onInputFocus()"
        (blur)="onInputBlur()"
        [required]="required()"
        #numberInput
      />

      @if (!hidePhoneIcon()) {
        <mat-icon matSuffix>
          @if (
            iconMakeCall() && fieldControl()?.valid && fieldControl()?.value
          ) {
            <a href="tel:{{ fieldControl()?.value }}">
              <ng-container *ngTemplateOutlet="phoneIcon"> </ng-container>
            </a>
          } @else {
            <ng-container *ngTemplateOutlet="phoneIcon"> </ng-container>
          }
        </mat-icon>
      }
    </mat-form-field>
  </div>

  <ng-template #phoneIcon>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="icon icon-tabler icon-tabler-phone"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path
        d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2"
      />
    </svg>
  </ng-template>

  @if (textLabels().hintLabel) {
    <mat-hint>{{ textLabels().hintLabel }}</mat-hint>
  }
  @if (
    fieldControl()?.hasError('required') &&
    textLabels().requiredError &&
    fieldControl()?.dirty
  ) {
    <mat-error>{{ textLabels().requiredError }}</mat-error>
  }
  @if (
    fieldControl()?.hasError('invalidNumber') && textLabels().invalidNumberError
  ) {
    <mat-error>{{ textLabels().invalidNumberError }}</mat-error>
  }
</section>
