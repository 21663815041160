<h1 mat-dialog-title class="title">Obsługiwany obszar</h1>
<p class="title-desc p-content">Określ obszar obsługiwania napraw </p>
<hr class="custom-hr"/>
<div mat-dialog-content>
  <div class="checkboxes">
      <mat-checkbox [checked]="!isPostalCode" (change)="changeCheckbox(false)" class="checkbox">Według odległości</mat-checkbox>
      <mat-checkbox [checked]="isPostalCode" (change)="changeCheckbox(true)" class="checkbox">Według kodów pocztowych</mat-checkbox>
  </div>
  <div class="did-floating-label-content full-width" *ngIf="!isPostalCode">
    <input class="did-floating-input" type="text" placeholder=" ">
    <label class="did-floating-label">Odległość [km]</label>
  </div>
  <div class="did-floating-label-content full-width" *ngIf="isPostalCode">
    <input class="did-floating-input" type="text" mask="00-000" placeholder=" ">
    <label class="did-floating-label">Kod pocztowy</label>
  </div>

</div>
<div class="text-right buttons" mat-dialog-actions>
  <button mat-button class="primary-btn" color="primary" (click)="add()">Zapisz</button>
  <button mat-button class="secondary-btn" color="accent" [mat-dialog-close]="'cancel'">Anuluj</button>
</div>
