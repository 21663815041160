<div
  class="main-content"
  *ngIf="Auth.isViewAllowed(['ROLE_SHIPMENTS_VIEW', 'ROLE_ADMIN'])">
  <mat-card>
    <div class="card">
      <div class="card-title">
        <p>Przesyłki</p>
        <button mat-stroked-button hidden>Dodaj przesyłkę</button>
      </div>
      <hr class="custom-hr" />
      <div class="table">
        <table mat-table [dataSource]="dataSource">
          <!-- Weight Column -->
          <ng-container matColumnDef="courierName">
            <th mat-header-cell *matHeaderCellDef>Kurier</th>
            <td mat-cell *matCellDef="let element">
              {{ element.courierName }}
            </td>
          </ng-container>

          <!-- Symbol Column -->
          <ng-container matColumnDef="trackingNumber">
            <th mat-header-cell *matHeaderCellDef>Numer listu przewozowego</th>
            <td mat-cell *matCellDef="let element">
              {{ element.trackingNumber }}
            </td>
          </ng-container>

          <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef>Kierunek nadania</th>
            <td mat-cell *matCellDef="let element">
              <p *ngIf="element.type == 'sending'">Wysłana z serwisu</p>
              <p *ngIf="element.type == 'receiving'">Nadana do serwisu</p>
            </td>
          </ng-container>

          <ng-container matColumnDef="createdAt">
            <th mat-header-cell *matHeaderCellDef>Data przesyłki</th>
            <td mat-cell *matCellDef="let element">
              {{ element.createdAt | date }}
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr
            class="hovered"
            mat-row
            *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <div class="empty-table-view" *ngIf="dataSource.data.length === 0">
          <p>{{ 'no_data' | translate }}</p>
        </div>
      </div>
    </div>
  </mat-card>
</div>
