<mat-stepper [linear]="isLinear" #stepper>
  <mat-step
    [stepControl]="firstFormGroup"
    label="{{ 'orders-front.adding_an_invoice' | translate }}">
    <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>

    <p class="title-desc">
      {{ 'orders-front.fill_in_the_information_below' | translate }}
    </p>
    <hr class="custom-hr" />
    <div mat-dialog-content>
      <div class="flex">
        <div class="did-floating-label-content mr-10 disabled">
          <input
            disabled
            class="did-floating-input disabled"
            type="text"
            placeholder=" "
            [(ngModel)]="invoice_number" />
          <label class="did-floating-label disabled">{{
            'orders-front.invoice_number' | translate
          }}</label>
        </div>
        <div class="did-floating-label-content disabled">
          <input
            disabled
            class="did-floating-input disabled"
            matInput
            [matDatepicker]="picker1"
            [(ngModel)]="invoice_date" />
          <mat-error *ngIf="fileSizeError">
            {{ 'file_size_error' | translate }}
          </mat-error>

          <label class="did-floating-label disabled">{{
            'orders-front.invoice_date' | translate
          }}</label>
        </div>
      </div>
      <div class="flex">
        <div class="did-floating-label-content mr-10">
          <input
            disabled
            class="did-floating-input"
            type="text"
            placeholder=" "
            [ngModel]="
              net_invoice_amount | currency : 'PLN' : 'symbol' : '1.2' : 'pl'
            " />
          <label class="did-floating-label">{{
            'orders-front.net_invoice_amount' | translate
          }}</label>
        </div>
        <div class="did-floating-label-content">
          <input
            matInput
            class="did-floating-input"
            type="text"
            placeholder=" "
            [formControl]="gross_invoice_amount"
            required />
          <mat-error *ngIf="invalid">Wartość jest błędna </mat-error>
          <label class="did-floating-label">{{
            'orders-front.gross_invoice_amount' | translate
          }}</label>
        </div>
      </div>
      <p class="p-content">{{ 'orders-front.add_attachment' | translate }}</p>
      <div class="did-floating-label-content w-100 icon">
        <input
          class="did-floating-input"
          readonly
          [formControl]="display"
          type="text"
          placeholder=" " />
        <label class="did-floating-label">{{
          'orders-front.document_name' | translate
        }}</label>
        <input
          type="file"
          multiple
          size="25000000"
          hidden
          #f_input
          (change)="handleFileInputChange(f_input?.files)" />

        <mat-error *ngIf="invalid_file">Brak pliku</mat-error>
        <mat-error *ngIf="res">{{ res || '' }}</mat-error>
        <mat-error *ngIf="error">{{ error.error || '' | translate }}</mat-error>
        <mat-icon (click)="f_input.click()"
          ><mat-icon>publish</mat-icon></mat-icon
        >
      </div>
    </div>
    <div mat-dialog-actions>
      <button
        mat-button
        class="primary-btn right-button"
        color="primary"
        (click)="add(stepper)"
        [disabled]="isLoading">
        {{ 'orders-front.next' | translate }}
      </button>
      <button
        mat-button
        class="secondary-btn"
        color="accent"
        [mat-dialog-close]="'cancel'"
        [disabled]="isLoading">
        {{ 'orders-front.cancel' | translate }}
      </button>
    </div>
  </mat-step>
  <mat-step [stepControl]="secondFormGroup" label="Podsumowanie">
    <h1 class="success">Succes</h1>
    <h3 class="success">Faktura nr {{ invoice_number }} została dodana</h3>

    <button
      mat-button
      class="secondary-btn right-button"
      color="accent"
      [mat-dialog-close]="'succes'"
      [disabled]="isLoading">
      {{ 'orders-front.close' | translate }}
    </button>
  </mat-step>
</mat-stepper>
