<h1 mat-dialog-title class="text-center">
  <mat-icon color="primary">warning</mat-icon>
</h1>
<hr class="custom-hr" />
<div class="text-center" mat-dialog-content>
  <h1 class="title-h">
    {{ 'are_you_sure_you_want_to_delete_service' | translate }} ?<br />
    Usunięcie uzytkownika jest nieodwracalne.
  </h1>
</div>

<div class="text-right buttons" mat-dialog-actions>
  <button mat-button class="primary-btn" [mat-dialog-close]="['confirm']">
    {{ 'users-front.delete' | translate }}
  </button>
  <button mat-button class="secondary-btn" [mat-dialog-close]="'cancel'">
    {{ 'users-front.cancel' | translate }}
  </button>
</div>
