<h1 mat-dialog-title class="text-center">
  <mat-icon class="success">check</mat-icon>
</h1>
<hr class="custom-hr" />
<div class="text-center" mat-dialog-content>
  <h1 *ngIf="!isEdit" class="title-h">Potwierdzenie dodania użytkownika</h1>
  <h1 *ngIf="isEdit"  class="title-h">Dane użytkownika zaktualizowane</h1>
  <p *ngIf="!isEdit">
    Użytkownik został dodany poprawnie
  </p>
  <p *ngIf="isEdit">
    Dane użytkownika zostały poprawnie zaktualizowane
  </p>
</div>
<div class="text-right buttons" mat-dialog-actions>
  <button mat-raised-button color="primary" [mat-dialog-close]="'confirm'">Zamknij</button>
</div>
