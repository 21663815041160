import { Component, Input, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-show-preauth',
  templateUrl: './show-preauth.component.html',
  styleUrls: ['./show-preauth.component.scss'],
})
export class ShowPreAuthComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}
  showPreAuth(): void {}
}
