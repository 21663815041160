<h1 mat-dialog-title class="title">Zakoncz zlecenie</h1>
<hr class="custom-hr" />
<div mat-dialog-content>
  <div class="did-floating-label-content w-100 mt-15">
    <input
      [(ngModel)]="code"
      class="did-floating-input"
      type="text"
      maxlength="20"
      placeholder=" " />
    <label class="did-floating-label">{{ 'code' | translate }}</label>
  </div>
  <div class="did-floating-label-content w-100 mt-15">
    <input
      [(ngModel)]="action"
      maxlength="50"
      class="did-floating-input"
      type="text"
      placeholder=" " />
    <label class="did-floating-label">{{ 'action' | translate }}</label>
  </div>
  <div class="did-floating-label-content w-100 mt-15">
    <textarea
      [(ngModel)]="repairDescription"
      class="did-floating-input"
      maxlength="500"
      placeholder=" ">
    </textarea>
    <label class="did-floating-label">{{
      'repairDescription' | translate
    }}</label>
  </div>
</div>
<div mat-dialog-actions>
  <button
    mat-button
    class="secondary-btn"
    color="accent"
    [mat-dialog-close]="'cancel'">
    {{ 'cancel' | translate }}
  </button>
  <button mat-button class="primary-btn" (click)="onSubmit()" color="primary">
    {{ 'Save' | translate }}
  </button>
</div>
