<div>
  <div class="title">
    <app-breadcrumb [name]="order?.faultNumber"></app-breadcrumb>
  </div>
</div>
<app-settlement-info [order]="order"></app-settlement-info>
<mat-card class="settlement-details">
  <div class="header">
    <div>
      <div class="title-details">Koszta naprawy</div>
      <div
        class="fv-info"
        (click)="downloadFv()"
        *ngIf="order?.billingRevenue?.invoiceNumber">
        Nr FV
        <span>
          {{ order?.billingRevenue?.invoiceNumber || 'brak faktury' }}
          <mat-icon class="downloadFv">download</mat-icon></span
        >
      </div>
      <div *ngIf="!order?.billingRevenue?.invoiceNumber">
        <span>Nr FV: Brak faktury</span>
      </div>
    </div>
  </div>

  <div class="details">
    <hr class="custom-hr" />
    <div class="content-item content-header">
      <p class="flex-70">Nazwa kosztu</p>
      <p>Kwota netto</p>
      <p>Kwota brutto</p>
    </div>
    <hr class="custom-hr" />
    <div class="content-item">
      <p class="flex-70">Części</p>
      <p>
        {{
          order?.costs?.partsWithMarginAndServiceFee || 0 || 0
            | currency : 'PLN' : 'symbol' : '1.2' : 'pl'
            | dashIfEmpty
        }}
      </p>
      <p>
        {{
          (order?.costs?.partsWithMarginAndServiceFee || 0) * 1.23 || 0
            | currency : 'PLN' : 'symbol' : '1.2' : 'pl'
            | dashIfEmpty
        }}
      </p>
    </div>
    <hr class="custom-hr" />
    <div class="content-item">
      <p class="flex-70">Ekspertyza</p>
      <p>
        {{
          (order?.costs?.consultationWithMarginAndServiceFee || 0) +
            (order?.costs?.replacementWithMarginAndServiceFee || 0) || 0
            | currency : 'PLN' : 'symbol' : '1.2' : 'pl'
            | dashIfEmpty
        }}
      </p>
      <p>
        {{
          ((order?.costs?.consultationWithMarginAndServiceFee || 0) +
            (order?.costs?.replacementWithMarginAndServiceFee || 0)) *
            1.23 || 0
            | currency : 'PLN' : 'symbol' : '1.2' : 'pl'
            | dashIfEmpty
        }}
      </p>
    </div>
    <hr class="custom-hr" />
    <div class="content-item">
      <p class="flex-70">Robocizna</p>
      <p>
        {{
          order?.costs?.repairWithMarginAndServiceFee || 0 || 0
            | currency : 'PLN' : 'symbol' : '1.2' : 'pl'
            | dashIfEmpty
        }}
      </p>
      <p>
        {{
          (order?.costs?.repairWithMarginAndServiceFee || 0) * 1.23 || 0
            | currency : 'PLN' : 'symbol' : '1.2' : 'pl'
            | dashIfEmpty
        }}
      </p>
    </div>
    <hr class="custom-hr" />
    <div class="content-item">
      <p class="flex-70">Transport</p>
      <p>
        {{
          (order?.costs?.courierWithMarginAndServiceFee || 0) +
            (order?.costs?.serviceWithMarginAndServiceFee || 0)
            | currency : 'PLN' : 'symbol' : '1.2' : 'pl'
            | dashIfEmpty
        }}
      </p>
      <p>
        {{
          ((order?.costs?.courierWithMarginAndServiceFee || 0) +
            (order?.costs?.serviceWithMarginAndServiceFee || 0)) *
            1.23 || 0
            | currency : 'PLN' : 'symbol' : '1.2' : 'pl'
            | dashIfEmpty
        }}
      </p>
    </div>
    <hr class="custom-hr" />
    <div class="content-item">
      <p class="flex-70">Udział własny</p>
      <p>
        {{
          order?.costs?.deductibleWithMarginAndServiceFee || 0
            | currency : 'PLN' : 'symbol' : '1.2' : 'pl'
            | dashIfEmpty
        }}
      </p>
      <p>
        {{
          order?.costs?.deductibleWithMarginAndServiceFee * 1.23 || 0
            | currency : 'PLN' : 'symbol' : '1.2' : 'pl'
            | dashIfEmpty
        }}
      </p>
    </div>

    <hr class="custom-hr" />
    <div class="content-item">
      <p class="flex-70">Kwota do wypłaty</p>
      <p>
        {{
          order?.costs?.totalNetWithMarginAndServiceFee || 0
            | currency : 'PLN' : 'symbol' : '1.2' : 'pl'
            | dashIfEmpty
        }}
      </p>
      <p>
        {{
          order?.costs?.totalGrossWithMarginAndServiceFee || 0
            | currency : 'PLN' : 'symbol' : '1.2' : 'pl'
            | dashIfEmpty
        }}
      </p>
    </div>
  </div>
</mat-card>
