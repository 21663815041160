import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard extends KeycloakAuthGuard {
  userRoles: string[] = [];
  constructor(
    public override router: Router,
    private keycloak: KeycloakService
  ) {
    super(router, keycloak);
  }

  public async isAccessAllowed(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    // Force the user to log in if currently unauthenticated.
    if (!this.authenticated) {
      await this.keycloak.login({
        redirectUri: window.location.origin + state.url,
      });
    }

    // Get the roles required from the route.
    const requiredRoles = route.data.roles;

    // Allow the user to proceed if no additional roles are required to access the route.
    if (!Array.isArray(requiredRoles) || requiredRoles.length === 0) {
      return true;
    }
    this.userRoles = this.roles;
    localStorage.setItem('userRoles', JSON.stringify(this.roles));
    // Allow the user to proceed if one of  required roles are present.
    return requiredRoles.some(role => this.roles.includes(role));
  }

  public isViewAllowed(roles: string[]): boolean {
    this.roles = this.keycloak.getUserRoles();
    // Get the roles required from the route.
    const requiredRoles = roles;
    // Allow the user to proceed if no additional roles are required to access the route.
    if (!Array.isArray(requiredRoles) || requiredRoles.length === 0) {
      return true;
    }
    this.userRoles = this.roles;
    // Allow the user to proceed if one of  required roles are present.
    return requiredRoles.some(role => this.userRoles.includes(role));
  }
}
