import { Component, Input } from '@angular/core';
import { OrdersService } from 'src/app/pages/orders/services/orders.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.html',
  styleUrls: ['./notifications.scss'],
})
export class NotificationsComponent {
  @Input() someInput: string | undefined;
  orders: any;

  constructor(private orderService: OrdersService) {
    this.orderService.getNewOrders().subscribe(res => {
      this.orders = res['hydra:member'];
    });
  }
}
