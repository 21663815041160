import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './app/environments/environment';
import { Buffer } from 'buffer';
const load = async () => {
  try {
    let apiConf;
    if (location.origin.includes('localhost')) {
      try {
        apiConf = await fetch('localdata/config.json').then(res => res.text());
      } catch (e) {
        /* empty */
      }
    } else {
      apiConf = await fetch('/config.json').then(res => res.text());
    }
    apiConf = decodeBase64(apiConf);
    //

    let apiConfJson;
    try {
      apiConfJson = JSON.parse(apiConf);
    } catch (e) {
      /* empty */
    }
    if (apiConfJson) {
      if (apiConfJson.API.apiPointInternal) {
        environment.API.apiPointInternal = apiConfJson.API.apiPointInternal;
      }
      if (apiConfJson.API.apiPointPartners) {
        environment.API.apiPointPartners = apiConfJson.API.apiPointPartners;
      }
      if (apiConfJson.API.apiHost) {
        environment.API.apiHost = apiConfJson.API.apiHost;
      }
      if (apiConfJson.Keycloak) {
        environment.Keycloak = apiConfJson.Keycloak;
      }
      if (apiConfJson.realm) {
        environment.realm = apiConfJson.realm;
      }
      if (apiConfJson.clientId) {
        environment.clientId = apiConfJson.clientId;
      }
      if (apiConfJson.statusGroup) {
        environment.statusGroup = apiConfJson.statusGroup;
      }
      if (apiConfJson.statusGroupArchived) {
        environment.statusGroupArchived = apiConfJson.statusGroupArchived;
      }
      if (apiConfJson.settlements) {
        environment.settlements = apiConfJson.settlements;
      }
      if (apiConfJson.costs) {
        environment.costs = apiConfJson.costs;
      }
      if (apiConfJson.validators) {
        environment.validators = apiConfJson.validators;
      }
      if (apiConfJson.sessionTime) {
        environment.sessionTime = apiConfJson.sessionTime;
      }
      if (apiConfJson.sessionWarning) {
        environment.sessionWarning = apiConfJson.sessionWarning;
      }
      if (apiConfJson.language) {
        environment.language = apiConfJson.language;
      }
    }
  } catch (e) {
    console.error(e);
  }

  if (environment.production) {
    enableProdMode();
  }

  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch(err => console.error(err));
};

load().then();
const decodeBase64 = (data: any) => {
  return Buffer.from(data, 'base64').toString('ascii');
};
