<div>
  <div class="title">
    <app-breadcrumb></app-breadcrumb>
  </div>
  <div class="flex">
    <div class="left-flex">
      <div class="card">
        <div class="card-title">
          {{ 'settings-front.system_margins' | translate }}
          <div>
            <button
              (click)="editMargin()"
              mat-stroked-button
              *ngIf="
                Auth.isViewAllowed([
                  'ROLE_SYSTEM_MARGIN_SETTINGS',
                  'ROLE_ADMIN'
                ])
              ">
              Edytuj
            </button>

            {{ ' ' }}

            <button
              (click)="show()"
              mat-stroked-button
              class="show-data-button"
              *ngIf="
                Auth.isViewAllowed([
                  'ROLE_SYSTEM_MARGIN_SETTINGS',
                  'ROLE_ADMIN'
                ]) && !showData
              ">
              <mat-icon>visibility</mat-icon> Pokaż
            </button>

            <button
              class="show-data-button"
              (click)="hide()"
              mat-stroked-button
              *ngIf="
                Auth.isViewAllowed([
                  'ROLE_SYSTEM_MARGIN_SETTINGS',
                  'ROLE_ADMIN'
                ]) && showData
              ">
              <mat-icon>visibility_off</mat-icon> Ukryj
            </button>
          </div>
        </div>
        <hr class="custom-hr" />
        <div class="card-content">
          <div class="left-site">
            <div class="content-item">
              <p>{{ 'settings-front.default_margin' | translate }}</p>
              <p *ngIf="showData">
                {{
                  systemMargin?.createdAt | date : 'YYYY-MM-dd'
                }}&nbsp;&nbsp;&nbsp;&nbsp;
                {{ (systemMargin?.generalMargin || 0).toFixed(2) }}%
              </p>
              <p *ngIf="!showData">***</p>
            </div>
            <hr class="custom-hr" />
            <div class="content-item">
              <p>{{ 'settings-front.parts' | translate }}</p>
              <p *ngIf="showData">
                {{ systemMargin?.createdAt | date : 'YYYY-MM-dd' }}
                &nbsp;&nbsp;&nbsp;&nbsp;
                {{ (systemMargin?.partsMargin || 0).toFixed(2) }}%
              </p>
              <p *ngIf="!showData">***</p>
            </div>
            <hr class="custom-hr" />
            <div class="content-item">
              <p>{{ 'settings-front.labour' | translate }}</p>
              <p *ngIf="showData">
                {{ systemMargin?.createdAt | date : 'YYYY-MM-dd' }}
                &nbsp;&nbsp;&nbsp;&nbsp;
                {{ (systemMargin?.repairMargin || 0).toFixed(2) }}%
              </p>
              <p *ngIf="!showData">***</p>
            </div>
            <hr class="custom-hr" />
            <div class="content-item">
              <p>{{ 'settings-front.transport' | translate }}</p>
              <p *ngIf="showData">
                {{ systemMargin?.createdAt | date : 'YYYY-MM-dd' }}
                &nbsp;&nbsp;&nbsp;&nbsp;
                {{ (systemMargin?.transportMargin || 0).toFixed(2) }}%
              </p>
              <p *ngIf="!showData">***</p>
            </div>
            <hr class="custom-hr" />
            <div class="content-item">
              <p>{{ 'settings-front.expertise' | translate }}</p>
              <p *ngIf="showData">
                {{ systemMargin?.createdAt | date : 'YYYY-MM-dd' }}
                &nbsp;&nbsp;&nbsp;&nbsp;
                {{ systemMargin?.consultationMargin.toFixed(2) }}%
              </p>
              <p *ngIf="!showData">***</p>
            </div>

            <hr class="custom-hr" />
            <div class="content-item">
              <p>{{ 'settings-front.serviceFee' | translate }}</p>
              <p *ngIf="showData">
                {{ systemMargin?.createdAt | date : 'YYYY-MM-dd' }}
                &nbsp;&nbsp;&nbsp;&nbsp;
                {{ (systemMargin?.serviceFee || 0).toFixed(2) }}%
              </p>
              <p *ngIf="!showData">***</p>
            </div>
          </div>
        </div>
      </div>
      <div class="card" *ngIf="false">
        <div class="card-title">
          {{ 'settings-front.own_margins' | translate }}
          <button (click)="checkMargin()" mat-stroked-button>Sprawdź</button>
        </div>
        <hr class="custom-hr" />
        <div class="card-content">
          <div class="left-site">
            <div class="content-item">
              <p>{{ 'settings-front.high' | translate }}</p>
              <p>12.03.2022 &nbsp;&nbsp;&nbsp;&nbsp; 1,00% |mock</p>
            </div>
            <hr class="custom-hr" />
            <div class="content-item">
              <p>{{ 'settings-front.medium' | translate }}</p>
              <p>12.03.2022 &nbsp;&nbsp;&nbsp;&nbsp; 1,00% |mock</p>
            </div>
            <hr class="custom-hr" />
            <div class="content-item">
              <p>{{ 'settings-front.little' | translate }}</p>
              <p>12.03.2022 &nbsp;&nbsp;&nbsp;&nbsp; 1,00% |mock</p>
            </div>
            <hr class="custom-hr" />
            <div class="content-item">
              <p>{{ 'settings-front.low' | translate }}</p>
              <p>12.03.2022 &nbsp;&nbsp;&nbsp;&nbsp; 1,00% |mock</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="right-flex">
      <div class="card" *ngIf="false">
        <div class="card-title">
          Sieci
          <button mat-stroked-button>
            {{ 'settings-front.edit' | translate }}
          </button>
        </div>
        <hr class="custom-hr" />
        <div class="card-content">
          <div class="left-site">
            <div class="content-item">
              <p>ME |mock</p>
              <p>0 |mock</p>
            </div>
            <hr class="custom-hr" />
            <div class="content-item">
              <p>SD |mock</p>
              <p>0 |mock</p>
            </div>
            <hr class="custom-hr" />
            <div class="content-item">
              <p>SIEC AAA |mock</p>
              <p>12% |mock</p>
            </div>
            <hr class="custom-hr" />
            <div class="content-item">
              <p>MACRO |mock</p>
              <p>10% |mock</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
