import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OrdersService } from 'src/app/pages/orders/services/orders.service';
import { ServicesService } from 'src/app/pages/services/services/services.service';

@Component({
  selector: 'app-change-service',
  templateUrl: './change-service.component.html',
  styleUrls: ['./change-service.component.scss'],
})
export class ChangeServiceComponent implements OnInit {
  services: any;
  service: any = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private orderService: OrdersService,
    private servicesService: ServicesService
  ) {}

  ngOnInit(): void {
    this.servicesService.getServices().subscribe((res: any) => {
      this.services = res['hydra:member'];
    });
  }
}
