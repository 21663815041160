import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/app/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  constructor(private http: HttpClient) {}

  getMargins() {
    return this.http.get(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        '/order_margins'
    );
  }

  getSystemMargins() {
    return this.http.get(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        '/system_margins/1'
    );
  }

  updateSystemMargins(body: any) {
    return this.http.put(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        '/system_margins/1',
      body
    );
  }
}
