import { Component, Inject } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { DialogRef } from '@angular/cdk/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ServicesService } from '../../../pages/services/services/services.service';
import { environment } from 'src/app/environments/environment';

@Component({
  selector: 'app-add-contact',
  templateUrl: './add-contact.component.html',
  styleUrls: ['./add-contact.component.scss'],
})
export class AddContactComponent {
  public formContactInfo!: FormGroup;
  public submitted!: boolean;
  phone1!: FormControl<string | null>;
  phone2!: FormControl<string | null>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private dialogRef: DialogRef,
    private serviceService: ServicesService
  ) {
    this.formContactInfo = this.fb.group({
      name: ['', Validators.required],
      surname: ['', Validators.required],
      phoneNumber: ['', Validators.required],
      secondNumber: ['', Validators.required],
      email: ['', Validators.required],
      type: ['generic', Validators.required],
    });

    this.formContactInfo = this.fb.group({
      name: [
        '',
        [Validators.required, Validators.pattern(environment.validators.name)],
      ],
      surname: [
        '',
        [
          Validators.required,
          Validators.pattern(environment.validators.surname),
        ],
      ],
      phoneNumber: ['', [Validators.required, Validators.pattern('[+0-9 ]*')]],

      secondNumber: ['', [Validators.pattern('[+0-9 ]*')]],

      email: [
        '',
        [Validators.required, Validators.pattern(environment.validators.email)],
      ],
      type: ['', Validators.required],
    });

    this.phone1 = this.formContactInfo.get('phoneNumber') as FormControl;
    this.phone2 = this.formContactInfo.get('secondNumber') as FormControl;
  }

  add(): void {
    this.submitted = true;
    if (this.formContactInfo.valid) {
      let body = {
        workshop: this.data.service['@id'],
        contactDetails: {
          email: this.formContactInfo.value.email,
          mobileNumber: this.formContactInfo.value.phoneNumber.replace(
            / /g,
            ''
          ),
          phoneNumber: this.formContactInfo.value.secondNumber.replace(
            / /g,
            ''
          ),
          description: 'string',
          name: this.formContactInfo.value.name,
          surname: this.formContactInfo.value.surname,
          type: this.formContactInfo.value.type,
        },
      };

      this.serviceService.addServiceContact(body).subscribe(res => {
        this.dialogRef.close('confirm');
      });
    }
  }
}
