<h1 mat-dialog-title class="title">
  {{ 'services-front.edit_service' | translate }}
</h1>
<p class="title-desc">
  {{ 'services-front.fill_in_the_information_below' | translate }}
</p>
<hr class="custom-hr" />

<div mat-dialog-content>
  <div [hidden]="tab !== 'companyInfo'">
    <form [formGroup]="formCompanyInfo">
      <div class="did-floating-label-content col-2 mt-15">
        <input
          class="did-floating-input"
          formControlName="name"
          type="text"
          placeholder=" "
          required />
        <label class="did-floating-label">{{
          'services-front.company_name' | translate
        }}</label>
        <mat-error
          *ngIf="companyNext && formCompanyInfo.controls['name'].errors?.['required']"
          >{{ 'services-front.field_is_required' | translate }}</mat-error
        >
      </div>
      <div class="flex first-flex">
        <div class="did-floating-label-content mr-10 mt-15">
          <input
            class="did-floating-input"
            formControlName="regon"
            type="text"
            mask="000000000"
            required
            placeholder=" " />
          <label class="did-floating-label">{{
            'services-front.regon' | translate
          }}</label>
          <mat-error
            *ngIf="companyNext && formCompanyInfo.controls['regon'].errors?.['required']"
            >{{ 'services-front.field_is_required' | translate }}</mat-error
          >
          <mat-error
            *ngIf="companyNext && formCompanyInfo.controls['regon'].errors?.['pattern']"
            >To pole wymaga podania 9 cyfrowego numeru regon</mat-error
          >
        </div>

        <div class="did-floating-label-content mt-15">
          <input
            class="did-floating-input"
            formControlName="nip"
            mask="0000000000"
            type="text"
            required
            placeholder=" " />

          <label class="did-floating-label">{{
            'services-front.nip' | translate
          }}</label>
          <mat-icon
            matPrefix
            class="icon-inside-input"
            (click)="getGusInfo()"
            matTooltip="{{ 'services-front.find_by_nip' | translate }}"
            >search</mat-icon
          >
          <mat-error
            *ngIf="companyNext && formCompanyInfo.controls['nip']?.errors?.['required']"
            >{{ 'services-front.field_is_required' | translate }}</mat-error
          >
          <mat-error *ngIf="gusError">
            <p *ngIf="gusError == 404">
              Nie znaleziono podmiotu o takim numerze NIP
            </p>
            <p *ngIf="gusError == 400">Błędny numer NIP</p>
            <p *ngIf="gusError == 'other'"></p>
          </mat-error>
          <mat-error
            *ngIf="!(companyNext && formCompanyInfo.controls['nip'].errors?.['required']) && companyNext && formCompanyInfo.controls['nip'].errors?.['pattern'] || !(companyNext && formCompanyInfo.controls['nip'].errors?.['required']) && gusInfo  "
            >To pole wymaga podania 10 cyfrowego numeru nip
          </mat-error>
        </div>
      </div>
      <div class="flex">
        <div class="did-floating-label-content mr-10 mt-15">
          <input
            class="did-floating-input"
            formControlName="city"
            required
            type="text"
            placeholder=" " />
          <label class="did-floating-label">{{
            'services-front.city' | translate
          }}</label>
          <mat-error
            *ngIf="companyNext && formCompanyInfo.controls['city'].errors?.['required']"
            >{{ 'services-front.field_is_required' | translate }}</mat-error
          >
          <mat-error
            *ngIf="companyNext && (formCompanyInfo.controls['city'].errors?.['minlength'] || formCompanyInfo.controls['city'].errors?.['maxlength'])"
            >To pole wymaga podania prawidłowej nazwy miasta</mat-error
          >
        </div>
        <div class="did-floating-label-content mt-15">
          <input
            class="did-floating-input"
            formControlName="postalCode"
            required
            mask="00-000"
            type="text"
            placeholder=" " />
          <label class="did-floating-label">{{
            'services-front.postal_code' | translate
          }}</label>
          <mat-error
            *ngIf="companyNext && formCompanyInfo.controls['postalCode']?.errors?.['required']"
            >{{ 'services-front.field_is_required' | translate }}</mat-error
          >
          <mat-error
            *ngIf="companyNext && formCompanyInfo.controls['postalCode']?.errors?.['mask'] || companyNext && formCompanyInfo.controls['postalCode']?.errors?.['pattern']"
            >To pole wymaga podania prawidłowego nr pocztowego</mat-error
          >
        </div>
      </div>
      <div class="flex">
        <div class="did-floating-label-content mr-10 mt-15">
          <input
            class="did-floating-input"
            formControlName="street"
            required
            type="text"
            placeholder=" " />
          <label class="did-floating-label">{{
            'services-front.street' | translate
          }}</label>
          <mat-error
            *ngIf="companyNext && formCompanyInfo.controls['street'].errors?.['required']"
            >{{ 'services-front.field_is_required' | translate }}</mat-error
          >
          <mat-error
            *ngIf="companyNext && (formCompanyInfo.controls['street'].errors?.['minlength'] || formCompanyInfo.controls['street'].errors?.['maxlength'])"
            >To pole wymaga podania prawidłowej nazwy ulicy</mat-error
          >
        </div>
        <div class="did-floating-label-content small-input mt-15 mr-10">
          <input
            class="did-floating-input"
            formControlName="houseNumber"
            required
            type="text"
            placeholder=" " />
          <label class="did-floating-label">{{
            'services-front.house_number' | translate
          }}</label>
          <mat-error
            *ngIf="companyNext && formCompanyInfo.controls['houseNumber'].errors?.['required']"
            >{{ 'services-front.field_is_required' | translate }}</mat-error
          >
          <mat-error
            *ngIf="companyNext && formCompanyInfo.controls['houseNumber'].errors?.['pattern']"
            >To pole wymaga podania prawidłowego numeru domu</mat-error
          >
        </div>
        <div class="did-floating-label-content small-input mt-15">
          <input
            class="did-floating-input"
            formControlName="apartmentNumber"
            type="text"
            placeholder=" " />
          <label class="did-floating-label">{{
            'services-front.apartment_number' | translate
          }}</label>
        </div>
      </div>

      <div class="flex">
        <div class="did-floating-label-content mr-10 mt-15">
          <input
            class="did-floating-input"
            formControlName="bankNumber"
            required
            mask="SS00-0000-0000-0000-0000-0000-0000"
            type="text"
            placeholder="AA00-0000-0000-0000-0000-0000-0000"
            oninput="this.value = this.value.toUpperCase()" />
          <label class="did-floating-label did-floating-label-focus">{{
            'services-front.bank_account_number(IBAN)' | translate
          }}</label>
          <mat-error
            *ngIf="companyNext && formCompanyInfo.controls['bankNumber'].errors?.['required']"
            >{{ 'services-front.field_is_required' | translate }}</mat-error
          >
          <mat-error
            *ngIf="companyNext && formCompanyInfo.controls['bankNumber'].errors?.['pattern'] || companyNext && formCompanyInfo.controls['bankNumber'].errors?.['mask']"
            >To pole wymaga podania prawidłowego nr konta</mat-error
          >
        </div>
        <div class="did-floating-label-content mt-15">
          <input
            class="did-floating-input"
            formControlName="number"
            type="text"
            required
            placeholder=" " />
          <label class="did-floating-label">{{
            'services-front.agreement_number' | translate
          }}</label>
          <mat-error
            *ngIf="companyNext && formCompanyInfo.controls['number'].errors?.['required']"
            >{{ 'services-front.field_is_required' | translate }}</mat-error
          >
        </div>
      </div>
    </form>
  </div>
</div>
<div class="text-right buttons" mat-dialog-actions>
  <button
    mat-button
    class="secondary-btn"
    color="accent"
    [mat-dialog-close]="'cancel'">
    {{ 'services-front.cancel' | translate }}
  </button>

  <button mat-button class="primary-btn" color="primary" (click)="save()">
    {{ 'services-front.save' | translate }}
  </button>
</div>
