<div
  class="user-info"
  [matMenuTriggerFor]="menu"
  (mouseover)="createMenuTrigger.openMenu()"
  #createMenuTrigger="matMenuTrigger">
  <div class="icon">
    <mat-icon>person</mat-icon>
  </div>
  <div class="name" *ngIf="opened">
    <p>{{ user?.name }} {{ user?.surname }}</p>
  </div>
</div>
<mat-menu
  class="user-menu"
  #menu="matMenu"
  yPosition="above"
  (mouseleave)="createMenuTrigger.closeMenu()">
  <div class="menu-item" [routerLink]="'/my-profile'">
    {{ 'users-front.my_account' | translate }}
  </div>
  <hr class="custom-hr menu-item" />
  <div (click)="logout()" class="menu-item">
    {{ 'users-front.logout' | translate }}
  </div>
</mat-menu>
