import { KeycloakService } from 'keycloak-angular';
import { environment } from 'src/app/environments/environment';

export function initializeKeycloak(keycloak: KeycloakService) {
  return () =>
    keycloak.init({
      bearerExcludedUrls: [
        environment.Keycloak +
          '/realms/' +
          environment.realm +
          '/protocol/openid-connect/userinfo',
      ],
      config: {
        url: environment.Keycloak,
        realm: 'drs',
        clientId: 'drsapp',
      },
      initOptions: {
        onLoad: 'login-required',
        checkLoginIframe: false,
      },
    });
}
