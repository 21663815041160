import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { OrdersService } from '../../../services/orders.service';
import { AuthGuard } from 'src/app/shared/guards/access-allowed.guard';

@Component({
  selector: 'app-order-packages',
  templateUrl: './order-packages.component.html',
  styleUrls: ['./order-packages.component.scss'],
})
export class OrderPackagesComponent implements OnInit {
  @Input() order: any;
  dataSource: any;
  constructor(private ordersService: OrdersService, public Auth: AuthGuard) {}

  ngOnInit(): void {
    this.ordersService.getPackages(this.order.id).subscribe((res: any) => {
      this.dataSource = new MatTableDataSource(res['hydra:member']);
    });
  }
  displayedColumns: string[] = [
    'courierName',
    'trackingNumber',
    'type',
    'createdAt',
  ];
}
