<h1 mat-dialog-title class="text-center">
  <mat-icon color="primary">warning</mat-icon>
</h1>
<hr class="custom-hr" />
<div class="text-center" mat-dialog-content>
  <h1 class="title-h">
    {{
      isActivate
        ? ('users-front.are_you_sure_you_want_to_unlock' | translate)
        : ('users-front.are_you_sure_you_want_to_block' | translate)
    }}<br />
    konto użytkownika {{ username }}?
  </h1>
  <p *ngIf="!isActivate">
    {{ 'users-front.blocking_a_user_will' | translate }}<br />
    {{
      'users-front.result_in_not_being_able_to_use_the_application' | translate
    }}
  </p>
  <p *ngIf="isActivate">
    <mat-slide-toggle (change)="setValue($event)"
      >>{{
        'users-front.generate_a_temporary_startup_password' | translate
      }}</mat-slide-toggle
    >
  </p>
</div>

<div class="text-right buttons" mat-dialog-actions>
  <button
    mat-button
    class="primary-btn"
    [mat-dialog-close]="['confirm', checked]">
    {{
      isActivate
        ? ('users-front.unlock' | translate)
        : ('users-front.lock' | translate)
    }}
  </button>
  <button mat-button class="secondary-btn" [mat-dialog-close]="'cancel'">
    {{ 'users-front.cancel' | translate }}
  </button>
</div>
