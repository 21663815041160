import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
  selector: 'app-user-confirm-action',
  templateUrl: './user-confirm-action.component.html',
  styleUrls: ['./user-confirm-action.component.scss']
})
export class UserConfirmActionComponent implements OnInit {

  public isEdit: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {

  }

  ngOnInit(): void {
    this.isEdit = this.data?.isEdit
  }


}
