import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EditMarginComponent } from '../../shared/modals/edit-margin/edit-margin.component';
import { CheckMarginComponent } from '../../shared/modals/check-margin/check-margin.component';
import { SettingsService } from './services/settings.service';
import { AuthGuard } from 'src/app/shared/guards/access-allowed.guard';
import { ShowDataDefaultComponent } from 'src/app/shared/modals/show-data-default/show-data-default.component';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
  systemMargin: any;
  showData: boolean = false;

  constructor(
    private dialog: MatDialog,
    private settingsService: SettingsService,
    public Auth: AuthGuard
  ) {}

  ngOnInit(): void {
    this.settingsService.getSystemMargins().subscribe(res => {
      this.systemMargin = res;
    });
  }

  editMargin(): void {
    const dialogRef = this.dialog.open(EditMarginComponent, {
      data: { margin: this.systemMargin, system: true },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      this.settingsService.getSystemMargins().subscribe(res => {
        this.systemMargin = res;
      });
      if (result === 'show') {
      }
    });
  }

  checkMargin(): void {
    const dialogRef = this.dialog.open(CheckMarginComponent, {
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'show') {
      }
    });
  }

  show() {
    const dialogRef = this.dialog.open(ShowDataDefaultComponent, {
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'show') {
        this.showData = true;
      }
    });
  }

  hide() {
    this.showData = false;
  }
}
