import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-admin-panel',
  templateUrl: './admin-panel.component.html',
  styleUrls: ['./admin-panel.component.scss'],
})
export class AdminPanelComponent implements OnInit {
  dataSource: any;
  displayedColumns: string[] = [
    'commandName',
    'description',
    'details',
    'isActive',
    'lastRun',
    'noActivityAlertAfter',
    'status',
  ];
  constructor(public userService: UserService) {}
  ngOnInit(): void {
    this.userService.getCroneHealthCheck().subscribe(res => {
      this.dataSource = res;
    });
  }
}
