<mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
<h1 mat-dialog-title class="title">
  {{ 'orders-front.add_document' | translate }}
</h1>
<p class="title-desc">
  {{ 'orders-front.fill_in_the_information_below' | translate }}
</p>
<hr class="custom-hr" />
<div mat-dialog-content>
  <div>
    <div class="did-floating-label-content">
      <select class="did-floating-select" [formControl]="type" required>
        <option value=""></option>
        <option *ngFor="let type of types" [value]="type.id">
          {{ type.claimsName }}
        </option>
      </select>
      <label class="did-floating-label">{{
        'orders-front.document_type' | translate
      }}</label>
    </div>
  </div>

  <p class="p-content">
    Dodaj załącznik (maksymalny rozmiar {{ maxSize | fileSize }})
  </p>
  <div class="did-floating-label-content w-100 icon">
    <input
      class="did-floating-input"
      [ngClass]="{ 'warn': fileSizeError }"
      readonly
      [formControl]="display"
      type="text"
      placeholder=" " />
    <label class="did-floating-label">{{
      'orders-front.document_name' | translate
    }}</label>
    <input
      type="file"
      multiple
      hidden
      #f_input
      (change)="handleFileInputChange(f_input?.files)" />
    <mat-icon (click)="f_input.click()"><mat-icon>publish</mat-icon></mat-icon>

    <mat-error *ngIf="fileSizeError">
      {{ 'file_size_error' | translate }}
    </mat-error>
    <mat-error *ngIf="missing_file">
      {{ 'missing_file' | translate }}
    </mat-error>
    <mat-error *ngIf="missing_type">
      {{ 'missing_type' | translate }}
    </mat-error>
  </div>
  <p class="p-content">
    {{ 'orders-front.visibility' | translate }}
  </p>

  <section class="example-section">
    <mat-checkbox class="example-margin" [(ngModel)]="toPartner">{{
      'orders-front.toPartner' | translate
    }}</mat-checkbox>
    <mat-checkbox class="example-margin" [(ngModel)]="toOrderSource">{{
      'orders-front.toOrderSource' | translate
    }}</mat-checkbox>
  </section>
</div>
<div mat-dialog-actions>
  <button
    mat-button
    class="primary-btn"
    color="primary"
    (click)="add()"
    [disabled]="fileSizeError">
    {{ 'orders-front.save' | translate }}
  </button>
  <button
    mat-button
    class="secondary-btn"
    color="accent"
    [mat-dialog-close]="'cancel'">
    {{ 'orders-front.cancel' | translate }}
  </button>
</div>
