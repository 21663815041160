<div class="text-center" mat-dialog-content>
  <h1 class="title-h">
    {{ 'are_you_sure_you_want_to_archive_this_order' | translate }}
  </h1>
  <p class="red">
    {{ 'After_saving_the_changes_return_is_not_possible' | translate }}
  </p>
</div>
<div class="text-right buttons" mat-dialog-actions>
  <button mat-raised-button class="secondary-btn" [mat-dialog-close]="'cancel'">
    {{ 'margin.cancel' | translate }}
  </button>
  <button mat-raised-button class="primary-btn" [mat-dialog-close]="'confirm'">
    {{ 'margin.confirm' | translate }}
  </button>
</div>
