<h1 mat-dialog-title class="title">Generowanie zestawienia kosztów zleceń</h1>
<h4>Podaj zakres dat dla którego wygenerować zestawienia kosztów zleceń</h4>
<hr class="custom-hr" />
<div mat-dialog-content>
  <mat-card>
    <mat-form-field class="filter-field" appearance="outline">
      <mat-label class="form-label"
        >{{ 'orderInvoiceDateFrom' | translate }}</mat-label
      >
      <input
        matInput
        class="form-label"
        [matDatepicker]="picker1"
        [formControl]="orderFinishDateFrom"
        required />

      <mat-datepicker-toggle
        matIconSuffix
        [for]="picker1"></mat-datepicker-toggle>
      <mat-error *ngIf="orderFinishDateFrom.hasError('required')">
        {{ 'requiredField' | translate }}
      </mat-error>
      <mat-datepicker #picker1></mat-datepicker>
    </mat-form-field>
    <mat-form-field class="filter-field" appearance="outline">
      <mat-label class="form-label"
        >{{ 'orderInvoiceDateTo' | translate }}</mat-label
      >
      <input
        matInput
        class="form-label"
        [matDatepicker]="picker2"
        [formControl]="orderFinishDateTo"
        required />

      <mat-datepicker-toggle
        matIconSuffix
        [for]="picker2"></mat-datepicker-toggle>
      <mat-datepicker #picker2></mat-datepicker>
      <mat-error *ngIf="orderFinishDateTo.hasError('required')">
        {{ 'requiredField' | translate }}
      </mat-error>
    </mat-form-field>
  </mat-card>
</div>
<div mat-dialog-actions>
  <button
    mat-button
    class="secondary-btn"
    color="accent"
    [mat-dialog-close]="'cancel'">
    {{ 'cancel' | translate }}
  </button>
  <button
    mat-button
    class="primary-btn"
    color="primary"
    (click)="downloadSummary()">
    {{ 'generate' | translate }}
  </button>
</div>
