<h1 mat-dialog-title class="text-center">
  <mat-icon color="primary">warning</mat-icon>
</h1>
<hr class="custom-hr" />
<div class="text-center" mat-dialog-content>
  <h1 class="title-h">Zmiana serwisu</h1>
  <mat-form-field class="service" appearance="outline">
    <mat-select [(ngModel)]="service" class="service">
      <mat-option *ngFor="let service of services" [value]="service.id">
        {{ service.name | translate }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>

<div class="text-right buttons" mat-dialog-actions>
  <button
    mat-button
    class="primary-btn"
    [mat-dialog-close]="['confirm', service]">
    Zapisz
  </button>
  <button mat-button class="secondary-btn" [mat-dialog-close]="'cancel'">
    Anuluj
  </button>
</div>
