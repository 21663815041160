import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { OrdersService } from './services/orders.service';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { HttpParams } from '@angular/common/http';
import { Sort } from '@angular/material/sort';
import { AuthGuard } from 'src/app/shared/guards/access-allowed.guard';
import { environment } from 'src/app/environments/environment';
import { Clipboard } from '@angular/cdk/clipboard';

import { ServicesService } from '../services/services/services.service';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss'],
})
export class OrdersComponent implements OnInit {
  showFilters: any = false;
  sort: any = {
    active: 'createdAt',
    direction: 'desc',
  };
  Array: any = [];
  filterArray: any;
  preauthList: any = [];
  createdAtToValue: any;
  createdAtFromValue: any;
  faultNumberValue: any;
  preauthValue: any;
  workshopNameValue: any;
  deviceNameValue: any;
  repairCodeValue: any;
  deductibleCostsExistsValue: any;
  constructor(
    private ordersService: OrdersService,
    private servicesService: ServicesService,
    private router: Router,
    public Auth: AuthGuard,
    public translateService: TranslateService,
    private clipboard: Clipboard,
    private _snackBar: MatSnackBar
  ) {}
  itemsPerPage: number = 30;
  offset: number = 0;
  pageIndex: number = 0;
  faultNumber = new FormControl();
  createdAtFrom = new FormControl();
  createdAtTo = new FormControl();
  workshopName = new FormControl();
  deductibleCostsExists = new FormControl();
  deviceName = new FormControl();
  vendorCity = new FormControl();
  repairCode = new FormControl();
  serviceTime = new FormControl();
  state = new FormControl();
  preauth = new FormControl();
  filteredStates: any[] = [];
  statesCount: any;
  statesCountGrouped: any[string] = [];
  stateList: any = [];
  workshops: any = [];
  preAuthCount: any = 0;

  filteredValues = {
    faultNumber: '',
    createdAtFrom: 0,
    createdAtTo: 0,
    workshopName: '',
    deductibleCostsExists: '',
    deviceName: '',
    vendorCity: '',
    repairCode: '',
    serviceTime: '',
    state: '',
    preauth: '',
  };

  displayedColumns: string[] = [
    'Lp',
    'faultNumber',
    'createdAt',
    'service',
    'deviceName',
    'vendorCity',
    'repairCode',
    'preauthorizationStatus',
    'state',
    'serviceTime',
  ];
  dataSource: any;
  tab = 'Wszystkie';
  selection: any[] = [];
  statusGroup: any = environment.statusGroup;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  ngOnInit(): void {
    this.getWorkshops();
    this.tab = localStorage.getItem('orderGroup') ?? 'Wszystkie';
    this.preauthList = [
      'nopreauth',
      'requested',
      'rejected',
      'accepted',
      'total-loss',
    ];

    let params = new HttpParams();
    params = params.append('limit', this.itemsPerPage);
    params = params.append('sort[createdAt]', 'desc');
    this.getStatuses();

    this.faultNumber.valueChanges.subscribe(positionFilterValue => {
      this.filteredValues['faultNumber'] = positionFilterValue;
    });
    this.workshopName.valueChanges.subscribe(positionFilterValue => {
      this.filteredValues['workshopName'] = positionFilterValue;
    });

    this.createdAtFrom.valueChanges.subscribe(weightFilterValue => {
      this.filteredValues['createdAtFrom'] = weightFilterValue;
    });
    this.createdAtTo.valueChanges.subscribe(weightFilterValue => {
      this.filteredValues['createdAtTo'] = weightFilterValue;
    });
    this.deviceName.valueChanges.subscribe(weightFilterValue => {
      this.filteredValues['deviceName'] = weightFilterValue;
    });
    this.vendorCity.valueChanges.subscribe(weightFilterValue => {
      this.filteredValues['vendorCity'] = weightFilterValue;
    });
    this.repairCode.valueChanges.subscribe(weightFilterValue => {
      this.filteredValues['repairCode'] = weightFilterValue;
    });
    this.serviceTime.valueChanges.subscribe(weightFilterValue => {
      this.filteredValues['serviceTime'] = weightFilterValue;
    });
    this.deductibleCostsExists.valueChanges.subscribe(weightFilterValue => {
      this.filteredValues['deductibleCostsExists'] = weightFilterValue;
    });
    this.state.valueChanges.subscribe(weightFilterValue => {
      this.filteredValues['state'] = weightFilterValue;
    });
    this.preauth.valueChanges.subscribe(weightFilterValue => {
      this.filteredValues['preauth'] = weightFilterValue;
    });
    if (localStorage.getItem('orderFilters') != null) {
      this.filterArray = JSON.parse(localStorage.getItem('orderFilters') ?? '');
    }
    if (this.filterArray != null) {
      this.faultNumber.setValue(this.filterArray['faultNumber']);
      this.createdAtFrom.setValue(this.filterArray['createdAtFrom']);
      this.createdAtTo.setValue(this.filterArray['createdAtTo']);
      this.workshopName.setValue(this.filterArray['workshopName']);
      this.deviceName.setValue(this.filterArray['deviceName']);
      this.vendorCity.setValue(this.filterArray['vendorCity']);
      this.repairCode.setValue(this.filterArray['repairCode']);
      this.serviceTime.setValue(this.filterArray['serviceTime']);
      this.deductibleCostsExists.setValue(
        this.filterArray['deductibleCostsExists']
      );
      this.preauth.setValue(this.filterArray['preauth']);
      this.state.setValue(this.filterArray['state']);
    }

    this.ordersService.getStatesCount().subscribe((res: any) => {
      this.statesCount = res['hydra:member'];
      this.statesCountGrouped['Wszystkie'] = this.statesCountGrouped[
        'Wszystkie'
      ] = this.statesCount.reduce(
        (acc: any, cur: any) => acc + cur.ordersCount,
        0
      );
      const requestedState = this.statesCount.find(
        (state: any) => state.preauthState === 'requested'
      );
      if (requestedState) {
        this.preAuthCount = requestedState.ordersCount;
        this.statesCountGrouped['Wszystkie'] -= requestedState.ordersCount;
      }
      this.statusGroup.forEach((element1: any) => {
        this.statesCount.forEach((element2: any) => {
          element1.statuses.forEach((element3: any) => {
            if (element3 == element2.state) {
              this.statesCountGrouped[element1.name] =
                (this.statesCountGrouped[element1.name] || 0) +
                element2.ordersCount;
            }
          });
        });
      });
    });

    this.sendFilters();
  }

  sortData(sort: Sort) {
    this.sort = sort;
    this.sendFilters();
  }
  goToDetails(id: string, event: any): void {
    if (event.ctrlKey) {
      window.open('/orders/' + id, '_blank');
    } else {
      this.router.navigate(['/orders/' + id]);
    }
  }

  isDateMoreThan10Days(date: Date): boolean {
    const currentDate = new Date(date);
    const futureDate = new Date();

    return currentDate < futureDate;
  }

  filter(filterArray: []) {
    this.filteredStates = filterArray;

    this.paginator.pageIndex = 0;
    this.offset = 0;
    this.sendFilters();
  }

  sendFilters() {
    this.statusGroup.some((status: any) => {
      if (status.name == this.tab) {
        this.filteredStates = status.statuses;
      }
    });
    localStorage.setItem('orderFilters', JSON.stringify(this.filteredValues));

    let params = new HttpParams();

    this.filteredStates.forEach(element => {
      params = params.append('state[]', element);
    });
    params = params.append(
      'sort[' + this.sort.active + ']',
      this.sort.direction
    );

    params = params.append('limit', this.itemsPerPage);
    params = params.append('offset', this.offset);

    if (
      this.filteredValues['faultNumber'] != null &&
      this.filteredValues['faultNumber'] != ''
    ) {
      params = params.append('faultNumber', this.filteredValues['faultNumber']);
    }
    if (
      this.filteredValues['workshopName'] != null &&
      this.filteredValues['workshopName'] != ''
    ) {
      params = params.append(
        'workshopName',
        this.filteredValues['workshopName']
      );
    }
    if (
      this.filteredValues['deductibleCostsExists'] != null &&
      this.filteredValues['deductibleCostsExists'] != ''
    ) {
      params = params.append(
        'deductibleCostsExist',
        this.filteredValues['deductibleCostsExists']
      );
    }
    if (
      this.filteredValues['createdAtFrom'] != null &&
      this.filteredValues['createdAtFrom'] != 0
    ) {
      const createdAtFrom = new Date(this.filteredValues['createdAtFrom']);
      const formattedDate = createdAtFrom.toLocaleDateString('es-CL', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      });
      params = params.append('createdAtFrom', formattedDate);
    }
    if (
      this.filteredValues['createdAtTo'] != null &&
      this.filteredValues['createdAtTo'] != 0
    ) {
      const createdAtTo = new Date(this.filteredValues['createdAtTo']);
      const formattedDate = createdAtTo.toLocaleDateString('es-CL', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      });
      params = params.append('createdAtTo', formattedDate);
    }
    if (
      this.filteredValues['deviceName'] != null &&
      this.filteredValues['deviceName'] != ''
    ) {
      params = params.append('deviceName', this.filteredValues['deviceName']);
    }
    if (
      this.filteredValues['vendorCity'] != null &&
      this.filteredValues['vendorCity'] != ''
    ) {
      params = params.append('vendorCity', this.filteredValues['vendorCity']);
    }
    if (
      this.filteredValues['repairCode'] != null &&
      this.filteredValues['repairCode'] != ''
    ) {
      params = params.append('repairCode', this.filteredValues['repairCode']);
    }
    if (
      this.filteredValues['serviceTime'] != null &&
      this.filteredValues['serviceTime'] != ''
    ) {
      params = params.append('serviceTime', this.filteredValues['serviceTime']);
    }
    if (
      this.filteredValues['state'] != null &&
      this.filteredValues['state'] != ''
    ) {
      this.Array = this.filteredValues['state'];
      this.Array.forEach((element: string | number | boolean) => {
        params = params.append('state[]', element);
      });
    }
    if (
      this.filteredValues['preauth'] != null &&
      this.filteredValues['preauth'] != ''
    ) {
      this.Array = this.filteredValues['preauth'];
      this.Array.forEach((element: string | number | boolean) => {
        params = params.append('preauth[]', element);
      });
    }
    this.ordersService.getOrdersFiltered(params).subscribe((res: any) => {
      this.dataSource = new MatTableDataSource(res.items['hydra:member']);
      setTimeout(() => {
        this.paginator.length = res.totalItems;
        this.translateAlertsMessage();
      });
    });
  }

  clearFilters() {
    this.faultNumber.setValue('');
    this.createdAtFrom.setValue('');
    this.createdAtTo.setValue('');
    this.workshopName.setValue('');
    this.deviceName.setValue('');
    this.vendorCity.setValue('');
    this.repairCode.setValue('');
    this.deductibleCostsExists.setValue('');
    this.serviceTime.setValue('');
    this.preauth.setValue('');
    this.state.setValue('');
    localStorage.removeItem('orderFilters');
    localStorage.removeItem('orderGroup');
    this.tab = 'Wszystkie';

    this.sendFilters();
  }

  onPaginateChange(event: PageEvent) {
    this.offset = event.pageIndex * event.pageSize;
    this.pageIndex = event.pageIndex;

    this.itemsPerPage = event.pageSize;
    this.sendFilters();
  }

  getStatuses() {
    this.ordersService.getStates().subscribe((res: any) => {
      this.stateList = res['hydra:member'];
    });
  }

  getWorkshops() {
    this.servicesService.getServices().subscribe((res: any) => {
      this.workshops = res['hydra:member'];
    });
  }

  changeGroup(item: any) {
    if (this.tab == 'Do akceptacji' && item.name != 'Do akceptacji') {
      this.preauth.setValue([]);
    }
    if (item.name == 'Do akceptacji') {
      this.changePreAuth();
    }
    this.tab = item.name;
    this.filter(item.statuses);

    localStorage.setItem('orderGroup', item.name);
  }

  changePreAuth() {
    this.preauth.setValue(['requested']);
    this.tab = 'requested';
    this.filter([]);
    localStorage.setItem('orderGroup', 'requested');
  }

  translateAlertsMessage() {
    this.dataSource.data.forEach((element: any) => {
      if (element.alerts?.state?.message != null) {
        const messages = element.alerts.state.message.split('\n');
        if (messages.length > 1) {
          element.alerts.state.message = '';
          messages.forEach((elementToTranslate: string | string[]) => {
            const elementTranslated =
              this.translateService.instant(elementToTranslate);
            element.alerts.state.message += '  ' + elementTranslated;
          });
        } else {
          element.alerts.state.message = this.translateService.instant(
            element.alerts.state.message
          );
        }
      }
    });
  }

  openInNewTabOrder(row: any) {
    window.open('/orders/' + row.value.id, '_blank');
  }

  openInNewTabCost(row: any) {
    window.open('/costs/' + row.value.id, '_blank');
  }

  openInNewTabSettlement(row: any) {
    window.open('/settlements/' + row.value.id, '_blank');
  }

  public copy(row: any, itemName: string) {
    if (itemName == 'faultNumber') {
      this.clipboard.copy(row.value.id);
    } else if (itemName == 'workshopName') {
      this.clipboard.copy(row.value.workshopName);
    } else if (itemName == 'deviceName') {
      this.clipboard.copy(row.value.deviceName);
    }
    this._snackBar.open('Skopiowano do schowka', 'Zamknij', {
      duration: 3000,
    });
  }

  openLogsOfOrder(row: any) {
    console.log(row);
    window.open('/logs/' + row.value.faultNumber, '_blank');
  }
}
