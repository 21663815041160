import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthGuard } from 'dashboard-libs/guards/access-allowed.guard';

@Component({
  selector: 'app-finish-order-modal',
  templateUrl: './finish-order-modal.component.html',
  styleUrls: ['./finish-order-modal.component.scss'],
})
export class FinishOrderModalComponent {
  code: any;
  action: any;
  repairDescription: any;
  constructor(
    private dialogRef: MatDialogRef<FinishOrderModalComponent>,
    public Auth: AuthGuard
  ) {}
  onCancel() {
    this.dialogRef.close();
  }

  onSubmit() {
    this.dialogRef.close({
      code: this.code,
      action: this.action,
      repairDescription: this.repairDescription,
    });
  }
}
