import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { OrdersService } from 'src/app/pages/orders/services/orders.service';
import { EditMarginComponent } from 'src/app/shared/modals/edit-margin/edit-margin.component';
import { ShowPreAuthComponent } from 'src/app/shared/modals/show-preauth/show-preauth.component';

@Component({
  selector: 'app-settlement-info',
  templateUrl: './settlement-info.component.html',
  styleUrls: ['./settlement-info.component.scss'],
})
export class SettlementInfoComponent implements OnInit {
  @Input() order: any;
  public marginId!: string;
  public margin: any;

  constructor(
    private dialog: MatDialog,
    private orderService: OrdersService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    setTimeout(() => {
      if (this.order.workshop) {
        this.order.workshopId = this.order.workshop.split('/')[5];
        this.marginId = this.marginId = this.order.margin.split('/')[5];
        this.orderService.getOrderMargin(this.marginId).subscribe(res => {
          this.margin = res;
        });
      }
    }, 1500);
  }

  openMargin(): void {
    const dialogRef = this.dialog.open(EditMarginComponent, {
      data: { margin: this.margin },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      this.orderService.getOrderMargin(this.marginId).subscribe(res => {
        this.margin = res;
      });
      if (result === 'show') {
      }
    });
  }

  openPreAuth(): void {
    const dialogRef = this.dialog.open(ShowPreAuthComponent, {
      data: { preAuth: this.order.preauthorization },
      disableClose: true,
    });
  }
}
