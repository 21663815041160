<h1 mat-dialog-title class="title">
  {{ 'orders-front.adding_a_new_contact' | translate }}
</h1>
<p class="title-desc">
  {{ 'orders-front.fill_in_the_information_below' | translate }}
</p>
<hr class="custom-hr" />
<div mat-dialog-content>
  <div>
    <form [formGroup]="formContactInfo">
      <div class="flex">
        <div class="did-floating-label-content mr-10 mt-15">
          <input
            class="did-floating-input"
            formControlName="name"
            required
            type="text"
            placeholder=" " />
          <label class="did-floating-label">{{
            'services-front.name' | translate
          }}</label>
          <mat-error
            *ngIf="submitted && formContactInfo.controls['name'].errors?.['required']"
            >{{ 'services-front.field_is_required' | translate }}</mat-error
          >
          <mat-error
            *ngIf="submitted && formContactInfo.controls['name'].errors?.['pattern']"
            >To pole wymaga podania prawidłowego imienia</mat-error
          >
        </div>
        <div class="did-floating-label-content mt-15">
          <input
            class="did-floating-input"
            formControlName="surname"
            required
            type="text"
            placeholder=" " />
          <label class="did-floating-label">{{
            'services-front.surname' | translate
          }}</label>
          <mat-error
            *ngIf="submitted && formContactInfo.controls['surname'].errors?.['required']"
            >{{ 'services-front.field_is_required' | translate }}</mat-error
          >
          <mat-error
            *ngIf="submitted && formContactInfo.controls['surname'].errors?.['pattern']"
            >To pole wymaga podania prawidłowego nazwiska</mat-error
          >
        </div>
      </div>
      <div class="flex">
        <div class="did-floating-label-content mr-10 mt-15">
          <ngx-material-intl-tel-input
            class="phone-number"
            [fieldControl]="phone1"
            [preferredCountries]="['pl', 'de', 'us', 'gb']"
            [autoIpLookup]="true"
            [required]="true"
            [iconMakeCall]="false"
            [textLabels]="{
              mainLabel: 'Główny numer telefonu',
              codePlaceholder: 'Kod',
              searchPlaceholderLabel: 'Szukaj',
              noEntriesFoundLabel: 'Nie znaleziono krajów',
              nationalNumberLabel: 'Numer telefonu',
              hintLabel: '',
              invalidNumberError: 'Numer jest niepoprawny',
              requiredError: 'To pole jest wymagane'
            }">
          </ngx-material-intl-tel-input>
          <mat-error
            *ngIf="submitted && formContactInfo.controls['phoneNumber'].errors?.['required']"
            >{{ 'services-front.field_is_required' | translate }}</mat-error
          >
          <!-- 
          <mat-error
            *ngIf="submitted && formContactInfo.controls['phoneNumber'].errors?.['required']"
            >{{ 'services-front.field_is_required' | translate }}</mat-error
          >
          <mat-error
            *ngIf="submitted && formContactInfo.controls['phoneNumber'].errors?.['pattern']"
            >To pole wymaga podania prawidłowego nr telefonu</mat-error
          > -->
        </div>
        <div class="did-floating-label-content mt-15">
          <ngx-material-intl-tel-input
            class="phone-number"
            [fieldControl]="phone2"
            [autoIpLookup]="true"
            [iconMakeCall]="false"
            [numberValidation]="true"
            [preferredCountries]="['pl', 'de', 'us', 'gb']"
            [textLabels]="{
              mainLabel:
                'Dodatkowy 
             numer telefonu ',
              codePlaceholder: 'Kod',
              searchPlaceholderLabel: 'Szukaj',
              noEntriesFoundLabel: 'Nie znaleziono krajów',
              nationalNumberLabel: 'Numer telefonu',
              hintLabel: '',
              invalidNumberError: 'Numer jest niepoprawny',
              requiredError: 'To pole jest wymagane'
            }">
          </ngx-material-intl-tel-input>
        </div>
      </div>
      <div class="flex">
        <div class="did-floating-label-content mr-10 mt-15">
          <input
            class="did-floating-input"
            formControlName="email"
            required
            type="text"
            placeholder=" " />
          <label class="did-floating-label">{{
            'services-front.email_address' | translate
          }}</label>
          <mat-error
            *ngIf="submitted && formContactInfo.controls['email'].errors?.['required']"
            >{{ 'services-front.field_is_required' | translate }}</mat-error
          >
          <mat-error
            *ngIf="submitted && formContactInfo.controls['email'].errors?.['pattern']"
            >To pole wymaga podania prawidłowego adresu email</mat-error
          >
        </div>
        <div class="did-floating-label-content mt-15">
          <select class="did-floating-select" formControlName="type">
            <option value="Main">Główny</option>
            <option value="Additional">Dodatkowy</option>
          </select>
          <label class="did-floating-label">{{
            'services-front.contact_type' | translate
          }}</label>
          <mat-error
            *ngIf="submitted && formContactInfo.controls['type'].errors?.['required']"
            >{{ 'services-front.field_is_required' | translate }}</mat-error
          >
        </div>
      </div>
    </form>
  </div>

  <div class="text-right buttons" mat-dialog-actions>
    <button
      mat-button
      class="secondary-btn"
      color="accent"
      [mat-dialog-close]="'cancel'">
      {{ 'orders-front.cancel' | translate }}
    </button>
    <button mat-button class="primary-btn" color="primary" (click)="add()">
      {{ 'orders-front.save' | translate }}
    </button>
  </div>
</div>
