<h1 mat-dialog-title class="title">
  {{ "margin.margin_edition" | translate }}
</h1>
<p class="title-desc p-content">
  {{ "margin.select_from_the_list_of_available_margins" | translate }}
</p>
<hr class="custom-hr" />
<div mat-dialog-content>
  <div class="did-floating-label-content full-width">
    <select class="did-floating-select">
      <option value=""></option>
      <option *ngFor="let type of types" [value]="type">
        {{ type }}
      </option>
    </select>
    <label class="did-floating-label">{{
      "margin.margin_selection" | translate
    }}</label>
  </div>
  <hr class="custom-hr" />
  <div class="content-item">
    <p>{{ "margin.overall_margin" | translate }}</p>
    <p>2%</p>
  </div>
  <hr class="custom-hr" />
  <div class="content-item">
    <p>{{ "margin.parts" | translate }}</p>
    <p>2%</p>
  </div>
  <hr class="custom-hr" />
  <div class="content-item">
    <p>{{ "margin.labor" | translate }}</p>
    <p>2%</p>
  </div>
  <hr class="custom-hr" />
  <div class="content-item">
    <p>{{ "margin.expertise" | translate }}</p>
    <p>2%</p>
  </div>
  <hr class="custom-hr" />
  <div class="content-item">
    <p>{{ "margin.expertise" | translate }}</p>
    <p>2%</p>
  </div>
</div>
<div class="text-right buttons" mat-dialog-actions>
  <button mat-button class="primary-btn" color="primary" (click)="add()">
    {{ "margin.save" | translate }}
  </button>
  <button
    mat-button
    class="secondary-btn"
    color="accent"
    [mat-dialog-close]="'cancel'"
  >
    {{ "margin.cancel" | translate }}
  </button>
</div>
