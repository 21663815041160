import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthGuard } from 'dashboard-libs/guards/access-allowed.guard';

@Component({
  selector: 'app-assign-order-modal',
  templateUrl: './assign-order-modal.component.html',
  styleUrls: ['./assign-order-modal.component.css'],
})
export class AssignOrderModalComponent {
  apartmentNumber: any;
  buildingNumber: any;
  streetName: any;
  postalCode: any;
  city: any;
  name: any;
  constructor(
    private dialogRef: MatDialogRef<AssignOrderModalComponent>,
    public Auth: AuthGuard
  ) {}
  onCancel() {
    this.dialogRef.close();
  }

  onSubmit() {
    this.dialogRef.close({
      address: {
        apartmentNumber: this.apartmentNumber || null,
        buildingNumber: this.buildingNumber,
        streetName: this.streetName,
        postalCode: this.postalCode,
        city: this.city,
      },
      name: this.name,
    });
  }
}
