<mat-card *ngIf="orders && orders.length"
  ><h5>Nowe zlecenia</h5>
  <mat-divider></mat-divider>
</mat-card>
<mat-card
  class="card row_2 border"
  *ngFor="let activity of orders"
  [routerLink]="'/orders/' + activity.id">
  <div class="right-s">
    <div>
      <mat-icon class="black-icon icon">home</mat-icon>
      <div class="left">
        <b>Wagas | {{ activity?.faultNumber }}</b>
      </div>
      <div class="gray right">
        {{ activity?.updatedAt | timeAgo | translate}}
      </div>
    </div>
  </div>
  <mat-divider></mat-divider>
</mat-card>
<mat-card *ngIf="orders && !orders.length">
  <h5>Brak nowych zleceń</h5>
</mat-card>
