import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoaderService } from '../services/spinner.service';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  private totalRequests = 0;

  constructor(private loadingService: LoaderService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (request.url.includes('/userinfo')) {
      return next.handle(request);
    }
    if (request.url.includes('/unread-comments')) {
      return next.handle(request);
    }
    if (request.url.includes('/counters')) {
      return next.handle(request);
    }
    if (request.url.includes('/preauthorization')) {
      return next.handle(request);
    }
    if (request.url.includes('/order_margins')) {
      return next.handle(request);
    }

    this.totalRequests++;
    this.loadingService.setLoading(true);
    return next.handle(request).pipe(
      finalize(() => {
        this.totalRequests--;
        if (this.totalRequests == 0) {
          this.loadingService.setLoading(false);
        }
      })
    );
  }
}
