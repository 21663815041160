import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { OrdersService } from 'src/app/pages/orders/services/orders.service';

@Component({
  selector: 'app-preAuth',
  templateUrl: './preAuth.html',
  styleUrls: ['./preAuth.scss'],
})
export class PreAuthComponent {
  @Input() someInput: string | undefined;
  preAuth!: any;
  loading: boolean = true;
  constructor(private orderService: OrdersService, private router: Router) {
    this.orderService.getNewPreAuth().subscribe(res => {
      this.loading = false;
      this.preAuth = res['hydra:member'];
    });
  }

  redirectTo(uri: string) {
    this.router
      .navigateByUrl('/', {
        skipLocationChange: true,
      })
      .then(() => {
        this.router.navigate([uri], { state: { openPreAuth: true } });
      });
  }
}
