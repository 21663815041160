<ol id="breadcrumbNav" class="breadcrumb-nav">
  <li *ngFor="let breadcrumb of breadcrumbs$ | async; let i = index">
    <a
      *ngIf="i != (breadcrumbs$ | async)?.length - 1"
      [href]="breadcrumb.url"
      >{{ breadcrumb.label | translate }}</a
    >
    <span
      class="breadcrumb-last-li"
      *ngIf="i == (breadcrumbs$ | async)?.length - 1"
      >{{ name || breadcrumb.label | translate }}</span
    >
  </li>
</ol>
