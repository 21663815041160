import { Component, OnInit } from '@angular/core';
import { OrdersService } from '../../orders/services/orders.service';
import { ActivatedRoute } from '@angular/router';
import { AuthGuard } from 'src/app/shared/guards/access-allowed.guard';

@Component({
  selector: 'app-archived-order-item',
  templateUrl: './archived-order-item.component.html',
  styleUrls: ['./archived-order-item.component.scss'],
})
export class ArchivedOrderItemComponent implements OnInit {
  public order: any;
  public history: any;
  public showData!: boolean;
  public tab: string = 'details';

  constructor(
    private ordersService: OrdersService,
    private route: ActivatedRoute,
    public Auth: AuthGuard
  ) {}

  ngOnInit(): void {
    this.ordersService
      .getOrderById(this.route.snapshot.params['id'])
      .subscribe(res => {
        this.order = res;
      });

    this.ordersService
      .getOrderHistory(this.route.snapshot.params['id'])
      .subscribe((res: any) => {
        this.history = res['hydra:member'];
      });
  }

  refresh() {
    this.ngOnInit();
  }
}
