import { Component } from '@angular/core';
import { OrdersService } from '../orders/services/orders.service';

@Component({
  selector: 'my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss'],
})
export class MyProfileComponent {
  data: any;
  constructor(private orderService: OrdersService) {}
  ngOnInit(): void {
    this.orderService.getLoggedUser().subscribe(data => {
      this.data = data;
      if (this.data.roles.includes('ROLE_ADMIN')) {
        this.data.admin = true;
      }
      if (this.data.roles.includes('ROLE_PARTNER_API')) {
        this.data.api_partner = true;
      }
      this.orderService.getWorkshop(this.data.workshop).subscribe(workshop => {
        this.data.workshop = workshop;
      });
    });
  }
}
