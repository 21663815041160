import { Component } from '@angular/core';
import { UserService } from '../../services/user.service';
import { UserIdleService } from 'angular-user-idle';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-session-ending',
  templateUrl: './session-ending.component.html',
  styleUrls: ['./session-ending.component.scss'],
})
export class SessionEndingComponent {
  constructor(
    private userService: UserService,
    private userIdle: UserIdleService
  ) {}

  resetSession(): void {
    this.userService.getUserInfo().subscribe(res => {});
    this.stop();
    this.restart();
  }

  stop() {
    this.userIdle.stopTimer();
  }

  restart() {
    this.userIdle.resetTimer();
  }
}
