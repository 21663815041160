<h1 mat-dialog-title class="title">Obsługiwani producenci</h1>
<p class="title-desc p-content">Zaznacz obsługiwanych producentów</p>
<hr class="custom-hr" />
<div mat-dialog-content>
  <div class="types-container">
    <div class="type" *ngFor="let supportedBrand of supportedBrands">
      <section class="example-section">
        <mat-checkbox
          #checkboxes
          (click)="$event.stopPropagation()"
          (change)="$event ? toggle(supportedBrand, $event) : null"
          [checked]="exists(supportedBrand)"
          class="example-margin"
          >{{ supportedBrand.name }}</mat-checkbox
        >
      </section>
    </div>
  </div>
  <mat-checkbox
    class="slide"
    (change)="$event ? toggleAll($event) : null"
    [indeterminate]="isIndeterminate()"
    [checked]="isChecked()"
    >Zaznacz wszystko</mat-checkbox
  >
</div>
<div class="text-right buttons" mat-dialog-actions>
  <button
    mat-button
    class="secondary-btn"
    color="accent"
    [mat-dialog-close]="'cancel'">
    Anuluj
  </button>
  <button mat-button class="primary-btn" color="primary" (click)="add()">
    Zapisz
  </button>
</div>
