<h1 mat-dialog-title class="title">
  {{ "margin.margin_preview" | translate }}
</h1>
<hr class="custom-hr" />
<div mat-dialog-content>
  <div class="flex margin-item">
    <div>
      <p>
        <strong>{{ "margin.current_margin" | translate }}</strong>
      </p>
      <p>{{ "margin.average" | translate }}</p>
    </div>
    <button class="btn-check">{{ "margin.check" | translate }}</button>
  </div>
  <hr class="custom-hr" />
  <div class="flex margin-item">
    <div>
      <p>
        <strong>{{ "margin.scheduled_margin" | translate }}</strong>
      </p>
      <p>{{ "margin.average" | translate }}</p>
    </div>
    <button class="btn-check">{{ "margin.check" | translate }}</button>
  </div>
  <hr class="custom-hr" />
  <div class="flex margin-item">
    <div>
      <p>
        <strong>{{ "margin.archived_margins" | translate }}</strong>
      </p>
      <p>{{ "margin.average" | translate }}</p>
    </div>
    <button class="btn-check">{{ "margin.check" | translate }}</button>
  </div>
</div>
<div mat-dialog-actions>
  <button
    mat-button
    class="primary-btn"
    color="primary"
    [mat-dialog-close]="'show'"
  >
    {{ "margin.close" | translate }}
  </button>
</div>
