<h1 mat-dialog-title class="title">
  {{ 'orders-front.add_comment' | translate }}
</h1>
<hr class="custom-hr" />
<div mat-dialog-content>
  <p class="p-content">
    {{ 'orders-front.fill_in_the_information_below' | translate }}
  </p>
  <div class="did-floating-label-content w-100 mt-15">
    <textarea
      [(ngModel)]="comment"
      class="did-floating-input"
      type="text"
      placeholder=" ">
    </textarea>
    <label class="did-floating-label">{{
      'orders-front.comment' | translate
    }}</label>
  </div>
  <p
    *ngIf="Auth.isViewAllowed(['ROLE_ORDER_DETAILS_EDIT', 'ROLE_ADMIN'])"
    class="p-content">
    {{ 'orders-front.visibility' | translate }}
  </p>
  <section
    class="example-section"
    *ngIf="Auth.isViewAllowed(['ROLE_ORDER_DETAILS_EDIT', 'ROLE_ADMIN'])">
    <mat-checkbox class="example-margin" [(ngModel)]="toPartner">{{
      'orders-front.toPartner' | translate
    }}</mat-checkbox>
    <mat-checkbox class="example-margin" [(ngModel)]="toOrderSource">{{
      'orders-front.toOrderSource' | translate
    }}</mat-checkbox>
  </section>
</div>
<div mat-dialog-actions>
  <button
    mat-button
    class="secondary-btn"
    color="accent"
    [mat-dialog-close]="'cancel'">
    {{ 'orders-front.cancel' | translate }}
  </button>
  <button mat-button class="primary-btn" (click)="onSubmit()" color="primary">
    {{ 'orders-front.add' | translate }}
  </button>
</div>
