<h1 mat-dialog-title class="title">
  {{ 'orders-front.assign_order' | translate }}
</h1>

<div mat-dialog-content>
  <p class="p-content">
    {{ 'orders-front.fill_in_the_information_below' | translate }}
  </p>
  <div class="did-floating-label-content w-100 mt-15">
    <input
      [(ngModel)]="name"
      class="did-floating-input"
      type="text"
      placeholder=" "
      required />
    <label class="did-floating-label">{{
      'orders-front.nameOfCompany' | translate
    }}</label>
  </div>
  <div class="did-floating-label-content w-100 mt-15">
    <input
      [(ngModel)]="streetName"
      class="did-floating-input"
      type="text"
      placeholder=" "
      required />
    <label class="did-floating-label">{{
      'orders-front.streetName' | translate
    }}</label>
  </div>
  <div class="did-floating-label-content w-100 mt-15">
    <input
      [(ngModel)]="buildingNumber"
      class="did-floating-input"
      type="text"
      placeholder=" "
      required />
    <label class="did-floating-label">{{
      'orders-front.buildingNumber' | translate
    }}</label>
  </div>
  <div class="did-floating-label-content w-100 mt-15">
    <input
      [(ngModel)]="apartmentNumber"
      class="did-floating-input"
      type="text"
      placeholder=" "
      required />
    <label class="did-floating-label">{{
      'orders-front.apartmentNumber' | translate
    }}</label>
  </div>
  <div class="did-floating-label-content w-100 mt-15">
    <input
      [(ngModel)]="postalCode"
      class="did-floating-input"
      type="text"
      placeholder=" "
      required />
    <label class="did-floating-label">{{
      'orders-front.postalCode' | translate
    }}</label>
  </div>
  <div class="did-floating-label-content w-100 mt-15">
    <input
      [(ngModel)]="city"
      class="did-floating-input"
      type="text"
      placeholder=" "
      required />
    <label class="did-floating-label">{{
      'orders-front.city' | translate
    }}</label>
  </div>
</div>
<div mat-dialog-actions>
  <button
    mat-button
    class="secondary-btn"
    color="accent"
    [mat-dialog-close]="'cancel'">
    {{ 'orders-front.cancel' | translate }}
  </button>
  <button mat-button class="primary-btn" (click)="onSubmit()" color="primary">
    {{ 'orders-front.assign' | translate }}
  </button>
</div>
