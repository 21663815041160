import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { OrdersService } from '../../../pages/orders/services/orders.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-add-attachment',
  templateUrl: './add-attachment.component.html',
  styleUrls: ['./add-attachment.component.scss'],
})
export class AddAttachmentComponent implements OnInit {
  toOrderSource: boolean = true;
  toPartner: boolean = true;

  file_store: any;
  display: FormControl = new FormControl('', Validators.required);
  name: FormControl = new FormControl('');
  type: FormControl = new FormControl('');
  description: FormControl = new FormControl('');
  access: FormControl = new FormControl('');
  orderId: any;
  isLoading!: boolean;
  types: any;
  maxSize: any = 0;
  fileSizeError: boolean = false;
  missing_file: boolean = false;
  missing_type: boolean = false;

  constructor(
    private ordersService: OrdersService,
    public dialogRef: MatDialogRef<AddAttachmentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.orderId = data.id;
  }

  ngOnInit(): void {
    this.ordersService.getAttachmentTypes().subscribe((res: any) => {
      this.types = res['hydra:member'];
    });
    this.getMaxSize();
  }

  handleFileInputChange(l: any): void {
    this.file_store = l[0];
    if (l.length) {
      const f = l[0];
      const count = l.length > 1 ? `(+${l.length - 1} files)` : '';
      this.display.patchValue(`${f.name}${count}`);
    } else {
      this.display.patchValue('');
    }
    if (!l) return;
    const maxSize = this.maxSize;
    Array.from(l).forEach((file: any) => {
      if (file.size > maxSize) {
        this.fileSizeError = true;
      } else {
        this.fileSizeError = false;
      }
    });
    if (!this.file_store) {
      this.missing_file = true;
      return;
    } else {
      this.missing_file = false;
    }
    if (!this.type.value) {
      this.missing_type = true;
      return;
    } else {
      this.missing_type = false;
    }
  }

  add(): void {
    if (this.hasFileSizeError()) {
      return;
    }
    if (!this.file_store) {
      this.missing_file = true;
      return;
    } else {
      this.missing_file = false;
    }
    if (!this.type.value) {
      this.missing_type = true;
      return;
    } else {
      this.missing_type = false;
    }
    this.isLoading = true;
    let data = {
      toPartner: this.toPartner,
      toOrderSource: this.toOrderSource,
      type: this.type.value,
      file: this.file_store,
    };

    this.ordersService.postAttachment(data, this.orderId).subscribe(
      res => {
        this.dialogRef.close();
        this.isLoading = false;
      },
      error => {
        this.isLoading = false;
      }
    );
  }

  private hasFileSizeError(): boolean {
    return this.fileSizeError;
  }

  getMaxSize() {
    this.ordersService.getMaxSize().subscribe((res: any) => {
      this.maxSize = res?.maxUploadSize ?? 0; // Fallback to 0 if undefined
    });
  }
}
