import { OrdersService } from './../../../pages/orders/services/orders.service';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-add-note',
  templateUrl: './add-note.component.html',
  styleUrls: ['./add-note.component.scss'],
})
export class AddNoteComponent {
  note: any;
  toOrderSource: any;
  toPartner: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<AddNoteComponent>
  ) {
    this.note = data.note;
  }
  onCancel() {
    this.dialogRef.close();
  }

  onSubmit() {
    this.dialogRef.close({
      note: this.note,
    });
  }
}
