import { Component } from '@angular/core';

@Component({
  selector: 'app-supported-area',
  templateUrl: './supported-area.component.html',
  styleUrls: ['./supported-area.component.scss']
})
export class SupportedAreaComponent {

  public isPostalCode!: boolean;

  constructor() {
  }

  add() {

  }

  changeCheckbox(bool: boolean): void {
    this.isPostalCode = bool;
  }

}
