<h1 mat-dialog-title class="title">
  {{ 'users-front.adding_a_new_user' | translate }}
</h1>
<hr class="custom-hr" />
<div mat-dialog-content>
  <mat-card class="card-error" *ngIf="showError">
    <mat-card-content>{{
      'users-front.a_user_with_the_specified_email_address_already_exists'
        | translate
    }}</mat-card-content>
  </mat-card>
  <mat-card class="card-error" *ngIf="showGeneralError">
    <mat-card-content>Nie udało się zedytować użytkownika</mat-card-content>
  </mat-card>
  <form [formGroup]="form">
    <div class="flex">
      <div class="did-floating-label-content full-width">
        <input
          class="did-floating-input"
          formControlName="email"
          type="text"
          placeholder=" " />
        <label class="did-floating-label">{{
          'users-front.email_address' | translate
        }}</label>
        <mat-error
          *ngIf="submitted && form.controls['email'].errors?.['required']"
          >{{ 'users-front.field_is_required' | translate }}</mat-error
        >
        <mat-error
          *ngIf="submitted && form.controls['email'].errors?.['email']"
          >{{ 'users-front.email_is_incorrect' | translate }}</mat-error
        >
      </div>
      <div class="did-floating-label-content mr-10 mt-15">
        <mat-checkbox formControlName="enabled">Aktywny</mat-checkbox>
      </div>
    </div>

    <div class="flex">
      <div class="did-floating-label-content mr-10 mt-15">
        <input
          class="did-floating-input"
          formControlName="firstName"
          type="text"
          placeholder=" " />
        <label class="did-floating-label">Imię</label>
        <mat-error
          *ngIf="submitted && form.controls['firstName'].errors?.['required']"
          >{{ 'users-front.field_is_required' | translate }}</mat-error
        >
        <mat-error
          *ngIf="submitted && form.controls['firstName'].errors?.['minlength']"
          >{{
            'users-front.a_minimum_of_2_characters_is_required' | translate
          }}</mat-error
        >
      </div>
      <div class="did-floating-label-content mt-15">
        <input
          class="did-floating-input"
          formControlName="lastName"
          type="text"
          placeholder=" " />
        <label class="did-floating-label">{{
          'users-front.surname' | translate
        }}</label>
        <mat-error
          *ngIf="submitted && form.controls['lastName'].errors?.['required']"
          >{{ 'users-front.field_is_required' | translate }}</mat-error
        >
        <mat-error
          *ngIf="submitted && form.controls['lastName'].errors?.['minlength']"
          >{{
            'users-front.a_minimum_of_2_characters_is_required' | translate
          }}</mat-error
        >
      </div>
    </div>
    <div class="flex">
      <div class="did-floating-label-content mr-10 mt-15">
        <select class="did-floating-select" formControlName="workshop">
          <option value="{{ service.id }}" *ngFor="let service of services">
            {{ service.name }}
          </option>
        </select>
        <label class="did-floating-label">{{
          'users-front.service' | translate
        }}</label>
        <mat-error
          *ngIf="submitted && form.controls['workshop'].errors?.['required']"
          >{{ 'services-front.field_is_required' | translate }}</mat-error
        >
      </div>
      <div class="did-floating-label-content mt-15">
        <select class="did-floating-select" formControlName="groups">
          <option
            value="{{ userGroup.id }}"
            *ngFor="let userGroup of userGroups">
            {{ userGroup.name | translate }}
          </option>
        </select>
        <label class="did-floating-label">{{
          'users-front.user_group' | translate
        }}</label>
        <mat-error
          *ngIf="submitted && form.controls['groups'].errors?.['required']"
          >{{ 'services-front.field_is_required' | translate }}</mat-error
        >
      </div>
    </div>
  </form>
</div>
<div mat-dialog-actions>
  <button
    mat-button
    class="secondary-btn"
    color="accent"
    [mat-dialog-close]="'cancel'">
    {{ 'users-front.cancel' | translate }}
  </button>
  <button
    mat-button
    class="primary-btn"
    [disabled]="isLoading"
    color="primary"
    (click)="add()">
    {{ 'users-front.save' | translate }}
  </button>
</div>
