import { Component, Input } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import {
  animate,
  query,
  sequence,
  stagger,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { environment } from 'src/app/environments/environment';

export const DropDownAnimation = trigger('dropDownMenu', [
  transition(':enter', [
    style({ height: 0, overflow: 'hidden' }),
    query('.menu-item', [
      style({ opacity: 0, transform: 'translateY(-50px)' }),
    ]),
    sequence([
      animate('200ms', style({ height: '*' })),
      query('.menu-item', [
        stagger(-50, [
          animate('400ms ease', style({ opacity: 1, transform: 'none' })),
        ]),
      ]),
    ]),
  ]),

  transition(':leave', [
    style({ height: '*', overflow: 'hidden' }),
    query('.menu-item', [style({ opacity: 1, transform: 'none' })]),
    sequence([
      query('.menu-item', [
        stagger(50, [
          animate(
            '400ms ease',
            style({ opacity: 0, transform: 'translateY(-50px)' })
          ),
        ]),
      ]),
      animate('200ms', style({ height: 0 })),
    ]),
  ]),
]);

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss'],
  animations: [DropDownAnimation],
})
export class UserInfoComponent {
  @Input() user: any;
  @Input() opened: any;
  public showMenu!: boolean;

  language: string = environment.language;

  constructor(private keycloak: KeycloakService) {}

  logout(): void {
    localStorage.clear();
    this.keycloak.logout();
  }
}
