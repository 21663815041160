<div class="sidenav" [ngClass]="{ 'sidenav-closed': opened == false }">
  <div class="name">
    <img src="../../../../assets/unnamed.png" />
  </div>
  <div class="items" [ngClass]="{ 'name-lower': opened == false }">
    <div class="item" routerLink="/orders" routerLinkActive="active">
      <div class="icon" matTooltip="{{ 'navbar.orders' | translate }}">
        <mat-icon>assignment</mat-icon>
      </div>
      <div class="text" *ngIf="opened">{{ 'navbar.orders' | translate }}</div>
    </div>

    <div
      class="item"
      (click)="showCosts()"
      *ngIf="
        Auth.isViewAllowed([
          'ROLE_BILLINGS_COSTS_VIEW',
          'ROLE_BILLINGS_REVENUE_VIEW',
          'ROLE_ADMIN',
        ])
      ">
      <div class="icon" matTooltip="{{ 'navbar.settlements' | translate }}">
        <mat-icon>dashboard</mat-icon>
      </div>
      <div class="text" *ngIf="opened">
        {{ 'navbar.settlements' | translate }}
      </div>
      <div class="arrow" *ngIf="!showItems">
        <mat-icon>keyboard_arrow_down</mat-icon>
      </div>
      <div class="arrow" *ngIf="showItems">
        <mat-icon>keyboard_arrow_up</mat-icon>
      </div>
    </div>

    <div
      class="item-items"
      *ngIf="showItems"
      [ngClass]="{ 'sidenav-items-closed': opened == false }">
      <div
        class="item"
        routerLink="/costs"
        routerLinkActive="active"
        *ngIf="Auth.isViewAllowed(['ROLE_BILLINGS_COSTS_VIEW', 'ROLE_ADMIN'])">
        <div class="icon" matTooltip="{{ 'navbar.costs' | translate }}">
          <mat-icon class="small-icon" *ngIf="opened"
            >fiber_manual_record</mat-icon
          >
          <mat-icon *ngIf="!opened">payment</mat-icon>
        </div>
        <div class="text" *ngIf="opened">{{ 'navbar.costs' | translate }}</div>
      </div>
      <div
        class="item"
        routerLink="/settlements"
        routerLinkActive="active"
        *ngIf="
          Auth.isViewAllowed(['ROLE_BILLINGS_REVENUE_VIEW', 'ROLE_ADMIN'])
        ">
        <div class="icon" matTooltip="{{ 'navbar.revenue' | translate }}">
          <mat-icon class="small-icon" *ngIf="opened"
            >fiber_manual_record</mat-icon
          >
          <mat-icon *ngIf="!opened">receipt</mat-icon>
        </div>
        <div class="text" *ngIf="opened">
          {{ 'navbar.revenue' | translate }}
        </div>
      </div>
    </div>
    <div
      class="item"
      routerLink="/workshop"
      routerLinkActive="active"
      *ngIf="Auth.isViewAllowed(['ROLE_WORKSHOP_VIEW', 'ROLE_ADMIN'])">
      <div class="icon" matTooltip="{{ 'navbar.services' | translate }}">
        <mat-icon>build</mat-icon>
      </div>
      <div class="text" *ngIf="opened">{{ 'navbar.services' | translate }}</div>
    </div>
    <div class="item" routerLink="/archived-orders" routerLinkActive="active">
      <div class="icon" matTooltip="{{ 'navbar.archived-orders' | translate }}">
        <mat-icon>account_balance</mat-icon>
      </div>
      <div class="text" *ngIf="opened">
        {{ 'navbar.archived-orders' | translate }}
      </div>
    </div>

    <div
      class="item"
      routerLink="/reports"
      routerLinkActive="active"
      *ngIf="Auth.isViewAllowed(['ROLE_REPORTS', 'ROLE_ADMIN'])">
      <div class="icon" matTooltip="{{ 'navbar.reports' | translate }}">
        <mat-icon>insert_drive_file</mat-icon>
      </div>
      <div class="text" *ngIf="opened">
        {{ 'navbar.reports' | translate }}
      </div>
    </div>

    <div
      class="item"
      (click)="showHideSettings()"
      *ngIf="
        Auth.isViewAllowed([
          'ROLE_LOGS',
          'ROLE_CRONS',
          'ROLE_SYSTEM_MARGIN_SETTINGS',
          'ROLE_SETTINGS_USERS_VIEW',
          'ROLE_ADMIN',
        ])
      ">
      <div class="icon" matTooltip="{{ 'navbar.settings' | translate }}">
        <mat-icon>settings</mat-icon>
      </div>
      <div class="text" *ngIf="opened">
        {{ 'navbar.settings' | translate }}
      </div>
      <div class="arrow" *ngIf="!showSettingItems">
        <mat-icon>keyboard_arrow_down</mat-icon>
      </div>
      <div class="arrow" *ngIf="showSettingItems">
        <mat-icon>keyboard_arrow_up</mat-icon>
      </div>
    </div>
    <div
      class="item-items"
      *ngIf="showSettingItems"
      [ngClass]="{ 'sidenav-items-closed': opened == false }">
      <div
        *ngIf="Auth.isViewAllowed(['ROLE_SETTINGS_USERS_VIEW', 'ROLE_ADMIN'])"
        class="item"
        routerLink="/users"
        routerLinkActive="active">
        <div class="icon" matTooltip="{{ 'navbar.users' | translate }}">
          <mat-icon class="small-icon" *ngIf="opened"
            >fiber_manual_record</mat-icon
          >
          <mat-icon *ngIf="!opened">assignment_ind</mat-icon>
        </div>
        <div class="text" *ngIf="opened">{{ 'navbar.users' | translate }}</div>
      </div>
      <div
        class="item"
        routerLink="/settings"
        routerLinkActive="active"
        *ngIf="
          Auth.isViewAllowed(['ROLE_SYSTEM_MARGIN_SETTINGS', 'ROLE_ADMIN'])
        ">
        <div class="icon" matTooltip="{{ 'navbar.margin_system' | translate }}">
          <mat-icon class="small-icon" *ngIf="opened"
            >fiber_manual_record</mat-icon
          >
          <mat-icon *ngIf="!opened">extension icon</mat-icon>
        </div>
        <div class="text" *ngIf="opened">
          {{ 'navbar.margin_system' | translate }}
        </div>
      </div>
      <div
        *ngIf="Auth.isViewAllowed(['ROLE_CRONS', 'ROLE_ADMIN'])"
        class="item"
        routerLink="/admin-panel"
        routerLinkActive="active">
        <div class="icon" matTooltip="{{ 'navbar.crons' | translate }}">
          <mat-icon class="small-icon" *ngIf="opened"
            >fiber_manual_record</mat-icon
          >
          <mat-icon *ngIf="!opened">brightness_auto</mat-icon>
        </div>
        <div class="text" *ngIf="opened">
          {{ 'navbar.crons' | translate }}
        </div>
      </div>
      <div
        *ngIf="Auth.isViewAllowed(['ROLE_LOGS', 'ROLE_ADMIN'])"
        class="item"
        routerLink="/logs"
        routerLinkActive="active">
        <div class="icon" matTooltip="{{ 'navbar.logs' | translate }}">
          <mat-icon class="small-icon" *ngIf="opened"
            >fiber_manual_record</mat-icon
          >
          <mat-icon *ngIf="!opened">memory</mat-icon>
        </div>
        <div class="text" *ngIf="opened">
          {{ 'navbar.logs' | translate }}
        </div>
      </div>
      <div
        *ngIf="Auth.isViewAllowed(['ROLE_LOGS_LOCAL', 'ROLE_ADMIN'])"
        class="item"
        routerLink="/logs-local"
        routerLinkActive="active">
        <div class="icon" matTooltip="{{ 'navbar.logs-local' | translate }}">
          <mat-icon class="small-icon" *ngIf="opened"
            >fiber_manual_record</mat-icon
          >
          <mat-icon *ngIf="!opened">memory</mat-icon>
        </div>
        <div class="text" *ngIf="opened">
          {{ 'navbar.logs-local' | translate }}
        </div>
      </div>
    </div>
  </div>
  <div>
    <!-- <mat-slide-toggle
      class="theme-switch"
      type="checkbox"
      [checked]="isLightTheme"
      (change)="onThemeSwitchChange()" /> -->
  </div>
  <div class="footer">
    <app-user-info [user]="user" [opened]="opened"></app-user-info>
  </div>
</div>
