import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { KeycloakService } from 'keycloak-angular';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  errors: any;
  errorToArray: any = [];
  constructor(
    private keycloak: KeycloakService,
    private _snackBar: MatSnackBar,
    public translateService: TranslateService
  ) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 403) {
          // this.userService.logout();
        }
        if (request.url.includes('/upload-xlsx')) {
          return throwError(error.error);
        }
        if (request.url.includes('/workshop')) {
          return throwError(error.error);
        }
        if (request.url.includes('/preauthorization')) {
          return next.handle(request);
        }
        if (request.url.includes('/upload-invoice')) {
          return throwError(error.error);
        }
        if (request.url.includes('/userinfo')) {
          this.keycloak.logout();
        }
        if (request.url.includes('/counters')) {
          return next.handle(request);
        }
        if (request.url.includes('/billing-revenue/download')) {
          return next.handle(request);
        }
        if (request.url.includes('/data-by-nip')) {
          return throwError(error.status);
        }
        let errorMessage = '';

        if (error.error instanceof ErrorEvent) {
          // client-side error
          errorMessage = `Internal error: ${error.error.message}`;
          this.errorToArray = error;
        } else {
          // server-side error

          errorMessage = `${this.translateService.instant(
            error.error['hydra:description']
          )}\n\n
          `;
          this.errorToArray = error;
        }
        if (error.status !== 0) {
          this._snackBar.open(errorMessage, 'Zamknij', {
            duration: 5000,
          });
        }
        this.errors = localStorage.getItem('error');
        this.errors = JSON.parse(this.errors);
        if (this.errors === null) {
          this.errors = [];
        }
        this.errors.push(this.errorToArray);
        localStorage.setItem('error', JSON.stringify(this.errors));

        return throwError(errorMessage);
      })
    );
  }
}
