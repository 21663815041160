<dialog id="altTextDialog" aria-labelledby="dialogLabel" aria-describedby="dialogDescription">
  <div id="altTextContainer">
    <div id="overallDescription">
      <span id="dialogLabel" data-l10n-id="pdfjs-editor-alt-text-dialog-label" class="title">Choose an option</span>
      <span id="dialogDescription" data-l10n-id="pdfjs-editor-alt-text-dialog-description">
        Alt text (alternative text) helps when people can’t see the image or when it doesn’t load.
      </span>
    </div>
    <div id="addDescription">
      <div class="radio">
        <div class="radioButton">
          <input type="radio" id="descriptionButton" name="altTextOption" aria-describedby="descriptionAreaLabel" checked />
          <label for="descriptionButton" data-l10n-id="pdfjs-editor-alt-text-add-description-label">Add a description</label>
        </div>
        <div class="radioLabel">
          <span id="descriptionAreaLabel" data-l10n-id="pdfjs-editor-alt-text-add-description-description">
            Aim for 1-2 sentences that describe the subject, setting, or actions.
          </span>
        </div>
      </div>
      <div class="descriptionArea">
        <textarea
          id="descriptionTextarea"
          placeholder="For example, “A young man sits down at a table to eat a meal”"
          aria-labelledby="descriptionAreaLabel"
          data-l10n-id="pdfjs-editor-alt-text-textarea"
        ></textarea>
      </div>
    </div>
    <div id="markAsDecorative">
      <div class="radio">
        <div class="radioButton">
          <input type="radio" id="decorativeButton" name="altTextOption" aria-describedby="decorativeLabel" />
          <label for="decorativeButton" data-l10n-id="pdfjs-editor-alt-text-mark-decorative-label">Mark as decorative</label>
        </div>
        <div class="radioLabel">
          <span id="decorativeLabel" data-l10n-id="pdfjs-editor-alt-text-mark-decorative-description">
            This is used for ornamental images, like borders or watermarks.
          </span>
        </div>
      </div>
    </div>
    <div id="buttons">
      <button id="altTextCancel"><span data-l10n-id="pdfjs-editor-alt-text-cancel-button">Cancel</span></button>
      <button id="altTextSave"><span data-l10n-id="pdfjs-editor-alt-text-save-button">Save</span></button>
    </div>
  </div>
</dialog>
