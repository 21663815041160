import { CommentsComponent } from './../../modals/comments/comments';
import {
  Component,
  Input,
  ElementRef,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { TranslateService } from '@ngx-translate/core';
import { OrdersService } from 'src/app/pages/orders/services/orders.service';
import { NavigationEnd, Router } from '@angular/router';

import { environment } from 'src/app/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { NotificationsComponent } from '../../modals/notifications/notifications';
import { PreAuthComponent } from '../../modals/preAuth/preAuth';

@Component({
  selector: 'app-top-navbar',
  templateUrl: './top-navbar.component.html',
  styleUrls: ['./top-navbar.component.scss'],
})
export class TopNavbarComponent {
  @Input() user: any;
  @ViewChild('toggleButton') toggleButton!: ElementRef;
  @ViewChild('menu') menu!: ElementRef;

  isListVisible: boolean = false;
  filteredItems: any[] = [];
  language: string = environment.language;
  searchValue!: any;
  focusedItemIndex: undefined;
  unreadCount!: number | 0;
  checkEngine: boolean = false;
  checkEngineError!: any;
  commentsCount!: number | 0;
  notificationsCount!: number | 0;
  preAuthCount!: number | 0;
  inputValue: string = '';
  menuNotifications: boolean = false;
  menuComments: boolean = false;
  menuPreAuth: boolean = false;
  constructor(
    private keycloak: KeycloakService,
    private dialog: MatDialog,
    private translateService: TranslateService,
    private ordersService: OrdersService,
    private renderer: Renderer2,
    private router: Router
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.renderer.listen('window', 'click', (e: Event) => {
      if (
        e.target !== this.toggleButton.nativeElement &&
        e.target !== this.menu.nativeElement
      ) {
        this.isListVisible = false;
      }
    });
    this.newNotifications();
    setInterval(() => {
      this.newNotifications();
    }, 5000);
    this.getApiState();
    setInterval(() => {
      this.getApiState();
    }, 300000);
  }

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.menuComments = false;
      }
    });
  }

  logout(): void {
    localStorage.clear();
    this.keycloak.logout();
  }

  getApiState() {
    this.ordersService.getApiState().subscribe((res: any) => {
      this.checkEngine = res.problem;
      this.checkEngineError = res;
    });
  }

  filterSearch(event: Event) {
    this.searchValue = {
      searchText: (event.target as HTMLInputElement).value,
      limit: 10,
    };

    if (this.searchValue.searchText.length < 3) {
      this.isListVisible = false;
      return;
    }
    this.ordersService.quickSearch(this.searchValue).subscribe((res: any) => {
      this.filteredItems = res['hydra:member'];
    });
    this.isListVisible = true;
  }

  setListVisibility(valueToSet: boolean): void {
    this.isListVisible = valueToSet;

    if (valueToSet === false) {
      this.focusedItemIndex = undefined;
    }
  }

  setLang(lang: string): void {
    this.translateService.use(lang);
  }
  goToDetails(id: string): void {
    this.router.navigate(['./orders/' + id]);
  }

  notifications() {
    const dialogRef = this.dialog.open(NotificationsComponent, {
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe();
  }
  comments() {
    const dialogRef = this.dialog.open(CommentsComponent, {
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe();
  }

  public newNotifications() {
    this.ordersService.getUnreadNotificationsCount().subscribe((res: any) => {
      this.unreadCount = res;
      this.commentsCount = res.unreadComments;
      this.notificationsCount = res.unreadOrders;
      this.preAuthCount = res.unreadPreauthorizations;
    });
  }

  preAuth() {
    const dialogRef = this.dialog.open(PreAuthComponent, {
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe();
  }

  menuNotificationsOpened() {
    this.menuNotifications = true;
  }
  menuCommentsOpened() {
    this.menuComments = true;
  }

  menuPreAuthOpened() {
    this.menuPreAuth = true;
  }

  menuNotificationsClosed() {
    this.menuNotifications = false;
  }
  menuCommentsClosed() {
    this.menuComments = false;
  }
  menuPreAuthClosed() {
    this.menuPreAuth = false;
  }
}
