<div
  class="main-content"
  *ngIf="Auth.isViewAllowed(['ROLE_HISTORY_DETAILS_VIEW', 'ROLE_ADMIN'])">
  <mat-card>
    <div class="card">
      <div class="card-title">
        <p>Zdarzenia</p>
        <p class="comments">Komentarze</p>
      </div>
      <hr class="custom-hr" />
      <div class="content">
        <div class="activities">
          <div class="activity-item" *ngFor="let activity of history">
            <div class="timeline">
              <div class="activity-icon">
                <div
                  class="activity-icon-content"
                  [class.comment-icon]="activity?.eventType == 'comment'">
                  <mat-icon *ngIf="activity?.eventType == 'activityLog'">
                    insert_drive_file</mat-icon
                  >
                  <mat-icon *ngIf="activity?.eventType == 'comment'"
                    >sms</mat-icon
                  >
                  <p class="date">
                    {{ activity?.eventDate | date: 'YYYY.MM.dd HH:mm' }}
                  </p>
                </div>
              </div>
            </div>
            <div
              class="activity-content"
              [class.comment]="activity?.eventType == 'comment'">
              <div class="activity-item-header">
                <p *ngIf="activity?.eventType == 'activityLog'">
                  <strong>{{ activity?.eventData?.title }}</strong>
                </p>
              </div>
              <div class="activity-item-text">
                <p *ngIf="activity?.eventType == 'activityLog'">
                  {{ activity?.eventData?.detailedInfo }}
                </p>
                <p *ngIf="activity?.eventType == 'activityLog'">
                  {{ activity?.createdBy }} - Serwis
                </p>
                <p *ngIf="activity?.eventType == 'comment'">
                  {{ activity?.eventData?.content }}
                </p>
                <p *ngIf="activity?.eventType == 'comment'">
                  {{ activity?.createdBy }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-card>
</div>

<ng-template> </ng-template>
