import { Component, Inject } from '@angular/core';
import { DeviceService } from '../../services/device.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogRef } from '@angular/cdk/dialog';

@Component({
  selector: 'app-supported-hardware',
  templateUrl: './supported-hardware.component.html',
  styleUrls: ['./supported-hardware.component.scss'],
})
export class SupportedHardwareComponent {
  public types: any = [];
  public addedTypes: any = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: DialogRef,
    private deviceService: DeviceService
  ) {
    this.deviceService.getDeviceTypes().subscribe((res: any) => {
      this.types = res['hydra:member'];
    });

    this.deviceService.getServiceDeviceTypes().subscribe(res => {});
  }

  add(): void {
    let body: any = {
      deviceTypeIds: this.addedTypes,
    };

    this.deviceService
      .addServiceDeviceTypes(body, this.data.service.id)
      .subscribe(res => {});
    this.dialogRef.close('confirm');
  }

  addHardware(type: any, event: any): void {
    if (event.checked) {
      this.addedTypes.push(type);
    } else {
      this.addedTypes.splice(this.addedTypes.indexOf(type), 1);
    }
  }

  setAll() {
    this.types.forEach((type: any) => {
      this.addedTypes.push(`drs-api/device_types/${type}`);
    });
  }
}
