<mat-card *ngIf="res && res.length"
  ><h5>Nowe komentarze</h5>

  <mat-button-toggle-group
    [(ngModel)]="newComments"
    class="new-comments"
    (click)="$event.stopPropagation();">
    <mat-button-toggle (click)="reloadComments()" value="true"
      >Nowe</mat-button-toggle
    >
    <mat-button-toggle (click)="reloadComments()" value="false"
      >Wszystkie</mat-button-toggle
    >
  </mat-button-toggle-group>

  <mat-divider></mat-divider>
</mat-card>
<div
  infiniteScroll
  (scrolled)="onScrollDown()"
  [infiniteScrollDistance]="2"
  [infiniteScrollThrottle]="50"
  [infiniteScrollContainer]="selector"
  [fromRoot]="true"
  class="container-comments">
  <mat-card
    class="card row_2 border"
    *ngFor="let activity of res"
    [ngClass]="{ 'unread': activity.unread !== true }"
    [routerLink]="'/orders/' + activity.orderId">
    <div class="right-s">
      <div class="icon">
        <mat-icon *ngIf="activity?.commentType == 'drs'" class="black-icon"
          >home</mat-icon
        >
        <mat-icon *ngIf="activity?.commentType == 'provider'" class="blue-icon"
          >support_agent</mat-icon
        >
        <mat-icon *ngIf="activity?.commentType == 'partner'" class="orange-icon"
          >build</mat-icon
        >
      </div>

      <div class="left">
        <p *ngIf="activity?.commentType == 'drs'">
          <b
            >{{activity?.commentType | translate}} | {{
            activity?.orderFaultNumber | dashIfEmpty }}</b
          >
        </p>
        <p *ngIf="activity?.commentType == 'provider'">
          <b
            >{{activity?.commentType | translate}} | {{
            activity?.orderFaultNumber | dashIfEmpty }}</b
          >
        </p>
        <p *ngIf="activity?.commentType == 'partner'">
          <b
            >{{activity?.commentType | translate}} | {{
            activity?.orderFaultNumber | dashIfEmpty }}</b
          >
        </p>
        <p>{{ activity?.commentText | dashIfEmpty }}</p>
      </div>
      <div class="right">{{activity?.commentCreatedAt | timeAgo}}</div>
    </div>
    <mat-divider></mat-divider>
  </mat-card>
</div>
<mat-card *ngIf="res && !res.length">
  <h5>Brak nowych komentarzy</h5>
</mat-card>
