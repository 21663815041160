<h1 mat-dialog-title class="title">Zaktualizuj numer faktury dla okresu</h1>
<hr class="custom-hr" />
<div mat-dialog-content>
  <div class="did-floating-label-content w-100 mt-15">
    <mat-select [(ngModel)]="invoicePeriodValue" class="did-floating-input">
      <mat-option
        *ngFor="let period of data.billingPeriods"
        [value]="period.period"
        >{{ period.period }}</mat-option
      >
    </mat-select>
    <mat-label class="did-floating-label">{{
      'invoicePeriod' | translate
    }}</mat-label>
  </div>
  <div class="did-floating-label-content w-100 mt-15">
    <input
      [(ngModel)]="invoiceNumber"
      maxlength="50"
      class="did-floating-input"
      type="text"
      placeholder=" " />
    <label class="did-floating-label">{{ 'Numer faktury' | translate }}</label>
  </div>
</div>
<div mat-dialog-actions>
  <button
    mat-button
    class="secondary-btn"
    color="accent"
    [mat-dialog-close]="'cancel'">
    {{ 'cancel' | translate }}
  </button>
  <button mat-button class="primary-btn" (click)="onSubmit()" color="primary">
    {{ 'Save' | translate }}
  </button>
</div>
