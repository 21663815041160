import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GenerateCostsReportComponent } from 'src/app/shared/modals/generate-costs-report/generate-costs-report';
import { GenerateServiceCostsAndInsuranceComponent } from 'src/app/shared/modals/generate-service-costs-and-insurance/generate-service-costs-and-insurance';
import { GenerateSettlementReportComponent } from 'src/app/shared/modals/generate-settlement-report/generate-settlement-report';

@Component({
  selector: 'app-settings',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
})
export class ReportsComponent implements OnInit {
  constructor(private dialog: MatDialog) {}
  ngOnInit(): void {}

  generateSettlementSummary(): void {
    const dialogRef = this.dialog.open(GenerateSettlementReportComponent, {
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'show') {
      }
    });
  }

  generateCostsSummary(): void {
    const dialogRef = this.dialog.open(GenerateCostsReportComponent, {
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'show') {
      }
    });
  }

  generateServiceCostsAndInsurance(): void {
    const dialogRef = this.dialog.open(
      GenerateServiceCostsAndInsuranceComponent,
      {
        disableClose: true,
      }
    );

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'show') {
      }
    });
  }
}
