import { Component, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { OrdersService } from 'src/app/pages/orders/services/orders.service';
import {
  MatDatepickerControl,
  MatDatepickerPanel,
} from '@angular/material/datepicker';
import { MatStepper } from '@angular/material/stepper';

@Component({
  selector: 'app-add-invoice',
  templateUrl: './add-invoice.component.html',
  styleUrls: ['./add-invoice.component.scss'],
})
export class AddInvoiceComponent {
  file = new FormControl('', [Validators.required]);
  file_store: any;
  invoice_number: any;
  invoice_date: Date = new Date();
  net_invoice_amount: any;
  gross_invoice_amount = new FormControl('', [Validators.required]);
  @Optional() @Inject(MAT_DIALOG_DATA) public data: any = {};
  gross_invoice_amount_to_verify: any;
  invalid: any;
  invalid_file: any;
  isLoading: any;
  isLinear: any = true;
  display: FormControl = new FormControl('', Validators.required);
  error!: any;
  res: any;
  firstFormGroup: any = this._formBuilder.group({
    firstCtrl: ['', Validators.required],
  });
  secondFormGroup: any = '';
  picker1!: MatDatepickerPanel<MatDatepickerControl<any>, any, any>;
  maxSize: any;
  fileSizeError: boolean = false;
  constructor(
    private _formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<AddInvoiceComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public dialog_data: any = {},
    private ordersService: OrdersService
  ) {
    this.getMaxSize();
    if (dialog_data) {
      this.invoice_number = dialog_data.invoice_number;
      this.invoice_date = new Date(dialog_data.invoice_date);

      this.net_invoice_amount = dialog_data.net_invoice_amount;
      this.gross_invoice_amount_to_verify = dialog_data.gross_invoice_amount;
    }
  }

  handleFileInputChange(l: any): void {
    this.file_store = l[0];
    if (l.length) {
      const f = l[0];
      const count = l.length > 1 ? `(+${l.length - 1} files)` : '';
      this.display.patchValue(`${f.name}${count}`);
    } else {
      this.display.patchValue('');
    }
    if (!l) return;
    const maxSize = this.maxSize;
    Array.from(l).forEach((file: any) => {
      if (file.size > maxSize) {
        this.fileSizeError = true;
      } else {
        this.fileSizeError = false;
      }
    });
  }

  add(stepper: MatStepper): void {
    if (this.fileSizeError) {
      return;
    }
    if (
      this.gross_invoice_amount_to_verify == this.gross_invoice_amount.value
    ) {
      this.invalid = false;
      if (!this.file_store) {
        this.invalid_file = true;
        this.file.hasError('required');
      } else {
        this.invalid_file = false;
        this.isLoading = true;
        let data = {
          file: this.file_store,
          name: this.file_store.name,
        };

        this.ordersService.postInvoice(data, '').subscribe(
          res => {
            this.isLoading = false;

            this.res = res;
            stepper.next();
          },
          error => {
            this.isLoading = false;

            this.error = error;
          }
        );
      }
    } else {
      this.invalid = true;
      this.gross_invoice_amount.hasError('required');
    }
  }

  getMaxSize() {
    this.ordersService.getMaxSize().subscribe((res: any) => {
      this.maxSize = res['maxUploadSize'];
    });
  }
}
