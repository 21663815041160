import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthGuard } from 'dashboard-libs/guards/access-allowed.guard';
import { OrdersService } from 'src/app/pages/orders/services/orders.service';

@Component({
  selector: 'app-fix-cost-invoice',
  templateUrl: './fix-cost-invoice.component.html',
  styleUrl: './fix-cost-invoice.component.scss',
})
export class FixCostInvoiceComponent {
  invoicePeriodValue: any;
  invoiceNumber: any;

  constructor(
    private dialogRef: MatDialogRef<FixCostInvoiceComponent>,
    public Auth: AuthGuard,
    private ordersService: OrdersService,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
  onCancel() {
    this.dialogRef.close();
  }

  onSubmit() {
    this.ordersService
      .fixInvoiceNumber(this.invoiceNumber, this.invoicePeriodValue)
      .subscribe(
        res => {
          this._snackBar.open('Faktura została poprawnie zapisana', '', {
            duration: 2000,
          });
          this.dialogRef.close({ 'Success': true });
        },
        err => {
          console.log(err);
        }
      );
  }
}
