import { Component, OnInit } from '@angular/core';

import { MatTableDataSource } from '@angular/material/table';
import { AuthGuard } from 'src/app/shared/guards/access-allowed.guard';
import { UserService } from 'src/app/shared/services/user.service';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-logs',
  templateUrl: './logs-local.component.html',
  styleUrls: ['./logs-local.component.scss'],
})
export class LogsLocalComponent implements OnInit {
  error: any;

  dataSourceErrors: any;

  displayedColumnsErrors: string[] = ['name', 'message', 'ok', 'status', 'url'];
  constructor(
    public userService: UserService,
    public Auth: AuthGuard,
    private datePipe: DatePipe
  ) {}
  ngOnInit(): void {
    this.error = localStorage.getItem('error');
    this.error = JSON.parse(this.error);
    this.dataSourceErrors = new MatTableDataSource(this.error);
  }
}
