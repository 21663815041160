<h1 mat-dialog-title class="title">
  {{ 'orders-front.add_note' | translate }}
</h1>
<hr class="custom-hr" />
<div mat-dialog-content>
  <p class="p-content">
    {{ 'orders-front.fill_in_the_information_below' | translate }}
  </p>
  <div class="did-floating-label-content w-100 mt-15">
    <mat-form-field class="text-area">
      <mat-label>{{ 'orders-front.note' | translate }}</mat-label>
      <textarea
        matInput
        cdkTextareaAutosize
        #autosize="cdkTextareaAutosize"
        cdkAutosizeMinRows="3"
        class="did-floating-input"
        id="note"
        type="text"
        [(ngModel)]="note"
        placeholder=" ">
      </textarea>
    </mat-form-field>
  </div>
  <p class="p-content" hidden>{{ 'orders-front.visibility' | translate }}</p>

  <section class="example-section" hidden>
    <mat-checkbox class="example-margin">{{
      'orders-front.private' | translate
    }}</mat-checkbox>
    <mat-checkbox class="example-margin">{{
      'orders-front.public' | translate
    }}</mat-checkbox>
  </section>
</div>
<div mat-dialog-actions>
  <button
    mat-button
    class="secondary-btn"
    color="accent"
    [mat-dialog-close]="'cancel'">
    {{ 'orders-front.cancel' | translate }}
  </button>
  <button mat-button class="primary-btn" (click)="onSubmit()" color="primary">
    {{ 'orders-front.add' | translate }}
  </button>
</div>
