import { Component, OnInit, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { OrdersService } from '../../../../orders/services/orders.service';
import { AddAttachmentComponent } from '../../../../../shared/modals/add-attachment/add-attachment.component';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ShowDocComponent } from 'src/app/shared/modals/show-doc/show-doc-component';
import { AuthGuard } from 'src/app/shared/guards/access-allowed.guard';

@Component({
  selector: 'app-archived-order-documents',
  templateUrl: './archived-order-documents.component.html',
  styleUrls: ['./archived-order-documents.component.scss'],
})
export class ArchivedOrderDocumentsComponent implements OnInit {
  @Input() data: any;
  displayedColumns: string[] = [
    'fileName',
    'type',
    'createdAt',
    'author',
    'size',
    'partner',
    'orderSource',
    'actions',
  ];
  dataSource = new MatTableDataSource<any>();
  attachments!: any;

  constructor(
    private ordersService: OrdersService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    public Auth: AuthGuard
  ) {}

  ngOnInit(): void {
    this.ordersService.getAttachments(this.data.id).subscribe((res: any) => {
      this.dataSource = new MatTableDataSource(res['hydra:member']);
    });
  }

  showAttachment(id: string) {
    this.ordersService.getAttachment(id).subscribe((res: any) => {
      const dialogRef = this.dialog.open(ShowDocComponent, {
        data: { data: res },
        disableClose: false,
      });
    });
  }

  download(id: string, name: string) {
    this.ordersService.getAttachment(id).subscribe((res: any) => {
      let dataType = res.type;
      let binaryData = [];
      binaryData.push(res);
      let downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(
        new Blob(binaryData, { type: dataType })
      );
      downloadLink.setAttribute('download', name);
      document.body.appendChild(downloadLink);
      downloadLink.click();
    });
  }
}
