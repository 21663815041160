import { Component, Input, AfterViewInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ShowDataComponent } from '../../../../../shared/modals/show-data/show-data.component';
import { AddNoteComponent } from '../../../../../shared/modals/add-note/add-note.component';
import { AddCommentComponent } from '../../../../../shared/modals/add-comment/add-comment.component';
import { OrdersService } from '../../../../orders/services/orders.service';
import { AuthGuard } from 'src/app/shared/guards/access-allowed.guard';
import { NgxDateFormat, NgxTimelineItemPosition } from '@frxjs/ngx-timeline';
@Component({
  selector: 'app-archived-order-details',
  templateUrl: './archived-order-details.component.html',
  styleUrls: ['./archived-order-details.component.scss'],
})
export class ArchivedOrderDetailsComponent {
  @Input() order: any;
  @Input() history!: Array<any>;
  comments: any = [];
  ngxDateFormat = NgxDateFormat;
  commentsEmpty: boolean = true;
  NgxTimelineItemPosition = NgxTimelineItemPosition.ON_RIGHT;
  activeLog: Array<any> = [];
  activeLogEmpty: boolean = true;
  public i = 0;
  public z = 0;
  public showData!: boolean;
  public timeline_change: any = 'ON_DIFFERENT_MONTH_IN_GROUP';

  public policy!: any;
  public showDataPolicy!: boolean;
  events!: {
    id: number;
    description: string;
    timestamp: Date;
    title: string;
  }[];

  constructor(
    public dialog: MatDialog,
    private orderService: OrdersService,
    public Auth: AuthGuard
  ) {}

  ngOnChanges() {
    const today = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);
    const nextMonth = new Date();
    nextMonth.setMonth(today.getMonth() + 1);
    const nextYear = new Date();
    nextYear.setFullYear(today.getFullYear() + 1);
    this.events = [];
    this.comments = [];
    if (this.history) {
      const i = 0;
      this.history.forEach((data: any) => {
        if (data.eventType === 'comment') {
          this.comments.push(data);
          this.commentsEmpty = false;
        } else {
          const timelineActiveLog: any = {};
          timelineActiveLog.id = this.i;
          timelineActiveLog.description = data.eventData.detailedInfo;
          timelineActiveLog.timestamp = new Date(data.eventDate);
          timelineActiveLog.title = data.eventData.title;
          timelineActiveLog.alertType = data.eventData.alertType;
          timelineActiveLog.itemPosition = this.NgxTimelineItemPosition;

          this.events.push(timelineActiveLog);
          this.activeLogEmpty = false;
          this.i++;
        }
      });
    }
  }

  showDataModal(): void {
    const dialogRef = this.dialog.open(ShowDataComponent, {
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'show') {
        this.showData = true;
      }
    });
  }

  showDataModalPolicy(): void {
    const dialogRef = this.dialog.open(ShowDataComponent, {
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'show') {
        this.orderService
          .getPolicyByName(this.order.insuranceNumber)
          .subscribe(res => {
            this.policy = res;
          });
        this.showDataPolicy = true;
      }
    });
  }
}
