import { Component, Input } from '@angular/core';
import { AuthGuard } from 'src/app/shared/guards/access-allowed.guard';

@Component({
  selector: 'app-order-repair',
  templateUrl: './order-repair.component.html',
  styleUrls: ['./order-repair.component.scss'],
})
export class OrderRepairComponent {
  constructor(public Auth: AuthGuard) {}
  @Input() history!: any;
}
