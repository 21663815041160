<div class="user-info">
  <div class="notification">
    <mat-icon
      *ngIf="checkEngine"
      [matMenuTriggerFor]="errors"
      svgIcon="check-engine"
      class="check-engine"></mat-icon>
    <mat-menu #errors="matMenu" class="menu-errors">
      <p class="errors">
        {{ 'lastRequestDate' | translate }} :
        {{
          checkEngineError?.lastRequestDate
            | date: 'YYYY-MM-dd | HH:mm:ss'
            | dashIfEmpty
        }}
      </p>
      <p
        class="errors"
        [ngClass]="{
          'red': checkEngineError?.lastRequestResponseCode > 299,
          'green': checkEngineError?.lastRequestResponseCode < 300,
        }">
        {{ 'lastRequestResponseCode' | translate }} :
        {{ checkEngineError?.lastRequestResponseCode | dashIfEmpty }}
      </p>
      <p class="errors">
        {{ 'lastRequestUri' | translate }} :
        {{ checkEngineError?.lastRequestUri | dashIfEmpty }}
      </p>
    </mat-menu>
    <div
      [matBadge]="notificationsCount"
      [matBadgeHidden]="!notificationsCount || notificationsCount === 0"
      matBadgeOverlap="false"
      class="mat-icon">
      <mat-icon
        [matMenuTriggerFor]="orders"
        (menuOpened)="menuNotificationsOpened()"
        (menuClosed)="menuNotificationsClosed()"
        >assignment late icon
      </mat-icon>
      <mat-menu #orders="matMenu" class="menu-dropdown">
        <app-notifications
          [someInput]="inputValue"
          *ngIf="menuNotifications"></app-notifications>
      </mat-menu>
    </div>
    <div
      [matBadge]="commentsCount"
      matBadgeOverlap="false"
      class="mat-icon"
      [matBadgeHidden]="!commentsCount || commentsCount === 0">
      <mat-icon
        [matMenuTriggerFor]="comments"
        (menuOpened)="menuCommentsOpened()"
        >announcement</mat-icon
      >
      <mat-menu
        #comments="matMenu"
        class="menu-dropdown"
        (click)="$event.stopPropagation()">
        <app-comments
          (click)="$event.stopPropagation()"
          [someInput]="inputValue"
          *ngIf="menuComments"></app-comments>
      </mat-menu>
    </div>
    <div
      [matBadge]="preAuthCount"
      matBadgeOverlap="false"
      class="mat-icon"
      [matBadgeHidden]="!preAuthCount || preAuthCount === 0">
      <mat-icon
        [matMenuTriggerFor]="preAuth"
        (menuOpened)="menuPreAuthOpened()"
        (menuClosed)="menuPreAuthClosed()"
        >notifications_none</mat-icon
      >
      <mat-menu #preAuth="matMenu" class="menu-dropdown">
        <app-preAuth [someInput]="inputValue" *ngIf="menuPreAuth"></app-preAuth>
      </mat-menu>
    </div>
  </div>
  <div>
    <mat-form-field
      class="example-full-width"
      (click)="setListVisibility(true)">
      <mat-label>Szukaj</mat-label>
      <input
        matInput
        (keyup)="filterSearch($event)"
        #toggleButton
        (click)="setListVisibility(true)" />
    </mat-form-field>
    <div #menu>
      <div class="list-container" *ngIf="isListVisible">
        <ul>
          <li
            #listItemRef
            *ngFor="let item of filteredItems; let i = index"
            (click)="goToDetails(item.id)">
            {{ item.faultNumber }} | {{ item.state | translate }}
            <hr />
          </li>
          <li #listItemRef *ngIf="filteredItems.length > 0">Pokaż wszystkie</li>
          <li #listItemRef *ngIf="filteredItems.length == 0">Brak danych</li>
        </ul>
      </div>
    </div>
  </div>

  <mat-select
    class="language"
    [(value)]="language"
    placeholder="Mode"
    (selectionChange)="setLang(language)">
    <mat-select-trigger>{{ language | uppercase }}</mat-select-trigger>
    <mat-option value="pl">PL</mat-option>
    <mat-option value="en">EN</mat-option>
    <mat-option value="fr">FR</mat-option>
  </mat-select>
</div>
