import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-archived-order-repair',
  templateUrl: './archived-order-repair.component.html',
  styleUrls: ['./archived-order-repair.component.scss'],
})
export class ArchivedOrderRepairComponent {
  @Input() history!: any;
}
