import { Component, Input } from '@angular/core';
import { UserService } from '../../services/user.service';
import {
  trigger,
  state,
  transition,
  style,
  animate,
} from '@angular/animations';
import { KeycloakService } from 'keycloak-angular';
import { AuthGuard } from '../../guards/access-allowed.guard';
import { NavigationEnd, Router } from '@angular/router';
@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent {
  @Input() opened: any;
  public isLightTheme = true;
  showItems: boolean = false;
  showSettingItems: boolean = false;
  isAdmin!: boolean;
  public showMenu!: boolean;
  user: any;
  constructor(
    private userService: UserService,
    private keycloak: KeycloakService,
    public Auth: AuthGuard,
    public router: Router
  ) {
    this.userService.user$.subscribe((res: any) => {
      this.isAdmin = res?.roles?.includes('ROLE_ADMIN');
    });
  }

  ngAfterViewInit(): void {
    this.userService.getUserInfo().subscribe(res => {
      this.user = res;
      this.userService.setUser(res);
    });

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (
          event.urlAfterRedirects === '/costs' ||
          event.urlAfterRedirects === '/settlements'
        ) {
          this.showCosts();
        } else {
          this.showItems = false;
        }
      }
    });
  }

  logout(): void {
    localStorage.clear();
    this.keycloak.logout();
  }

  onThemeSwitchChange() {
    this.isLightTheme = !this.isLightTheme;

    document.body.setAttribute(
      'data-theme',
      this.isLightTheme ? 'light' : 'dark'
    );
  }

  showCosts() {
    this.showItems = true;
    this.showSettingItems = false;
  }
  showHideSettings() {
    this.showItems = false;
    this.showSettingItems = !this.showSettingItems;
  }
}
