import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/app/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DeviceService {
  constructor(private http: HttpClient) {}

  getDeviceTypes() {
    return this.http.get(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/devices/types`
    );
  }

  getServiceDeviceTypes() {
    return this.http.get(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/workshop_device_types`
    );
  }

  getServiceDeviceTypesByWorkshop(workshopId: any) {
    return this.http.get(environment.API.apiHost + workshopId);
  }

  getServiceDeviceTypeByUrl(url: string) {
    return this.http.get(environment.API.apiHost + url);
  }

  addServiceDeviceTypes(body: any, workshopId: any) {
    return this.http.put(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/workshops/` +
        workshopId +
        `/workshop-device-types`,
      body
    );
  }
}
