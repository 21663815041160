import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { KeycloakService } from 'keycloak-angular';
import { environment } from 'src/app/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class ActivityService {
  timeout: number = environment.sessionTime;
  interval: any;

  constructor(
    private dialog: MatDialog,
    private keycloakService: KeycloakService
  ) {}

  resetTimeout() {
    this.timeout = environment.sessionTime;
  }
}
