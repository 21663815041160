<h1 mat-dialog-title class="title">
  {{ 'services-front.adding_a_new_service' | translate }}
</h1>
<p class="title-desc">
  {{ 'services-front.fill_in_the_information_below' | translate }}
</p>
<hr class="custom-hr" />
<div class="tabs no-click">
  <div class="tab no-click" [class.active]="tab == 'companyInfo'">
    {{ 'services-front.company_details' | translate }}
  </div>

  <div class="tab no-click" [class.active]="tab == 'contact'">
    {{ 'services-front.contact_information' | translate }}
  </div>
</div>
<div mat-dialog-content>
  <div [hidden]="tab !== 'companyInfo'">
    <form [formGroup]="formCompanyInfo">
      <div class="did-floating-label-content col-2 mt-15">
        <input
          class="did-floating-input"
          formControlName="name"
          type="text"
          placeholder=" "
          required />
        <label class="did-floating-label">{{
          'services-front.company_name' | translate
        }}</label>
        <mat-error
          *ngIf="companyNext && formCompanyInfo.controls['name'].errors?.['required']"
          >{{ 'services-front.field_is_required' | translate }}</mat-error
        >
      </div>
      <div class="flex first-flex">
        <div class="did-floating-label-content mr-10 mt-15">
          <input
            class="did-floating-input"
            formControlName="regon"
            type="text"
            mask="000000000"
            required
            placeholder=" " />
          <label class="did-floating-label">{{
            'services-front.regon' | translate
          }}</label>
          <mat-error
            *ngIf="companyNext && formCompanyInfo.controls['regon'].errors?.['required']"
            >{{ 'services-front.field_is_required' | translate }}</mat-error
          >
          <mat-error
            *ngIf="companyNext && formCompanyInfo.controls['regon'].errors?.['pattern']"
            >To pole wymaga podania 9 cyfrowego numeru regon</mat-error
          >
        </div>
        <div class="did-floating-label-content mt-15">
          <input
            class="did-floating-input"
            formControlName="nip"
            mask="0000000000"
            type="text"
            required
            placeholder=" " />

          <label class="did-floating-label">{{
            'services-front.nip' | translate
          }}</label>
          <mat-icon
            matPrefix
            class="icon-inside-input"
            (click)="getGusInfo()"
            matTooltip="{{ 'services-front.find_by_nip' | translate }}"
            >search</mat-icon
          >
          <mat-error
            *ngIf="companyNext && formCompanyInfo.controls['nip']?.errors?.['required']"
            >{{ 'services-front.field_is_required' | translate }}</mat-error
          >
          <mat-error *ngIf="gusError">
            <p *ngIf="gusError == 404">
              Nie znaleziono podmiotu o takim numerze NIP
            </p>
            <p *ngIf="gusError == 400">Błędny numer NIP</p>
            <p *ngIf="gusError == 'other'"></p>
          </mat-error>
          <mat-error
            *ngIf="!(companyNext && formCompanyInfo.controls['nip'].errors?.['required']) && companyNext && formCompanyInfo.controls['nip'].errors?.['pattern'] || !(companyNext && formCompanyInfo.controls['nip'].errors?.['required']) && gusInfo  "
            >To pole wymaga podania 10 cyfrowego numeru nip
          </mat-error>
        </div>
      </div>
      <div class="flex">
        <div class="did-floating-label-content mr-10 mt-15">
          <input
            class="did-floating-input"
            formControlName="city"
            required
            type="text"
            placeholder=" " />
          <label class="did-floating-label">{{
            'services-front.city' | translate
          }}</label>
          <mat-error
            *ngIf="companyNext && formCompanyInfo.controls['city'].errors?.['required']"
            >{{ 'services-front.field_is_required' | translate }}</mat-error
          >
          <mat-error
            *ngIf="companyNext && (formCompanyInfo.controls['city'].errors?.['minlength'] || formCompanyInfo.controls['city'].errors?.['maxlength'])"
            >To pole wymaga podania prawidłowej nazwy miasta</mat-error
          >
        </div>
        <div class="did-floating-label-content mt-15">
          <input
            class="did-floating-input"
            formControlName="postalCode"
            required
            mask="00-000"
            type="text"
            placeholder=" " />
          <label class="did-floating-label">{{
            'services-front.postal_code' | translate
          }}</label>
          <mat-error
            *ngIf="companyNext && formCompanyInfo.controls['postalCode']?.errors?.['required']"
            >{{ 'services-front.field_is_required' | translate }}</mat-error
          >
          <mat-error
            *ngIf="companyNext && formCompanyInfo.controls['postalCode']?.errors?.['mask'] || companyNext && formCompanyInfo.controls['postalCode']?.errors?.['pattern']"
            >To pole wymaga podania prawidłowego nr pocztowego</mat-error
          >
        </div>
      </div>
      <div class="flex">
        <div class="did-floating-label-content mr-10 mt-15">
          <input
            class="did-floating-input"
            formControlName="street"
            required
            type="text"
            placeholder=" " />
          <label class="did-floating-label">{{
            'services-front.street' | translate
          }}</label>
          <mat-error
            *ngIf="companyNext && formCompanyInfo.controls['street'].errors?.['required']"
            >{{ 'services-front.field_is_required' | translate }}</mat-error
          >
          <mat-error
            *ngIf="companyNext && (formCompanyInfo.controls['street'].errors?.['minlength'] || formCompanyInfo.controls['street'].errors?.['maxlength'])"
            >To pole wymaga podania prawidłowej nazwy ulicy</mat-error
          >
        </div>
        <div class="did-floating-label-content small-input mt-15 mr-10">
          <input
            class="did-floating-input"
            formControlName="houseNumber"
            required
            type="text"
            placeholder=" " />
          <label class="did-floating-label">{{
            'services-front.house_number' | translate
          }}</label>
          <mat-error
            *ngIf="companyNext && formCompanyInfo.controls['houseNumber'].errors?.['required']"
            >{{ 'services-front.field_is_required' | translate }}</mat-error
          >
          <mat-error
            *ngIf="companyNext && formCompanyInfo.controls['houseNumber'].errors?.['pattern']"
            >To pole wymaga podania prawidłowego numeru domu</mat-error
          >
        </div>
        <div class="did-floating-label-content small-input mt-15">
          <input
            class="did-floating-input"
            formControlName="apartmentNumber"
            type="text"
            placeholder=" " />
          <label class="did-floating-label">{{
            'services-front.apartment_number' | translate
          }}</label>
        </div>
      </div>

      <div class="flex">
        <div class="did-floating-label-content mr-10 mt-15">
          <input
            class="did-floating-input"
            formControlName="bankNumber"
            required
            mask="SS00-0000-0000-0000-0000-0000-0000"
            type="text"
            placeholder="AA00-0000-0000-0000-0000-0000-0000"
            oninput="this.value = this.value.toUpperCase()" />
          <label class="did-floating-label did-floating-label-focus">{{
            'services-front.bank_account_number(IBAN)' | translate
          }}</label>
          <mat-error
            *ngIf="companyNext && formCompanyInfo.controls['bankNumber'].errors?.['required']"
            >{{ 'services-front.field_is_required' | translate }}</mat-error
          >
          <mat-error
            *ngIf="companyNext && formCompanyInfo.controls['bankNumber'].errors?.['pattern'] || companyNext && formCompanyInfo.controls['bankNumber'].errors?.['mask']"
            >To pole wymaga podania prawidłowego nr konta</mat-error
          >
        </div>
        <div class="did-floating-label-content mt-15">
          <input
            class="did-floating-input"
            formControlName="number"
            type="text"
            required
            placeholder=" " />
          <label class="did-floating-label">{{
            'services-front.agreement_number' | translate
          }}</label>
          <mat-error
            *ngIf="companyNext && formCompanyInfo.controls['number'].errors?.['required']"
            >{{ 'services-front.field_is_required' | translate }}</mat-error
          >
        </div>
      </div>
    </form>
  </div>

  <div [hidden]="tab !== 'contact'">
    <form [formGroup]="formContactInfo">
      <div class="flex">
        <div class="did-floating-label-content mr-10 mt-15">
          <input
            class="did-floating-input"
            formControlName="name"
            required
            type="text"
            placeholder=" " />
          <label class="did-floating-label">{{
            'services-front.name' | translate
          }}</label>
          <mat-error
            *ngIf="submitted && formContactInfo.controls['name'].errors?.['required']"
            >{{ 'services-front.field_is_required' | translate }}</mat-error
          >
          <mat-error
            *ngIf="submitted && formContactInfo.controls['name'].errors?.['pattern']"
            >To pole wymaga podania prawidłowego imienia</mat-error
          >
        </div>
        <div class="did-floating-label-content mt-15">
          <input
            class="did-floating-input"
            formControlName="surname"
            required
            type="text"
            placeholder=" " />
          <label class="did-floating-label">{{
            'services-front.surname' | translate
          }}</label>
          <mat-error
            *ngIf="submitted && formContactInfo.controls['surname'].errors?.['required']"
            >{{ 'services-front.field_is_required' | translate }}</mat-error
          >
          <mat-error
            *ngIf="submitted && formContactInfo.controls['surname'].errors?.['pattern']"
            >To pole wymaga podania prawidłowego nazwiska</mat-error
          >
        </div>
      </div>
      <div class="flex">
        <div class="did-floating-label-content mr-10 mt-15">
          <ngx-material-intl-tel-input
            class="phone-number"
            [fieldControl]="phone1"
            [preferredCountries]="['pl', 'de', 'us', 'gb']"
            [autoIpLookup]="true"
            [required]="true"
            [iconMakeCall]="false"
            [textLabels]="{
              mainLabel: 'Główny numer telefonu',
              codePlaceholder: 'Kod',
              searchPlaceholderLabel: 'Szukaj',
              noEntriesFoundLabel: 'Nie znaleziono krajów',
              nationalNumberLabel: 'Numer telefonu',
              hintLabel: '',
              invalidNumberError: 'Numer jest niepoprawny',
              requiredError: 'To pole jest wymagane'
            }">
          </ngx-material-intl-tel-input>
          <mat-error
            *ngIf="submitted && formContactInfo.controls['phoneNumber'].errors?.['required']"
            >{{ 'services-front.field_is_required' | translate }}</mat-error
          >
          <!-- 
          <mat-error
            *ngIf="submitted && formContactInfo.controls['phoneNumber'].errors?.['required']"
            >{{ 'services-front.field_is_required' | translate }}</mat-error
          >
          <mat-error
            *ngIf="submitted && formContactInfo.controls['phoneNumber'].errors?.['pattern']"
            >To pole wymaga podania prawidłowego nr telefonu</mat-error
          > -->
        </div>
        <div class="did-floating-label-content mt-15">
          <ngx-material-intl-tel-input
            class="phone-number"
            [fieldControl]="phone2"
            [autoIpLookup]="true"
            [iconMakeCall]="false"
            [numberValidation]="true"
            [preferredCountries]="['pl', 'de', 'us', 'gb']"
            [textLabels]="{
              mainLabel:
                'Dodatkowy 
             numer telefonu ',
              codePlaceholder: 'Kod',
              searchPlaceholderLabel: 'Szukaj',
              noEntriesFoundLabel: 'Nie znaleziono krajów',
              nationalNumberLabel: 'Numer telefonu',
              hintLabel: '',
              invalidNumberError: 'Numer jest niepoprawny',
              requiredError: 'To pole jest wymagane'
            }">
          </ngx-material-intl-tel-input>
        </div>
      </div>
      <div class="flex">
        <div class="did-floating-label-content mr-10 mt-15">
          <input
            class="did-floating-input"
            formControlName="email"
            required
            type="text"
            placeholder=" " />
          <label class="did-floating-label">{{
            'services-front.email_address' | translate
          }}</label>
          <mat-error
            *ngIf="submitted && formContactInfo.controls['email'].errors?.['required']"
            >{{ 'services-front.field_is_required' | translate }}</mat-error
          >
          <mat-error
            *ngIf="submitted && formContactInfo.controls['email'].errors?.['pattern']"
            >To pole wymaga podania prawidłowego adresu email</mat-error
          >
        </div>
        <div class="did-floating-label-content mt-15">
          <select class="did-floating-select" formControlName="type">
            <option value="Main">Główny</option>
            <option value="Additional">Dodatkowy</option>
          </select>
          <label class="did-floating-label">{{
            'services-front.contact_type' | translate
          }}</label>
          <mat-error
            *ngIf="submitted && formContactInfo.controls['type'].errors?.['required']"
            >{{ 'services-front.field_is_required' | translate }}</mat-error
          >
        </div>
      </div>
    </form>
  </div>
</div>
<div class="text-right buttons" mat-dialog-actions>
  <button
    mat-button
    class="secondary-btn"
    color="accent"
    [mat-dialog-close]="'cancel'">
    {{ 'services-front.cancel' | translate }}
  </button>
  <button
    mat-button
    class="primary-btn"
    color="accent"
    *ngIf="tab === 'companyInfo'"
    (click)="getGusInfo()">
    {{ 'services-front.find_by_nip' | translate }}
  </button>
  <button
    mat-button
    class="secondary-btn"
    (click)="back()"
    *ngIf="tab === 'contact'"
    color="accent">
    {{ 'services-front.back' | translate }}
  </button>
  <button
    mat-button
    class="primary-btn"
    (click)="next()"
    *ngIf="tab === 'companyInfo'"
    color="primary">
    {{ 'services-front.next' | translate }}
  </button>
  <button
    mat-button
    class="primary-btn"
    *ngIf="tab === 'contact'"
    color="primary"
    (click)="save()">
    {{ 'services-front.save' | translate }}
  </button>
</div>
