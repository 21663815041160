<div>
  <div class="title">
    <app-breadcrumb [name]="order?.faultNumber"></app-breadcrumb>
  </div>
</div>
<app-settlement-info [order]="order"></app-settlement-info>
<mat-card class="settlement-details">
  <div class="header">
    <div>
      <div class="title-details">Koszta naprawy</div>
      <div
        class="fv-info"
        (click)="downloadFv()"
        *ngIf="order?.billing?.invoice">
        Nr
        <span
          >{{ this.order?.billing?.invoice }}
          <mat-icon class="downloadFv">download</mat-icon></span
        >
      </div>
      <div *ngIf="!order?.billing?.invoice">
        <span>Nr FV: Brak faktury</span>
      </div>
    </div>
    <button (click)="addInvoice()" mat-stroked-button>Rozlicz</button>
  </div>

  <div class="details">
    <hr class="custom-hr" />
    <div class="content-item content-header">
      <p class="flex-70">Nazwa kosztu</p>
      <p>Kwota netto</p>
      <p>Kwota brutto</p>
    </div>
    <hr class="custom-hr" />
    <div class="content-item">
      <p class="flex-70">Części</p>
      <p>
        {{
          order?.costs?.parts || 0
            | currency: 'PLN' : 'symbol' : '1.2' : 'pl'
            | dashIfEmpty
        }}
      </p>
      <p>
        {{
          order?.costs?.parts * 1.23 || 0
            | currency: 'PLN' : 'symbol' : '1.2' : 'pl'
            | dashIfEmpty
        }}
      </p>
    </div>
    <hr class="custom-hr" />
    <div class="content-item">
      <p class="flex-70">Ekspertyza</p>
      <p>
        {{
          (order?.costs?.consultation || 0) +
            (order?.costs?.replacement || 0) || 0
            | currency: 'PLN' : 'symbol' : '1.2' : 'pl'
            | dashIfEmpty
        }}
      </p>
      <p>
        {{
          ((order?.costs?.consultation || 0) +
            (order?.costs?.replacement || 0)) *
            1.23 || 0
            | currency: 'PLN' : 'symbol' : '1.2' : 'pl'
            | dashIfEmpty
        }}
      </p>
    </div>
    <hr class="custom-hr" />
    <div class="content-item">
      <p class="flex-70">Robocizna</p>
      <p>
        {{
          order?.costs?.repair || 0 || 0
            | currency: 'PLN' : 'symbol' : '1.2' : 'pl'
            | dashIfEmpty
        }}
      </p>
      <p>
        {{
          (order?.costs?.repair || 0) * 1.23 || 0
            | currency: 'PLN' : 'symbol' : '1.2' : 'pl'
            | dashIfEmpty
        }}
      </p>
    </div>
    <hr class="custom-hr" />

    <div class="content-item">
      <p class="flex-70">Transport</p>
      <p>
        {{
          (order?.costs?.courier || 0) + (order?.costs?.service || 0) || 0
            | currency: 'PLN' : 'symbol' : '1.2' : 'pl'
            | dashIfEmpty
        }}
      </p>
      <p>
        {{
          ((order?.costs?.courier || 0) + (order?.costs?.service || 0)) *
            1.23 || 0
            | currency: 'PLN' : 'symbol' : '1.2' : 'pl'
            | dashIfEmpty
        }}
      </p>
    </div>
    <hr class="custom-hr" />
    <div class="content-item">
      <p class="flex-70">Udział własny</p>
      <p>
        {{
          order?.costs?.deductible || 0
            | currency: 'PLN' : 'symbol' : '1.2' : 'pl'
            | dashIfEmpty
        }}
      </p>
      <p>
        {{
          order?.costs?.deductible * 1.23 || 0
            | currency: 'PLN' : 'symbol' : '1.2' : 'pl'
            | dashIfEmpty
        }}
      </p>
    </div>
    <hr class="custom-hr" />
    <div class="content-item">
      <p class="flex-70">Opłata serwisowa</p>
      <p>
        {{
          order?.costs?.serviceFee || 0
            | currency: 'PLN' : 'symbol' : '1.2' : 'pl'
            | dashIfEmpty
        }}
      </p>
      <p>
        {{
          order?.costs?.serviceFee * 1.23 || 0
            | currency: 'PLN' : 'symbol' : '1.2' : 'pl'
            | dashIfEmpty
        }}
      </p>
    </div>
    <hr class="custom-hr" />
    <div class="content-item">
      <p class="flex-70">Kwota do wypłaty</p>
      <p>
        {{
          order?.costs?.totalNet || 0
            | currency: 'PLN' : 'symbol' : '1.2' : 'pl'
            | dashIfEmpty
        }}
      </p>
      <p>
        {{
          order?.costs?.totalGross || 0
            | currency: 'PLN' : 'symbol' : '1.2' : 'pl'
            | dashIfEmpty
        }}
      </p>
    </div>
  </div>
</mat-card>
