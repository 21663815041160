import { Component } from '@angular/core';

@Component({
  selector: 'app-check-margin',
  templateUrl: './check-margin.component.html',
  styleUrls: ['./check-margin.component.scss']
})
export class CheckMarginComponent {

}
