import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { OrdersService } from 'src/app/pages/orders/services/orders.service';
import { MatStepper } from '@angular/material/stepper';
import { fileExtensionValidator } from '../../validators/fileExtension';
@Component({
  selector: 'app-add-invoices',
  templateUrl: './add-invoices.component.html',
  styleUrls: ['./add-invoices.component.scss'],
})
export class AddInvoicesComponent {
  file = new FormControl('', [Validators.required]);
  isLoading: any;
  display: FormControl = new FormControl('', Validators.required);
  displayInvoice: FormControl = new FormControl('', Validators.required);
  file_store: any;
  isLinear: any = false;
  invalid_file: any;
  firstFormGroup = this._formBuilder.group({
    firstCtrl: ['', Validators.required],
  });
  secondFormGroup = this._formBuilder.group({
    secondCtrl: ['', Validators.required],
  });
  thirdFormGroup = this._formBuilder.group({
    thirdCtrl: ['', Validators.required],
  });
  fourthFormGroup = this._formBuilder.group({
    fourthCtrl: ['', Validators.required],
  });
  res!: object;
  error: any;
  gross_invoice_amount_to_verify: any;
  gross_invoice_amount: any;
  invalid!: boolean;
  invoice_period: any;
  invoice_number: any;
  net_invoice_amount!: string | number;
  ordersId: any = [];
  resXlsx: any | undefined;
  file_storeInvoice: any;
  displayedColumns: string[] = [
    'orderFaultNumber',
    'invoiceNumber',
    'amountFromFile',
    'info',
  ];

  XlsxIsWrong: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<AddInvoicesComponent>,
    private _formBuilder: FormBuilder,
    private ordersService: OrdersService
  ) {
    this.resXlsx = [];
    this.resXlsx.verifiedOrders = [];
  }

  handleFileInputChange(l: any): void {
    this.file_store = l[0];
    if (l.length) {
      const f = l[0];
      const count = l.length > 1 ? `(+${l.length - 1} files)` : '';
      this.display.patchValue(`${f.name}${count}`);
    } else {
      this.display.patchValue('');
    }
  }
  handleFileInputChangeInvoice(l: any): void {
    this.file_storeInvoice = l[0];
    if (l.length) {
      const f = l[0];
      const count = l.length > 1 ? `(+${l.length - 1} files)` : '';
      this.displayInvoice.patchValue(`${f.name}${count}`);
    } else {
      this.displayInvoice.patchValue('');
    }
  }

  addXlsx(stepper: MatStepper): void {
    this.XlsxIsWrong = false;
    this.isLoading = true;
    const data = {
      file: this.file_store,
    };
    if (!this.file_store) {
      this.invalid_file = true;
      this.file.hasError('required');
      this.isLoading = false;
    } else {
      this.ordersService.postXlsxToVerify(data).subscribe(
        res => {
          this.isLoading = false;

          this.resXlsx = res;
          this.net_invoice_amount = this.resXlsx.verificationFileTotalNet;
          this.invoice_number = this.resXlsx.verificationFileInvoiceNumber;
          this.invoice_period = this.resXlsx.verificationFilePeriod;
          this.resXlsx.verifiedOrders.forEach(
            (element: { isValid: boolean }) => {
              if (element.isValid == false) {
                this.XlsxIsWrong = true;
              }
            }
          );
          stepper.next();
        },
        error => {
          this.isLoading = false;
          this.error = error;
        }
      );
    }
  }

  addInvoice(stepper: MatStepper): void {
    this.gross_invoice_amount_to_verify =
      this.resXlsx.verificationFileTotalGross;
    if (this.gross_invoice_amount_to_verify == this.gross_invoice_amount) {
      this.invalid = false;
      if (!this.file_storeInvoice) {
        this.invalid_file = true;
        this.file.hasError('required');
      } else {
        this.invalid_file = false;
        this.isLoading = true;
        const data = {
          file: this.file_storeInvoice,
          name: this.file_storeInvoice.name,
          verificationFileId: this.resXlsx.verificationFileId,
          verificationFileTotalGross: this.gross_invoice_amount,
        };
        this.resXlsx.verifiedOrders.forEach((order: any) => {
          this.ordersId.push(order.orderId);
        });
        this.ordersService.postInvoice(data, this.ordersId).subscribe(
          res => {
            this.isLoading = false;

            this.res = res;
            stepper.next();
          },
          error => {
            this.isLoading = false;

            this.error = error;
          }
        );
      }
    } else {
      this.invalid = true;
      this.gross_invoice_amount.hasError('required');
    }
  }

  onStepChange(): void {
    this.error = null;
  }

  nextStep(stepper: MatStepper) {
    this.net_invoice_amount = this.resXlsx.verificationFileTotalNet;
    this.invoice_number = this.resXlsx.verificationFileInvoiceNumber;
    this.invoice_period = this.resXlsx.verificationFilePeriod;
    stepper.next();
  }
}
