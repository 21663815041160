<mat-card *ngIf="preAuth && preAuth.length"
  ><h5>Nowe preautoryzacje</h5>
  <mat-divider></mat-divider>
</mat-card>
<mat-card
  class="card row_2 border"
  *ngFor="let activity of preAuth"
  (click)="redirectTo('orders/' + activity.orderId)">
  <div class="right-s">
    <div>
      <div class="icon">
        <mat-icon>person</mat-icon>
      </div>
      <div class="left">
        <b>{{ activity?.orderFaultNumber }}</b><br />
        <div class="gray" *ngIf="activity?.preauthorizationDecision">
          Decyzja: {{ activity?.preauthorizationDecision }}
        </div>
      </div>
      <div class="gray right">
        {{ activity?.preauthorizationUpdateAt | timeAgo | translate}}
      </div>
    </div>
  </div>

  <mat-divider></mat-divider>
</mat-card>

<mat-card *ngIf="preAuth && !preAuth.length">
  <h5>Brak nowych preautoryzacji</h5>
</mat-card>
