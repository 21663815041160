import { Component, Inject, OnInit, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ServicesService } from '../../../pages/services/services/services.service';
import { environment } from 'src/app/environments/environment';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'edit-add-service-info',
  templateUrl: './edit-service-info.component.html',
  styleUrls: ['./edit-service-info.component.scss'],
})
export class EditServiceInfoComponent implements OnInit {
  public formCompanyInfo!: FormGroup;
  public formAddressInfo!: FormGroup;
  public formContactInfo!: FormGroup;

  public submitted!: boolean;
  public companyNext!: boolean;
  public addressNext!: boolean;
  public gusError: any;
  public gusInfo!: boolean;
  public tab: any = 'companyInfo';
  id: any;
  service: any;
  constructor(
    private fb: FormBuilder,
    private serviceService: ServicesService,
    private dialogRef: MatDialogRef<EditServiceInfoComponent>,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.id = data.id;
  }

  download(): void {}

  ngOnInit(): void {
    this.companyNext = true;
    this.formCompanyInfo = this.fb.group({
      name: ['', [Validators.required]],
      nip: [
        '',
        [Validators.required, Validators.pattern(environment.validators.nip)],
      ],
      regon: [
        '',
        [Validators.required, Validators.pattern(environment.validators.regon)],
      ],
      number: ['', Validators.required],
      bankNumber: [
        '',
        [
          Validators.required,
          Validators.pattern(environment.validators.bank_account_number),
        ],
      ],
      active: [true],
      city: [
        null,
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(20),
        ],
      ],
      postalCode: [
        '',
        [
          Validators.required,
          Validators.pattern(environment.validators.postal_code),
        ],
      ],
      street: [
        null,
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(100),
        ],
      ],
      houseNumber: [
        '',
        [
          Validators.required,
          Validators.pattern(environment.validators.home_number),
        ],
      ],
      apartmentNumber: [''],
    });
    this.getData();
  }

  getData(): void {
    this.serviceService.getServiceById(this.id).subscribe(res => {
      this.service = res;
      this.formCompanyInfo.patchValue({
        name: this.service.name,
        nip: this.service.taxNumber,
        regon: this.service.regon,
        number: this.service.contractNumber,
        bankNumber: this.service.bankAccountNumber,
        postalCode: this.service.address.postalCode,
        city: this.service.address.city,
        street: this.service.address.streetName,
        houseNumber: this.service.address.buildingNumber,
        apartmentNumber: this.service.address.apartmentNumber,
      });
    });
  }

  save(): void {
    this.submitted = true;
    if (this.formCompanyInfo.valid) {
      let body = {
        name: this.formCompanyInfo.value.name,
        contractNumber: this.formCompanyInfo.value.number,
        bankAccountNumber: this.formCompanyInfo.value.bankNumber,
        taxNumber: this.formCompanyInfo.value.nip,
        regon: this.formCompanyInfo.value.regon,
        address: {
          city: this.formCompanyInfo.value.city,
          postalCode: this.formCompanyInfo.value.postalCode,
          post: this.formCompanyInfo.value.city,
          province: this.formCompanyInfo.value.city,
          streetName: this.formCompanyInfo.value.street,
          buildingNumber: this.formCompanyInfo.value.houseNumber,
          apartmentNumber: this.formCompanyInfo.value.apartmentNumber,
        },
      };

      this.serviceService.editServices(this.id, body).subscribe((res: any) => {
        let body = {
          workshop: res['@id'],
          contactDetails: {
            email: this.formContactInfo.value.email,
            mobileNumber: this.formContactInfo.value.phoneNumber.replace(
              / /g,
              ''
            ),
            phoneNumber: this.formContactInfo.value.secondNumber.replace(
              / /g,
              ''
            ),
            description: 'string',
            name: this.formContactInfo.value.name,
            surname: this.formContactInfo.value.surname,
            type: 'generic',
          },
        };
      });
      this.dialogRef.close();
      this._snackBar.open('Edycja zakończona sukcesem', 'Zamknij', {
        duration: 3000,
      });
    }
  }
  getGusInfo(): void {
    this.gusError = false;
    if (this.formCompanyInfo.value.nip.length === 10) {
      this.gusInfo = false;
      this.serviceService
        .getGusInfo(this.formCompanyInfo?.value?.nip)
        .subscribe(
          (res: any) => {
            this.formCompanyInfo.patchValue({
              name: res?.name,
              regon: res?.regon,
            });
            this.formCompanyInfo.patchValue({
              city: res?.address?.city,
              postalCode: res?.address?.postalCode,
              street: res?.address?.streetName,
              houseNumber: res?.address?.buildingNumber,
              apartmentNumber: res?.address?.apartmentNumber,
            });
          },
          (error: any) => {
            if (error == 404) {
              this.gusError = 404;
            } else if (error == 400) {
              this.gusError = 400;
            } else {
              this.gusError = 'other';
            }
          }
        );
    } else {
      this.gusInfo = true;
    }
  }
}
