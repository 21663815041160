<div class="text-center" mat-dialog-content>
  <i class="material-icons red">warning</i>
  <h1 class="title-h">
    {{ 'Are_you_sure_you_want_to_cancel_this_order' | translate }}
  </h1>
  <p>
    {{ 'Canceled_order_cannot_be_moved_to_previous_status' | translate }}
  </p>
</div>
<br />
<div class="text-right buttons" mat-dialog-actions>
  <button mat-raised-button class="secondary-btn" [mat-dialog-close]="'cancel'">
    {{ 'cancel' | translate }}
  </button>
  <button mat-raised-button class="primary-btn" [mat-dialog-close]="'confirm'">
    {{ 'confirm' | translate }}
  </button>
</div>
