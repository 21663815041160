import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-edit-margin',
  templateUrl: './confirm-archive-order.component.html',
  styleUrls: ['./confirm-archive-order.component.scss'],
})
export class ConfirmArchiveOrderComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}
}
