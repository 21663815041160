import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { environment } from 'src/app/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  public user: any = null;
  public user$: any = new Subject();

  constructor(private http: HttpClient) {}

  getUserInfo() {
    return this.http.get(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/users/logged-user`
    );
  }

  getUsersAdmin() {
    return this.http.get(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/admin/users`
    );
  }

  createUser(data: any) {
    return this.http.post(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/admin/users`,
      data
    );
  }

  blockUser(id: any) {
    return this.http.put(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/admin/users/${id}/deactivate`,
      {}
    );
  }

  unblockUser(id: any, resetPassword: boolean) {
    return this.http.put(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/admin/users/${id}/activate?resetPassword=${resetPassword}`,
      {}
    );
  }

  getUserAdminInfo(id: any) {
    return this.http.get(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/admin/users/${id}`
    );
  }

  updateUserAdmin(id: any, data: any) {
    return this.http.put(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/admin/users/${id}`,
      data
    );
  }

  getKeycloakUserInfo(auth_token: string) {
    return this.http.get(
      environment.Keycloak +
        '/realms/' +
        environment.realm +
        '/protocol/openid-connect/userinfo',
      { headers: { Authorization: `Bearer ${auth_token}` } }
    );
  }

  setUser(user: any) {
    this.user$.next(user);
    this.user = user;
  }

  getUser() {
    return this.user;
  }

  getUsersGroups() {
    return this.http.get(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/admin/users/groups`
    );
  }

  getUsersGroupsAvailable(id: any) {
    return this.http.get(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        `/admin/users/${id}/roles/available`
    );
  }

  getCroneHealthCheck() {
    return this.http.get(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        '/cron-healthcheck',
      { params: { 'output-format': 'json' } }
    );
  }

  getLogs() {
    return this.http.get(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        '/partner_api_requests_logs'
    );
  }
  getLogsFiltered(params: any) {
    return this.http.get(
      environment.API.apiHost +
        environment.API.apiPointInternal +
        '/partner_api_requests_logs',
      { params: params }
    );
  }
}
