import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AddInvoiceComponent } from '../../../shared/modals/add-invoice/add-invoice.component';
import { EditMarginComponent } from '../../../shared/modals/edit-margin/edit-margin.component';
import { OrdersService } from '../../orders/services/orders.service';
import { ActivatedRoute } from '@angular/router';
import * as FileSaver from 'file-saver';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'app-costs-item',
  templateUrl: './costs-item.component.html',
  styleUrls: ['./costs-item.component.scss'],
})
export class CostsItemComponent {
  costs: any;
  public marginId!: string;
  public margin: any;
  public order: any;

  constructor(
    private dialog: MatDialog,
    private ordersService: OrdersService,
    private route: ActivatedRoute,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.ordersService
      .getOrderById(this.route.snapshot.params['id'])
      .subscribe(res => {
        this.order = res;
        if (this.order.billing != null) {
          this.ordersService
            .getDataFromIRI(this.order.billing)
            .subscribe(res => {
              this.order.billing = res;
            });
        }
      });
  }

  addInvoice(): void {
    const dialogRef = this.dialog.open(AddInvoiceComponent, {
      data: {
        invoice_number: this.order?.billing?.invoice,
        invoice_date: this.order?.billing?.createdAt,
        net_invoice_amount: this.order?.costs?.calculatedTotalNet,
        gross_invoice_amount: this.order?.billing?.amount,
      },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'show') {
      }
    });
  }

  downloadFv() {
    this._snackBar.open('Pobieranie rozpoczęte', 'Zamknij', {
      duration: 1200,
    });
    return this.ordersService.downloadbillingInvoice(this.order?.id).subscribe(
      response => {
        const data = new Blob([response as any], {
          type: 'application/pdf;charset=utf-8',
        });
        FileSaver.saveAs(data, 'invoice.pdf');
      },
      error => {
        this._snackBar.open('Błąd pobierania', 'Zamknij', {
          duration: 1200,
        });
      }
    );
  }
}
