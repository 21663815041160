<ng-container>
  <div class="title">
    <app-breadcrumb></app-breadcrumb>
  </div>
  <mat-card>
    <div class="card-title text-right">
      <div
        class="search-input"
        *ngIf="Auth.isViewAllowed(['ROLE_WORKSHOP_SEARCH', 'ROLE_ADMIN'])">
        <div class="did-floating-label-content search">
          <input
            class="did-floating-input"
            (keyup)="applyFilter($event)"
            type="text"
            placeholder=" "
            #input />
          <label class="did-floating-label">{{
            'services-front.search' | translate
          }}</label>
          <mat-icon>search</mat-icon>
        </div>
      </div>
      <button
        mat-stroked-button
        (click)="addService()"
        *ngIf="Auth.isViewAllowed(['ROLE_WORKSHOP_MANAGE', 'ROLE_ADMIN'])">
        {{ 'services-front.add_service' | translate }}
      </button>
    </div>

    <div class="table">
      <table mat-table [dataSource]="dataSource">
        <!-- Position Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'services-front.table.name' | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            <ng-container *ngIf="element.name">
              {{ element.name }}</ng-container
            >
          </td>
        </ng-container>

        <ng-container matColumnDef="createdAt">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'services-front.added' | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            <ng-container *ngIf="element.createdAt">
              {{ element.createdAt | date: 'YYYY.MM.dd HH:mm' }}</ng-container
            >
          </td>
        </ng-container>
        <!-- Name Column -->
        <ng-container matColumnDef="city">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'services-front.table.city' | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            <ng-container *ngIf="element.address.city"
              >{{ element.address.city }}
            </ng-container>
          </td>
        </ng-container>

        <!-- Weight Column -->
        <ng-container matColumnDef="owner">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'services-front.table.owner' | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            <ng-container
              *ngIf="
                element.contacts[0] &&
                element.contacts[0].contactDetails &&
                element.contacts[0].contactDetails.name
              ">
              {{ element.contacts[0].contactDetails.name }}
              {{ element.contacts[0].contactDetails.surname }}
            </ng-container>
          </td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="mobile">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'services-front.table.mobile' | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            <ng-container
              *ngIf="
                element.contacts[0] &&
                element.contacts[0].contactDetails &&
                element.contacts[0].contactDetails.mobileNumber
              ">
              {{ element.contacts[0].contactDetails.mobileNumber }}
            </ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'services-front.table.email' | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            <ng-container
              *ngIf="
                element.contacts[0] &&
                element.contacts[0].contactDetails &&
                element.contacts[0].contactDetails.email
              ">
              {{ element.contacts[0].contactDetails.email }}
            </ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="nip">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'services-front.table.nip' | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            <ng-container *ngIf="element.taxNumber">
              {{ element.taxNumber }}
            </ng-container>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          class="hovered"
          (click)="goToDetails(row?.id)"
          mat-row
          *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <div class="empty-table-view" *ngIf="dataSource?.data?.length === 0">
        <p>{{ 'no_data' | translate }}</p>
      </div>
      <mat-paginator
        [pageSizeOptions]="[10, 20, 30, 50]"
        showFirstLastButtons
        aria-label="Select page of periodic elements">
      </mat-paginator>
    </div>
  </mat-card>
</ng-container>
