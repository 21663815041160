<ng-container>
  <div class="title">
    <app-breadcrumb></app-breadcrumb>
  </div>
  <mat-card>
    <mat-label>Crons</mat-label>
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
      <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->

      <!-- Position Column -->
      <ng-container matColumnDef="commandName">
        <th mat-header-cell *matHeaderCellDef>commandName</th>
        <td mat-cell *matCellDef="let element">
          {{ element.commandName }}
        </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef>description</th>
        <td mat-cell *matCellDef="let element">
          {{ element.description }}
        </td>
      </ng-container>

      <!-- Weight Column -->
      <ng-container matColumnDef="details">
        <th mat-header-cell *matHeaderCellDef>details</th>
        <td mat-cell *matCellDef="let element">{{ element.details }}</td>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="isActive">
        <th mat-header-cell *matHeaderCellDef>isActive</th>
        <td mat-cell *matCellDef="let element">{{ element.isActive }}</td>
      </ng-container>

      <ng-container matColumnDef="lastRun">
        <th mat-header-cell *matHeaderCellDef>lastRun</th>
        <td mat-cell *matCellDef="let element">{{ element.lastRun }}</td>
      </ng-container>

      <ng-container matColumnDef="noActivityAlertAfter">
        <th mat-header-cell *matHeaderCellDef>noActivityAlertAfter</th>
        <td mat-cell *matCellDef="let element">
          {{ element.noActivityAlertAfter }}
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>status</th>
        <td mat-cell *matCellDef="let element" [class]="element.status">
          {{ element.status }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <div class="empty-table-view" *ngIf="dataSource?.data?.length === 0">
      <p>{{ 'no_data' | translate }}</p>
    </div>
  </mat-card>
</ng-container>
