import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
@Component({
  selector: 'app-show-doc',
  templateUrl: './show-doc.component.html',
  styleUrls: ['./show-doc.component.scss'],
})
export class ShowDocComponent {
  URL!: string;
  image!: boolean;
  imageURL!: string;

  constructor(
    private h: HttpClient,
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data.data.type == 'image/png' || data.data.type == 'image/jpeg') {
      this.image = true;
    } else {
      this.image = false;
    }
    this.imageURL = this.createbloburl(data.data, data.data.type);
  }

  createbloburl(file: BlobPart, type: any) {
    var blob = new Blob([file], {
      type: type || 'application/*',
    });

    file = window.URL.createObjectURL(blob);

    return file;
  }
}
