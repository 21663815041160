<mat-drawer-container class="example-container">
  <mat-drawer
    #snav
    id="snav"
    [mode]="mobileQuery.matches ? 'over' : 'side'"
    [opened]="!mobileQuery.matches"
    [ngClass]="{ 'sidenav-collapsed': collapsedNav && !mobileQuery.matches }">
    <app-sidenav [opened]="!collapsedNav"></app-sidenav>
    <button
      class="menu-button"
      mat-icon-button
      (click)="
        mobileQuery.matches ? snav.toggle() : (collapsedNav = !collapsedNav)
      ">
      <mat-icon [ngClass]="{ 'name-lower': !collapsedNav == false }"
        >menu</mat-icon
      >
    </button>
  </mat-drawer>
  <mat-drawer-content
    class="mat-drawer-content"
    [ngClass]="{ 'sidenav-collapsed': collapsedNav && !mobileQuery.matches }">
    <div class="spinner-container" *ngIf="this.spinnerService.getLoading()">
      <mat-spinner class="spinner"> </mat-spinner>
    </div>
    <app-top-navbar></app-top-navbar>
    <router-outlet></router-outlet>
  </mat-drawer-content>
</mat-drawer-container>
