import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-activate-user-confirm',
  templateUrl: './activate-user-confirm.component.html',
  styleUrls: ['./activate-user-confirm.component.scss'],
})
export class ActivateUserConfirmComponent implements OnInit {
  public username: string = '';
  public isActivate: boolean = false;
  public checked: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit(): void {
    this.username = this.data.username;
    this.isActivate = this.data?.isActivate;
  }

  setValue(event: any): void {
    this.checked = event.checked;
  }
}
