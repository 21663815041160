<div class="order-info">
  <mat-card>
    <div class="order-info-panel">
      <div>
        <p>
          {{ 'orders-front.info.order_number' | translate }}
        </p>
        <p [routerLink]="'/orders/' + this.order?.id" class="like-link">
          {{ order?.faultNumber | dashIfEmpty }}
        </p>
      </div>
      <div>
        <p>
          {{ 'orders-front.info.created_at' | translate }}
        </p>
        <p>
          {{ order?.createdAt | date : 'YYYY-MM-dd HH:mm:ss' | dashIfEmpty }}
        </p>
      </div>
      <div>
        <p>
          {{ 'orders-front.info.state' | translate }}
        </p>
        <p>{{ order?.state | dashIfEmpty | translate }}</p>
      </div>
    </div>
  </mat-card>
  <mat-card>
    <div class="order-info-panel">
      <div>
        <p>
          {{ 'orders-front.info.service' | translate }}
        </p>
        <p
          class="like-link"
          [routerLink]="'/workshop/' + this.order?.workshopId">
          {{ order?.executorCompanyName | dashIfEmpty }}
        </p>
      </div>
      <div>
        <p>
          {{ 'orders-front.info.client' | translate }}
        </p>
        <p>
          {{ order?.orderer | dashIfEmpty }}
        </p>
      </div>
      <div>
        <p>
          {{ 'orders-front.info.invoice' | translate }}
        </p>
        <p>
          {{ order?.billingRevenue?.invoiceNumber | dashIfEmpty }}
        </p>
      </div>
    </div>
  </mat-card>
  <mat-card>
    <div class="order-info-panel">
      <div>
        <p>
          {{ 'orders-front.info.end_date' | translate }}
        </p>
        <p>
          {{
            order?.dates?.repairEndDate
              | date : 'YYYY-MM-dd HH:mm:ss'
              | dashIfEmpty
          }}
        </p>
      </div>
      <div>
        <p>
          {{ 'orders-front.info.settlement_date' | translate }}
        </p>
        <p>
          {{
            order?.dates?.finishDate
              | date : 'YYYY-MM-dd HH:mm:ss'
              | dashIfEmpty
          }}
        </p>
      </div>
    </div>
  </mat-card>
</div>
