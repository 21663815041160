import { HttpParams } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OrdersService } from 'src/app/pages/orders/services/orders.service';
import saveAs from 'file-saver';
import { FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-generate-costs-report',
  templateUrl: './generate-costs-report.html',
  styleUrls: ['./generate-costs-report.scss'],
})
export class GenerateCostsReportComponent {
  @Input() someInput: string | undefined;

  filteredValues = {
    orderFinishDateFrom: '',
    orderFinishDateTo: '',
  };
  orderFinishDateFrom = new FormControl();
  orderFinishDateTo = new FormControl();

  constructor(
    private _snackBar: MatSnackBar,
    private ordersService: OrdersService,
    private dialogRef: MatDialogRef<GenerateCostsReportComponent>
  ) {
    this.orderFinishDateFrom.valueChanges.subscribe(weightFilterValue => {
      this.filteredValues['orderFinishDateFrom'] = weightFilterValue;
    });
    this.orderFinishDateTo.valueChanges.subscribe(weightFilterValue => {
      this.filteredValues['orderFinishDateTo'] = weightFilterValue;
    });
  }

  downloadSummary(): void {
    let params = new HttpParams();

    if (
      this.filteredValues['orderFinishDateFrom'] != null &&
      this.filteredValues['orderFinishDateFrom'] != ''
    ) {
      const orderFinishDate = new Date(
        this.filteredValues['orderFinishDateFrom']
      );
      const formattedDate = orderFinishDate.toLocaleDateString('sv-SE', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      });

      params = params.append('orderFinishDateFrom', formattedDate);
    } else {
      this._snackBar.open('Uzupełnij dane w filtrach', 'Zamknij');
    }

    if (
      this.filteredValues['orderFinishDateTo'] != null &&
      this.filteredValues['orderFinishDateTo'] != ''
    ) {
      const orderFinishDate = new Date(
        this.filteredValues['orderFinishDateTo']
      );
      const formattedDate = orderFinishDate.toLocaleDateString('sv-SE', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      });

      params = params.append('orderFinishDateTo', formattedDate);

      if (
        this.filteredValues['orderFinishDateFrom'] >
        this.filteredValues['orderFinishDateTo']
      ) {
        this._snackBar.open(
          'Data początkowa nie może być większa niż data końcowa',
          'Zamknij'
        );
        return;
      }

      this.ordersService
        .downloadCostsSummary(
          params.get('orderFinishDateFrom'),
          params.get('orderFinishDateTo')
        )
        .subscribe(
          data => {
            let fileName = 'report.xlsx';

            const contentDisposition = data.headers.get('Content-Disposition');
            if (contentDisposition) {
              const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              const matches = fileNameRegex.exec(contentDisposition);
              if (matches != null && matches[1]) {
                fileName = matches[1].replace(/['"]/g, '');
              }
            }

            saveAs(data.body || new Blob(), fileName);
            this.dialogRef.close();
          },
          err => {
            console.error(err);
            alert(
              'Problem while downloading the file.\n' +
                '[' +
                err.status +
                '] ' +
                err.statusText
            );
          }
        );
    } else {
      this._snackBar.open('Uzupełnij dane w filtrach', 'Zamknij');
    }
  }
}
