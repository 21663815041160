<h1 mat-dialog-title class="text-center">
  <mat-icon color="primary">timelapse</mat-icon>
</h1>
<hr class="custom-hr" />
<div class="text-center" mat-dialog-content>
  <h1 class="title-h">{{ 'session.no_activity' | translate }}</h1>
  <p>
    {{ 'Inactivity_will_result_in_logout' | translate }}
    {{ 'session.to_stay_logged_in_click_the_button_below' | translate }}
  </p>
</div>
<div class="text-right buttons" mat-dialog-actions>
  <button
    mat-raised-button
    color="primary"
    (click)="resetSession()"
    [mat-dialog-close]="'show'">
    {{ 'session.extend_your_session' | translate }}
  </button>
</div>
