import { Component, Input } from '@angular/core';
import { AuthGuard } from 'src/app/shared/guards/access-allowed.guard';

@Component({
  selector: 'app-order-costs',
  templateUrl: './order-costs.component.html',
  styleUrls: ['./order-costs.component.scss'],
})
export class OrderCostsComponent {
  constructor(public Auth: AuthGuard) {}
  details: any = false;
  @Input() order: any;
  ngOnInit(): void {
    if (this.order.costs) {
      this.order.costs.courier_sum =
        (this.order?.costs?.courier || 0) + (this.order?.costs?.services || 0);

      this.order.costs.consultation_sum =
        (this.order?.costs?.consultation || 0) +
        (this.order?.costs?.replacement || 0);
    }
  }
}
