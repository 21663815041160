import { Component, Input } from '@angular/core';
import { UserService } from '../../services/user.service';
import { OrdersService } from 'src/app/pages/orders/services/orders.service';
import { AuthGuard } from 'dashboard-libs/guards/access-allowed.guard';
@Component({
  selector: 'app-comments',
  templateUrl: './comments.html',
  styleUrls: ['./comments.scss'],
})
export class CommentsComponent {
  @Input() someInput: string | undefined;
  selector: string = '.container-comments';
  res: any;
  loggedInUser: any;
  offset: any = 0;
  newComments: any = 'true';
  limit: any = 10;
  constructor(
    private orderService: OrdersService,
    private userService: UserService,
    public Auth: AuthGuard
  ) {
    if (this.newComments == 'false') {
      this.orderService
        .getNextComments(this.offset, this.limit)
        .subscribe((res: any) => {
          this.userService.getUserInfo().subscribe((user: any) => {
            this.loggedInUser = user;
          });

          this.res = res['hydra:member'].filter(
            (comment: any) =>
              comment.createdBy !==
              this.loggedInUser?.name + ' ' + this.loggedInUser?.surname
          );
          this.offset = this.offset + this.limit;
        });
    } else {
      this.orderService
        .getNextNewComments(this.offset, this.limit)
        .subscribe((res: any) => {
          this.userService.getUserInfo().subscribe((user: any) => {
            this.loggedInUser = user;
          });

          this.res = res['hydra:member'].filter(
            (comment: any) =>
              comment.createdBy !==
              this.loggedInUser?.name + ' ' + this.loggedInUser?.surname
          );
          this.offset = this.offset + this.limit;
        });
    }
  }

  loadNextComments() {
    if (this.newComments == 'false') {
      this.orderService
        .getNextComments(this.offset, this.limit)
        .subscribe((res: any) => {
          this.res = [...this.res, ...res['hydra:member']];
          this.offset = this.offset + this.limit;
        });
    } else {
      this.orderService
        .getNextNewComments(this.offset, this.limit)
        .subscribe((res: any) => {
          this.res = [...this.res, ...res['hydra:member']];
          this.offset = this.offset + this.limit;
        });
    }
  }

  onScrollDown() {
    this.loadNextComments();
  }

  reloadComments() {
    console.log(this.newComments);
    if (this.newComments == 'false') {
      console.log('old comments', this.newComments);
      this.orderService.getNextComments(0, this.limit).subscribe((res: any) => {
        this.res = res['hydra:member'].filter(
          (comment: any) =>
            comment.createdBy !==
            this.loggedInUser?.name + ' ' + this.loggedInUser?.surname
        );
        this.offset = this.limit;
      });
    } else {
      console.log('new comments', this.newComments);
      this.orderService
        .getNextNewComments(0, this.limit)
        .subscribe((res: any) => {
          this.res = res['hydra:member'].filter(
            (comment: any) =>
              comment.createdBy !==
              this.loggedInUser?.name + ' ' + this.loggedInUser?.surname
          );
          this.offset = this.limit;
        });
    }
  }
}
