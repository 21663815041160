import { Component, Input, AfterViewInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ShowDataComponent } from '../../../../../shared/modals/show-data/show-data.component';
import { AddNoteComponent } from '../../../../../shared/modals/add-note/add-note.component';
import { AddCommentComponent } from '../../../../../shared/modals/add-comment/add-comment.component';
import { OrdersService } from '../../../services/orders.service';
import { AuthGuard } from 'src/app/shared/guards/access-allowed.guard';
import { NgxDateFormat, NgxTimelineItemPosition } from '@frxjs/ngx-timeline';
@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss'],
})
export class OrderDetailsComponent {
  @Input() order: any;
  @Input() history!: Array<any>;
  comments: any = [];
  ngxDateFormat = NgxDateFormat;
  commentsEmpty: boolean = true;
  NgxTimelineItemPosition = NgxTimelineItemPosition.ON_RIGHT;
  activeLog: Array<any> = [];
  activeLogEmpty: boolean = true;
  public i = 0;
  public z = 0;
  public showData!: boolean;
  public timeline_change: any = 'ALL';

  public policy!: any;
  public showDataPolicy!: boolean;
  events!: {
    id: number;
    description: string;
    extraData?: any;
    timestamp: Date;
    title: string;
    alertType: string;
  }[];

  fullViewFlag = false;

  constructor(
    public dialog: MatDialog,
    private orderService: OrdersService,
    public Auth: AuthGuard
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.history?.currentValue || changes?.order?.currentValue) {
      const today = new Date();
      const tomorrow = new Date();
      tomorrow.setDate(today.getDate() + 1);
      const nextMonth = new Date();
      nextMonth.setMonth(today.getMonth() + 1);
      const nextYear = new Date();
      nextYear.setFullYear(today.getFullYear() + 1);
      this.events = [];
      this.comments = [];
      if (this.history) {
        const i = 0;
        this.history.forEach((data: any) => {
          if (data.eventType === 'comment') {
            this.comments.push(data);
            this.commentsEmpty = false;
          } else {
            const timelineActiveLog: any = {};
            timelineActiveLog.id = this.i;
            timelineActiveLog.description = data.eventData.detailedInfo;
            timelineActiveLog.timestamp = new Date(data.eventDate);
            timelineActiveLog.title = data.eventData.title;
            timelineActiveLog.alertType = data.eventData.alertType;
            timelineActiveLog.itemPosition = this.NgxTimelineItemPosition;
            timelineActiveLog.extraData = data.eventData.extraData;

            this.events.push(timelineActiveLog);
            this.activeLogEmpty = false;
            this.i++;
          }
        });
      }
    }
  }

  showDataModal(): void {
    const dialogRef = this.dialog.open(ShowDataComponent, {
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'show') {
        this.orderService
          .getPolicyByName(this.order.insuranceNumber)
          .subscribe(res => {
            this.policy = res;
          });
        this.showData = true;
      }
    });
  }

  addNote(): void {
    this.showData = false;
    const dialogRef = this.dialog.open(AddNoteComponent, {
      data: { id: this.order.id, note: this.order.note },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(submit => {
      if (submit === 'cancel') return;

      this.orderService.addNote(this.order.id, submit).subscribe(res => {
        window.location.reload();
      });
    });
  }

  addComment(): void {
    this.showData = false;
    const dialogRef = this.dialog.open(AddCommentComponent, {
      data: { id: this.order.id },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(submit => {
      if (submit === 'cancel') return;
      submit = {
        content: submit.comment,
        type: '',
        code: '',
        toPartner: submit.toPartner,
        toOrderSource: submit.toOrderSource,
      };
      this.orderService.sendComment(this.order.id, submit).subscribe(res => {
        window.location.reload();
      });
    });
  }
}
