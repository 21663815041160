import { Component } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'app-session-logout',
  templateUrl: './session-logout.component.html',
  styleUrls: ['./session-logout.component.scss'],
})
export class SessionLogoutComponent {
  constructor(private keycloakService: KeycloakService) {
    setTimeout(() => {
      this.login();
    }, 30000);
  }

  login(): void {
    localStorage.clear();
    this.keycloakService.logout();
  }
}
