<div class="main-content" *ngIf="Auth.isViewAllowed(['ROLE_ORDER_DOCUMENTS_VIEW', 'ROLE_ADMIN'])">
  <mat-card>
    <div class="card">

      <hr class="custom-hr" />
      <div class="table">
        <table mat-table [dataSource]="dataSource">
          <ng-container matColumnDef="fileName">
            <th mat-header-cell *matHeaderCellDef>Nazwa dokumentu</th>
            <td mat-cell *matCellDef="let element">{{ element.name }}</td>
          </ng-container>

          <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef>Typ</th>
            <td mat-cell *matCellDef="let element">
              {{ element?.typeClaimsName }}
            </td>
          </ng-container>

          <ng-container matColumnDef="createdAt">
            <th mat-header-cell *matHeaderCellDef>Data dodania</th>
            <td mat-cell *matCellDef="let element">
              {{ element.createdAt | date : 'YYYY-MM-dd | HH:mm' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="author">
            <th mat-header-cell *matHeaderCellDef>Autor</th>
            <td mat-cell *matCellDef="let element">{{ element.author }}</td>
          </ng-container>

          <ng-container matColumnDef="size">
            <th mat-header-cell *matHeaderCellDef>Rozmiar</th>
            <td mat-cell *matCellDef="let element">
              {{ element.size }}
            </td>
          </ng-container>

          <ng-container matColumnDef="partner">
            <th mat-header-cell *matHeaderCellDef>Partner</th>
            <td mat-cell *matCellDef="let element">
              <mat-icon *ngIf="element.partner">checked</mat-icon>
            </td>
          </ng-container>

          <ng-container matColumnDef="orderSource">
            <th mat-header-cell *matHeaderCellDef>OrderSource</th>
            <td mat-cell *matCellDef="let element" class="center">
              <mat-icon *ngIf="element.orderSource">checked</mat-icon>
            </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Akcje</th>
            <td mat-cell *matCellDef="let element">
              <mat-icon class="icon" (click)="download(element.id, element.fileName)">download</mat-icon>
              <mat-icon class="icon" (click)="showAttachment(element.id)">remove_red_eye</mat-icon>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr (dblclick)="showAttachment(row.id)" class="hovered" mat-row
            *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
    </div>
  </mat-card>
</div>