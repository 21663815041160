<div *ngIf="edit">
  <h1 mat-dialog-title class="title">Edycja marży</h1>
  <hr class="custom-hr" />
  <div mat-dialog-content>
    <form [formGroup]="form">
      <div class="flex">
        <div class="p-like-input">
          <p>{{ 'margin.overall_margin' | translate }}</p>
        </div>
        <div class="did-floating-label-content">
          <input
            class="did-floating-input"
            (keypress)="check($event)"
            formControlName="generalMargin"
            type="number"
            placeholder=" " />
          <label class="did-floating-label"
            >{{ 'margin.value' | translate }} %</label
          >

          <mat-error *ngIf="form.controls['generalMargin'].errors?.required">{{
            'margin.field_is_required' | translate
          }}</mat-error>
        </div>
      </div>
      <div class="flex">
        <div class="p-like-input">
          <p>{{ 'margin.serviceFee' | translate }}</p>
        </div>
        <div class="did-floating-label-content">
          <input
            class="did-floating-input"
            (keypress)="check($event)"
            formControlName="serviceFee"
            type="number"
            placeholder=" " />
          <label class="did-floating-label"
            >{{ 'margin.value' | translate }} %</label
          >
          <mat-error *ngIf="form.controls['serviceFee'].errors?.required">{{
            'margin.field_is_required' | translate
          }}</mat-error>
        </div>
      </div>

      <div class="flex">
        <div class="p-like-input">
          <p>Marże składowe</p>
        </div>
        <div class="did-floating-label-content">
          <mat-checkbox
            [checked]="systemMargin.generalMarginAsDefault"
            (change)="checked()">
            Ustaw domyślną</mat-checkbox
          >
        </div>
      </div>
      <hr />
      <br />
      <div class="flex">
        <div class="p-like-input under-margin">
          <p>{{ 'margin.parts' | translate }}</p>
        </div>
        <div class="did-floating-label-content">
          <input
            class="did-floating-input"
            (keypress)="check($event)"
            formControlName="partsMargin"
            type="number"
            placeholder=" "
            [readonly]="this.systemMargin.generalMarginAsDefault" />
          <label class="did-floating-label"
            >{{ 'margin.value' | translate }} %</label
          >
          <mat-error *ngIf="form.controls['partsMargin'].errors?.required">{{
            'margin.field_is_required' | translate
          }}</mat-error>
        </div>
      </div>
      <div class="flex">
        <div class="p-like-input under-margin">
          <p>{{ 'margin.labor' | translate }}</p>
        </div>
        <div class="did-floating-label-content">
          <input
            class="did-floating-input"
            (keypress)="check($event)"
            formControlName="repairMargin"
            type="number"
            placeholder=" "
            [readonly]="this.systemMargin.generalMarginAsDefault" />
          <label class="did-floating-label"
            >{{ 'margin.value' | translate }} %</label
          >
          <mat-error *ngIf="form.controls['repairMargin'].errors?.required">{{
            'margin.field_is_required' | translate
          }}</mat-error>
        </div>
      </div>
      <div class="flex">
        <div class="p-like-input under-margin">
          <p>{{ 'margin.shipping' | translate }}</p>
        </div>
        <div class="did-floating-label-content">
          <input
            class="did-floating-input"
            (keypress)="check($event)"
            formControlName="transportMargin"
            type="number"
            placeholder=" "
            [readonly]="this.systemMargin.generalMarginAsDefault" />
          <label class="did-floating-label"
            >{{ 'margin.value' | translate }} %</label
          >
          <mat-error
            *ngIf="form.controls['transportMargin'].errors?.required"
            >{{ 'margin.field_is_required' | translate }}</mat-error
          >
        </div>
      </div>
      <div class="flex">
        <div class="p-like-input under-margin">
          <p>{{ 'margin.expertise' | translate }}</p>
        </div>
        <div class="did-floating-label-content">
          <input
            class="did-floating-input"
            (keypress)="check($event)"
            formControlName="consultationMargin"
            type="number"
            placeholder=" "
            [readonly]="this.systemMargin.generalMarginAsDefault" />
          <label class="did-floating-label"
            >{{ 'margin.value' | translate }} %</label
          >
          <mat-error
            *ngIf="form.controls['consultationMargin'].errors?.required"
            >{{ 'margin.field_is_required' | translate }}</mat-error
          >
        </div>
      </div>
    </form>
  </div>
  <div mat-dialog-actions>
    <button
      mat-button
      class="secondary-btn"
      color="accent"
      [mat-dialog-close]="'cancel'">
      {{ 'margin.cancel' | translate }}
    </button>
    <button
      mat-button
      class="primary-btn"
      color="primary"
      (click)="editMargin()">
      {{ 'margin.save' | translate }}
    </button>
  </div>
</div>
<div *ngIf="!edit">
  <h1 mat-dialog-title class="title">Edycja marży</h1>
  <hr class="custom-hr" />
  <div mat-dialog-content>
    <br />
    <h1 mat-dialog-title class="title">
      Czy jesteś pewny ze chcesz zapisać zmiany?
    </h1>
    <br />
  </div>
  <div mat-dialog-actions>
    <button
      mat-button
      class="secondary-btn"
      color="accent"
      (click)="editMargin()">
      {{ 'margin.back' | translate }}
    </button>
    <button mat-button class="primary-btn" color="primary" (click)="save()">
      {{ 'margin.save' | translate }}
    </button>
  </div>
</div>
