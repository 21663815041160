import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

import { SettingsService } from '../../../pages/settings/services/settings.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OrdersService } from '../../../pages/orders/services/orders.service';

@Component({
  selector: 'app-edit-margin',
  templateUrl: './edit-margin.component.html',
  styleUrls: ['./edit-margin.component.scss'],
})
export class EditMarginComponent implements OnInit {
  edit: any = true;
  systemMargin: any;
  form!: FormGroup;
  dialogRef: any;
  default: boolean = false;

  constructor(
    private dialog: MatDialog,
    private fb: FormBuilder,
    private orderService: OrdersService,
    private settingsService: SettingsService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.form = this.fb.group({
      generalMargin: ['', Validators.required],
      partsMargin: ['', Validators.required],
      repairMargin: ['', Validators.required],
      transportMargin: ['', Validators.required],
      consultationMargin: ['', Validators.required],
      serviceFee: ['', Validators.required],
      default: [false],
    });
  }

  ngOnInit(): void {
    this.systemMargin = this.data.margin;

    this.form.patchValue(this.systemMargin);
  }

  onInputChange(event: any, name: any): any {
    if (event.toString().length >= 4) {
      return false;
    } else {
      this.systemMargin[name] = event;
    }
  }

  save() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
    } else {
      this.systemMargin.generalMargin = this.form.value.generalMargin;
      if (this.systemMargin.generalMarginAsDefault != true) {
        this.systemMargin.partsMargin = this.form.value.partsMargin;
      } else {
        this.systemMargin.partsMargin = this.form.value.generalMargin;
      }

      if (this.systemMargin.generalMarginAsDefault != true) {
        this.systemMargin.repairMargin = this.form.value.repairMargin;
      } else {
        this.systemMargin.repairMargin = this.form.value.generalMargin;
      }

      if (this.systemMargin.generalMarginAsDefault != true) {
        this.systemMargin.transportMargin = this.form.value.transportMargin;
      } else {
        this.systemMargin.transportMargin = this.form.value.generalMargin;
      }

      this.systemMargin.serviceFee = this.form.value.serviceFee;

      if (this.systemMargin.generalMarginAsDefault != true) {
        this.systemMargin.consultationMargin =
          this.form.value.consultationMargin;
      } else {
        this.systemMargin.consultationMargin = this.form.value.generalMargin;
      }

      if (this.data.system) {
        this.settingsService
          .updateSystemMargins(this.systemMargin)
          .subscribe(res => {
            this.dialog.closeAll();
          });
      } else {
        this.orderService
          .updateOrderMargin(this.data.margin.id, this.systemMargin)
          .subscribe(res => {
            this.dialog.closeAll();
          });
      }
    }
  }

  check($event: any): boolean {
    return $event.target.value.toString().length < 4;
  }

  checked() {
    this.systemMargin.generalMarginAsDefault =
      !this.systemMargin.generalMarginAsDefault;

    this.systemMargin.partsMargin = this.systemMargin.generalMargin;
    this.systemMargin.repairMargin = this.systemMargin.generalMargin;
    this.systemMargin.transportMargin = this.systemMargin.generalMargin;
    this.systemMargin.consultationMargin = this.systemMargin.generalMargin;

    this.form.patchValue(this.systemMargin);
  }

  editMargin() {
    this.edit = !this.edit;
  }
}
