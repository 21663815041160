<h1 mat-dialog-title class="text-center">
  <mat-icon color="primary">timer_off</mat-icon>
</h1>
<hr class="custom-hr" />
<div class="text-center" mat-dialog-content>
  <h1 class="title-h">{{ "session.automatic_logout" | translate }}</h1>
  <p>
    {{
      "session.an_automatic_logout_of_your_account_has_occurred_log_in_again_to_use_the_application"
        | translate
    }}
  </p>
</div>
<div class="text-right buttons" mat-dialog-actions>
  <button
    mat-raised-button
    color="primary"
    (click)="login()"
    [mat-dialog-close]="'show'"
  >
    {{ "session.close" | translate }}
  </button>
</div>
