import { Component } from '@angular/core';

@Component({
  selector: 'app-show-data-default',
  templateUrl: './show-data-default.component.html',
  styleUrls: ['./show-data-default.component.scss'],
})
export class ShowDataDefaultComponent {
  showData(): void {}
}
