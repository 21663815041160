import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-service-confirm',
  templateUrl: './delete-service-confirm.component.html',
  styleUrls: ['./delete-service-confirm.component.scss'],
})
export class DeleteServiceConfirmComponent implements OnInit {
  public username: string = '';
  public isActivate: boolean = false;
  public checked: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit(): void {
    this.username = this.data.username;
  }
}
