<h1 mat-dialog-title class="title">Typy obsługiwanego sprzętu</h1>
<p class="title-desc p-content">Zaznacz które typy sprzętu są obsługiwane przez serwis</p>
<hr class="custom-hr"/>
<div mat-dialog-content>
  <div class="types-container">
    <div class="type" *ngFor="let type of types">
      <section class="example-section">
        <mat-checkbox (change)="addHardware(type.id, $event)" class="example-margin">{{type.name}}</mat-checkbox>
      </section>
    </div>
  </div>
  <mat-slide-toggle class="slide" (change)="setAll()">Zaznacz wszystko</mat-slide-toggle>
</div>
<div class="text-right buttons" mat-dialog-actions>
  <button mat-button class="primary-btn" color="primary" (click)="add()">Zapisz</button>
  <button mat-button class="secondary-btn" color="accent" [mat-dialog-close]="'cancel'">Anuluj</button>
</div>
